.ctaInsert {
    $icon_size: 136px;

    @include stack-spacing(section);

    &-title {
        font-size: rem(50px);
    }

    &-description {
        @include stack-spacing();

        > *:first-child {
            margin-top: 0;
        }

    }

    &-title + &-button,
    &-description + &-button,
    &-countdown + &-title {
        @include stack-spacing(component);
    }

    &-button {

        > svg,
        .streamlineIcon {
            margin-left: 1em;
        }

    }

    @include only-on-desktop(){
        width: get-column-width(math.div(8, 12));
        margin-left: auto;
        margin-right: auto;
    }

    @include not-on-mobile(){
        text-align: center;
    }

    @include only-on-mobile(){

    	.button {
        	display: block;
    	}

    }

    &.is-detailed {

        > .wrapper {
            @include stack-spacing(component, padding-top);
            @include stack-spacing(section, padding-bottom);

            color: $white;
            @include full-width-backdrop($ci_leading-color, after);
        }

    }

    &.is-detailed & {

        &-button {
            background-color: $ci_impulse-color;
            color: $text-color;

            &:hover {
                background-color: $white;
                color: $text-color;
            }

        }

    }

    &.has-icon {
        $icon_data: get-icon-data-url(book-flip-page, $text-color, $ctaInsert_icons);

        padding-top: (.5 * $icon_size);

        > .wrapper {
            padding-top: 0;

            &::before {
                content: "";

                display: block;
                width: $icon_size; height: $icon_size;

                position: relative;
                top: (-.5 * $icon_size);

                margin-left: auto;
                margin-right: auto;
                margin-bottom: (-.5 * $icon_size);

                background: $ci_impulse-color center center no-repeat;
                background-size: 60%;
                border-radius: 50%;
            }


            > *:first-child {
                @include stack-spacing(component);
            }

        }

        @each $name, $svg in $ctaInsert_icons {

            $icon_data: get-svg-data-url($svg, $text-color);

            &.is-#{$name} > .wrapper::before {
                background-image: url($icon_data);
            }

        }

    }

    &:has(.streamlineIcon) {
        padding-top: (.5 * $icon_size);
    }

    &-icon {
        $factor: .6;

        display: flex;
        justify-content: center;

        width: $icon_size;
        height: $icon_size;

        position: relative;
        transform: translateY(-50%);

        margin-left: auto;
        margin-right: auto;
        margin-top: calc(-1 * var(--sp-component));
        margin-bottom: (-.5 * $icon_size);

        background-color: $ci_impulse-color;
        color: $text-color;
        border-radius: 50%;

        .streamlineIcon {
            display: block;
            width: ($factor * 100%);
            height: ($factor * 100%);

            align-self: center;

            top: 0;
        }

        .icon {
            top: 0;
            @include icon--set-stroke-width(2);
        }

        & + * {
            @include stack-spacing(component);
        }

    }

    .countdownTimer {
        border-color: $white;
    }

}
