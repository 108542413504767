.metadataInsert {
    $icon_size: 54px;

    @include stack-spacing(section);

    &-items {

        @include grid-flowbox(
            $min-width: 222px,
            $stack-spacing: var(--sp, #{$stack-spacing}),
        );

    }

    &-item {
        @include stack-spacing();

        display: grid;

        grid-template-columns: $icon_size auto;
        grid-template-rows: max-content auto;

        column-gap: 24px;

        grid-template-areas:
            "icon label"
            "icon value";
    }

    &-icon {
        grid-area: icon;

        width: $icon_size;
        height: $icon_size;

        .streamlineIcon,
        .icon {
            display: block;
            width: 100%;
            height: 100%;

            top: 0;
        }

        .icon {
            @include icon--set-stroke-width(2);
        }

    }

    &-label {
        grid-area: label;

        @include font-size(small);
        @include line-height(small);
        font-weight: $font-weight__medium;

        display: block;
    }

    &-value {
        grid-area: value;
        display: block;
    }


}
