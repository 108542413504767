.worldImagemap {

    &-region {
        fill: transparent;
        cursor: pointer;
    }

    &-sprite {
        opacity: 0;
        transition: all $base_transition-duration__out;

        pointer-events: none;

        &.is-current {
            opacity: 0;
        }

    }

    &-region[data-selected="true"] &-sprite.is-current {
        opacity: 1;
    }

    &-region:not([data-selected="true"]):hover &-sprite:not(.is-current) {
        opacity: 1;
        transition: all $base_transition-duration__in;
    }

    svg {
        display: block;
    }

}
