@mixin label-supplement(
    $supplement: $label_supplement,
    $required-marker: $label_marker__required,
    $optional-marker: $label-marker__opional,
){

    @if $supplement == null or $supplement == false {
        $supplement: "";
    }

    &::after {
        content: $supplement;
    }

    @if $required-marker and $required-marker != "" {

        @at-root .is-required &::after,
        &.is-required::after {
            content: "#{$required-marker $supplement}" !important;
        }

    }

    @if $optional-marker and $optional-marker != "" {

        @at-root .is-optional &::after,
        &.is-optional::after {
            content: "#{$optional-marker $supplement}" !important;
        }

    }

}
