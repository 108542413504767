.milestonesTimeline {
    @include stack-spacing(section);

    $year_font-size: rem(220px);
    $year_font-size__mobile: (.5 * $year_font-size);
    $year_line-height: .85;

    &-section {
        @include stack-spacing(section);
    }

    &-year {
        @extend h1;

        font-size: $year_font-size__mobile;
        line-height: $year_line-height;
    }

    .timelineEntry {
        @include stack-spacing(section);

        &-year {
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        &-title {
            @include stack-spacing(component);
        }

    }

    @include only-on-desktop(){
        $year_indent: 4;
        $images_column-span: 10;

        &-section {
            position: relative;

            min-height: (2 * $year_line-height * $year_font-size);

            &-title {
            	position: absolute;
            	// position: sticky; // FIXME: Does not work because of body’s hidden overflow
                width: 100%;
            }

        }

        &-year {
            display: block;
        	width: get-column-width(math.div($year-indent, 12));

        	text-align: right;
        	font-size: $year_font-size;

        	display: flex;
        	flex-wrap: wrap;
        	justify-content: flex-end;

        	> span {
            	display: block;
            	text-align: left;

        	    &:nth-child(2) {
            	    flex: 1 0 50%;
            	    text-align: left;
                }

        	    &:nth-child(n+3) {
            	    text-align: right;
                }

        	    &:nth-child(3) {
            	    flex: 1 0 50%;
                }

        	}

        }

        .timelineEntry {

        	> *:not(.timelineEntry-images) {
            	width: get-column-width(math.div(10 - $year-indent, 12));
            	margin-left: auto;
            	margin-right: get-columns-indent(2);
        	}

        	&-images {
                width: get-column-width(math.div($images-column-span, 12));
                margin-left: auto;
                margin-right: 0;
        	}

        	&-title {
                @include stack-spacing();
        	}

        	&-images + .timelineEntry-year + .timelineEntry-title {
            	@include stack-spacing(component);
        	}

        }

        .imageInsert {

            &-image {
                width: (math.div(5, $images-column-span) * 100%);

                &:not(.is-upright):only-child {
                    width: (math.div(7, $images-column-span) * 100%);
                    transform: translateX(math.div(1, 14) * 100%);
                }

            }

            &-image.is-upright {
                width: (math.div(4, $images-column-span) * 100%);

                &:only-child {
                    transform: translateX(-.25 * 100%);
                }

            }

            &-image.is-upright + .imageInsert-image:not(.is-upright),
            &-image:not(.is-upright) + .imageInsert-image.is-upright {
                margin-left: (math.div(1, $images-column-span) * 100%);
            }

            &-image:not(.is-upright) + &-image:not(.is-upright) {
                margin-left: (math.div(1, $images-column-span) * 100%);

                width: (math.div(4, $images-column-span) * 100%);
            }

            &-image.is-upright + &-image.is-upright {
                margin-left: (math.div(1, $images-column-span) * 100%);

                width: (math.div(3, $images-column-span) * 100%);
            }

        }

    }

    @include not-on-desktop(){

        .imageInsert-image + .imageInsert-image {
            margin-top:$stack-spacing__component;
        }

    }

}
