@mixin font-size(
    $type: default,
    $property: font-size,
    $factor: 1,
    $sizes: $font-sizes
){

    @include responsive-variable(
        $type: $type,
        $property: $property,
        $factor: 1,
        $variables: $sizes,
        $prefix: "--fs",
    );

}

@mixin line-height(
    $type: default,
    $property: line-height,
    $factor: 1,
    $sizes: $line-heights
){

    @include responsive-variable(
        $type: $type,
        $property: $property,
        $factor: 1,
        $variables: $sizes,
        $prefix: "--lh",
    );

}

@mixin font-face(
    $font-face
){

    @font-face {
        font-family: map-get($font-face, font-family);

        @include font-files(
            map-get($font-face, font-file),
            $root: map-get($font-face, root)
        );

        font-weight: map-get($font-face, font-weight);
        font-style: map-get($font-face, font-style);
    }

}

@mixin font-faces(
    $font-faces: $font-faces
){

    // Convert single item into list
    @if type-of($font-faces) == map {
        $font-faces: ($font-faces, );
    }

    @each $font-face in $font-faces {
        @include font-face($font-face);
    }


}

@mixin font-files(
    $name,
    $root: $font-faces_root,
    $formats: $font-faces_formats,
){
    $formats: "eot", "woff", "woff2", "ttf";

    $sources: "";

    $formats_include_eot: false;

    @each $format in $formats {

        $suffix: $format;

        @if ($format == "eot"){
            $formats_include_eot: true;

            $suffix: "#{$suffix}?#iefix";
            $format: "embedded-opentype";
        }

        $source: url("#{$root}#{$name}.#{$suffix}") format("#{$format}");

        @if ($sources == ""){
            $sources: $source;
        } @else {
            $sources: $sources, $source;
        }

    }

    @if ($formats_include_eot) {
        src: url("#{$root}#{$name}.eot");
    }

    src: $sources;

}

@mixin special-font-smoothing(
    $thin: true
){

    @if ($thin){
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }

}

@mixin hide-text(){
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
}
