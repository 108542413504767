@function get-heading-styles($size: null, $styles: $headings_styles) {

    @if map-has-key($styles, $size) == false {
        @debug "Error in @function heading_styles: Size was not found!";
        @return (null: null);
    }

    @return map-get($styles, $size);

}

@function get-heading-font-size($size: null, $styles: $headings_styles) {

    $styles: get-heading-styles($size: $size, $styles: $styles);

    @if map-has-key($styles, font-size) == false {
        @debug "Error in @function font-size: Font-size was not found!";
        @return false;
    }

    @return map-get($styles, font-size);

}

@function get-heading-line-height($size: null, $styles: $headings_styles) {

    $styles: get-heading-styles($size: $size, $styles: $styles);

    @if map-has-key($styles, line-height) == false {
        @debug "Error in @function line-height: Line-height was not found!";
        @return false;
    }

    @return map-get($styles, line-height);

}

@function get-heading-margin($size: null, $styles: $headings_styles, $side: bottom) {
        // WIP: Add ability to get single side of margin

    $styles: get-heading-styles($size: $size, $styles: $styles);

    @if map-has-key($styles, margin) == false {
        @return map-get($headings_margin, $side);
    }

    @return map-get($styles, margin);

}

@function add-default-margins(
    $styles-collection: $headings_styles,
    $margin-top: $headings_margin-top,
    $margin-bottom: $headings_margin-bottom,
){

    @each $level, $styles in $styles-collection {

        @if not map-has-key($styles, margin-top) {

            $styles: map-merge(
                $styles,
                (
                    margin-top: $margin-top
                )
            );

        }

        @if not map-has-key($styles, margin-bottom) {

            $styles: map-merge(
                $styles,
                (
                    margin-bottom: $margin-bottom
                )
            );

        }

        $styles-collection: map-merge(
            $styles-collection,
            (
                $level: $styles
            )
        );

    }

    @return $styles-collection;

}

@mixin set-heading-adjacent-margin(
    $styles: (),
    $styles-collection: $headings_styles,
){

    // @if map-has-key($styles, margin-bottom){
    //
    //     @each $level in map-keys($styles-collection) {
    //
    //         & + h#{$level} {
    //             margin-top: (-.8 * map-get($styles, margin-bottom));
    //         }
    //
    //     }
    //
    // }

}

@mixin heading-style(
    $size: null,
    $styles: $headings_styles,
    $properties...
){

    $declarations: get-heading-styles($size: $size, $styles: $styles);

    @if length($properties) > 0 {

        @each $property in $properties {

            @if map-has-key($declarations, $property) {
                #{$property}: map-get($declarations, $property);
            }

        }

    } @else {

        @each $property, $value in $declarations {
            #{$property}: $value;
        }

    }

    display: block;

}
