$figure_background-color: $lightgray !default;

$figure_caption_styles: (
    font-size: $base_font-size__small,
) !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

%figure {
    @include stack-spacing(component);

    &-image {
        width: 100%;
    }

    &:not([style*="background-image"]) &-image {

        &-container {
            display: block;
            position: relative;

            background-color: white;

            &::before {
                content: "";

                display: block;
                width: 100%; height: 100%;

                position: absolute;
                top: 0; left: 0;

                background-color: $page-color__transparent;
                mix-blend-mode: darken;
            }

        }

    }

    @include only-on-desktop(){

        &-caption {
            width: 80%;
        }

    }

}
