%figure {
    @include stack-spacing();

    &-image {
        display: block;
        max-width: 100%;
    }

    &-caption {
        @include styles($figure_caption_styles);
        @include stack-spacing(small);
    }

    &-link {
        display: block;
    }

}

.figure {
    @extend %figure;

    &-image {
        @extend %figure-image;
    }

    &-caption {
        @extend %figure-caption;
    }

    &-link {
        @extend %figure-link;
    }

}

    //** Fixed aspect ratio **//

figure[style*="background-image"] {
    @include fixed-ratio-image();

    &.is-upright {
        @include fixed-ratio-image(math.div(1, $figure_fix-ratio));
    }

}
