$icon_svgs: (
    arrow-down: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" xmlns="http://www.w3.org/2000/svg"><path d="M6 12L11.5 17.5C13.6479 15.3521 14.8521 14.1479 17 12"/><path d="M11.5 17.5V6"/></svg>',
    booth: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M22.0435 22.742H1.95652C1.70284 22.742 1.45954 22.6412 1.28016 22.4618C1.10078 22.2824 1 22.0391 1 21.7855V9.8165C1.00011 9.67139 1.03324 9.52819 1.09687 9.39777C1.16051 9.26735 1.25299 9.15311 1.3673 9.06372L11.4108 1.20303C11.579 1.07147 11.7864 1 12 1C12.2136 1 12.421 1.07147 12.5892 1.20303L22.6327 9.06372C22.747 9.15311 22.8395 9.26735 22.9031 9.39777C22.9668 9.52819 22.9999 9.67139 23 9.8165V21.7855C23 22.0391 22.8992 22.2824 22.7198 22.4618C22.5405 22.6412 22.2972 22.742 22.0435 22.742Z"/></svg>',
    brochure: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M5.5,2.5h12v2"></path><path d="M19.5,4.5v-4h-14c-1.104,0 -2,0.895 -2,2c0,1.104 0.896,2 2,2h15v19h-15c-1.104,0 -2,-0.896 -2,-2v-19"></path></svg>',
    go-back: '<svg width="36" height="24" viewBox="0 0 36 24" fill="none" stroke="{{color}}" stroke-width="1.3" xmlns="http://www.w3.org/2000/svg"><path d="M8 18L2.5 12.5M2.5 12.5L8 7M2.5 12.5H33V19"/></svg>',
    datasheet: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M20.5,23.5h-17v-23h11l6,6Z"></path><path d="M14.5,0.5v6h6"></path><path d="M12.5,10.5h4"></path><path d="M12.5,14.5h4"></path><path d="M12.5,18.5h4"></path><path d="M10.5,8.5l-2.5,2.5l-1.5,-1.5"></path><path d="M10.5,12.5l-2.5,2.5l-1.5,-1.5"></path><path d="M10.5,16.5l-2.5,2.5l-1.5,-1.5"></path></svg>',
    datetime: '<svg width="24" height="24" viewBox="0 0 24 24" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M0.75 9.75H23.25M6.75 6V0.75M17.25 6V0.75M0.75 3.75H23.25V23.25H0.75V3.75Z" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle cx="6" cy="14" r="1"/><circle cx="6" cy="19" r="1"/><circle cx="12" cy="14" r="1"/><circle cx="12" cy="19" r="1"/><circle cx="18" cy="14" r="1"/><circle cx="18" cy="19" r="1"/></svg>',
    download: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M 12.001,3.75v12 "></path><path d="M 7.501,11.25l4.5,4.5l4.5-4.5 "></path><path d="M 23.251,15.75v1.5c0,1.657-1.343,3-3,3h-16.5 c-1.657,0-3-1.343-3-3v-1.5"></path></svg>',
    duration: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5C11.6022 13.5 11.2206 13.342 10.9393 13.0607C10.658 12.7794 10.5 12.3978 10.5 12C10.5 11.6022 10.658 11.2206 10.9393 10.9393C11.2206 10.658 11.6022 10.5 12 10.5M13.0607 13.0607C13.342 12.7794 13.5 12.3978 13.5 12C13.5 11.6022 13.342 11.2206 13.0607 10.9393C12.7794 10.658 12.3978 10.5 12 10.5M13.0607 13.0607L15.75 15.75M12 10.5V5.25M1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12Z"/></svg>',
    email: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M23.5,18c0,0.828 -0.672,1.5 -1.5,1.5h-20c-0.828,0 -1.5,-0.672 -1.5,-1.5v-12c0,-0.829 0.672,-1.5 1.5,-1.5h20c0.828,0 1.5,0.671 1.5,1.5v12Z"></path><path d="M20.5,8.5l-8.5,5.5l-8.5,-5.5"></path><path d="M3.5,16l3.5,-2"></path><path d="M20.5,16l-3.5,-2"></path></svg>',
    external-link: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M20 10L20 4L14 4"/><path d="M20 4L10 14"/><path d="M11 6H4V20H18V13"/></svg>',
    expand: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" xmlns="http://www.w3.org/2000/svg"><path d="M 15.5,8.499l8-8 "></path><path d="M 0.5,23.499l8-8 "></path><path d="M 5.5,23.499h-5v-5 "></path><path d="M 23.5,5.499v-5h-5 "></path><path d="M 15.5,15.499l8,8 "></path><path d="M 0.5,0.499l8,8 "></path><path d="M 0.5,5.499v-5h5 "></path><path d="M 18.5,23.499h5v-5"></path></svg>',
    file-download: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M20.5,23.5h-17v-23h11l6,6Z"></path><path d="M14.5,0.5v6h6"></path></svg>',
    facebook: '<svg viewBox="0 0 24 24" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M18.768,7.5h-4.268v-1.905c0,-0.896 0.594,-1.105 1.012,-1.105c0.418,0 2.988,0 2.988,0v-3.942l-4.329,-0.013c-3.927,0 -4.671,2.938 -4.671,4.82v2.145h-3v4h3c0,5.212 0,12 0,12h5c0,0 0,-6.85 0,-12h3.851l0.417,-4Z"></path></svg>',
    globe: '<svg id="globe" version="1.1" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="1.3" stroke="currentColor" fill="none" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M15.8671 22.5682C14.6618 23.0093 13.3588 23.25 12 23.25C5.78609 23.25 0.75 18.2139 0.75 12C0.75 5.78804 5.78609 0.75 12 0.75C18.2129 0.75 23.25 5.78804 23.25 12"/><path d="M23.25 16.4022C23.25 18.5632 19.337 23.25 19.337 23.25C19.337 23.25 15.4239 18.5632 15.4239 16.4022C15.4239 14.2412 17.175 12.4891 19.337 12.4891C21.4979 12.4891 23.25 14.2412 23.25 16.4022Z"/><path d="M20.3746 15.3645C20.9476 15.9376 20.9476 16.8667 20.3746 17.4397C19.8015 18.0128 18.8724 18.0128 18.2994 17.4397C17.7263 16.8667 17.7263 15.9376 18.2994 15.3645C18.8724 14.7915 19.8015 14.7915 20.3746 15.3645Z"/><path d="M9.55433 17.3804V14.9348L4.66302 11.0217L6.86607 7.59783H12.4891V4.66305L11.0217 3.68479L15.2899 1.23914"/><path d="M20.3152 4.42337L18.6052 7.59783H16.8933L15.1813 9.30978"/></svg>',
    instagram: '<svg width="24" height="24" viewBox="0 0 24 24" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.8119 1.3485C3.34673 1.3485 1.3483 3.34692 1.3483 5.81212V17.7151C1.3483 20.1802 3.34673 22.1787 5.8119 22.1787H17.7149C20.1801 22.1787 22.1784 20.1802 22.1784 17.7151V5.81212C22.1784 3.34692 20.1801 1.3485 17.7149 1.3485H5.8119ZM18.9998 5.81858C18.9998 6.52865 18.4241 7.10429 17.7141 7.10429C17.004 7.10429 16.4283 6.52865 16.4283 5.81858C16.4283 5.10849 17.004 4.53287 17.7141 4.53287C18.4241 4.53287 18.9998 5.10849 18.9998 5.81858ZM11.7636 8.19768C9.79428 8.19768 8.1978 9.79416 8.1978 11.7635C8.1978 13.7328 9.79428 15.3293 11.7636 15.3293C13.733 15.3293 15.3294 13.7328 15.3294 11.7635C15.3294 9.79416 13.733 8.19768 11.7636 8.19768ZM6.48351 11.7635C6.48351 8.84738 8.84749 6.4834 11.7636 6.4834C14.6797 6.4834 17.0437 8.84738 17.0437 11.7635C17.0437 14.6796 14.6797 17.0436 11.7636 17.0436C8.84749 17.0436 6.48351 14.6796 6.48351 11.7635Z"/></svg>',
    language: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M14.7038 22.92C16.2231 20.69 17.2427 16.63 17.2427 12C17.2427 7.37 16.2431 3.31 14.7038 1.08M9.28621 22.92C7.76688 20.69 6.74734 16.63 6.74734 12C6.74734 7.37 7.74689 3.31 9.28621 1.08M23.24 12H0.750008M0.750008 12C0.747103 14.4316 1.53666 16.7978 2.99901 18.74L0.75 23.0988L6.75733 21.95C8.93045 23.0971 11.4193 23.5006 13.8433 23.0988C16.2674 22.6969 18.4931 21.5118 20.1802 19.7247C21.8673 17.9376 22.9229 15.6468 23.1856 13.2027C23.4482 10.7587 22.9035 8.29576 21.6347 6.1907C20.3658 4.08564 18.4428 2.45428 16.1595 1.54609C13.8763 0.6379 11.3586 0.502869 8.9914 1.16165C6.6242 1.82042 4.53782 3.23675 3.05133 5.19403C1.56483 7.15132 0.760032 9.54183 0.760004 12H0.750008ZM21.9406 6.75H2.04943M21.9406 17.25H2.04943"/></svg>',
    link: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M10.4695 13.6667C8.58516 11.7619 8.58516 8.80952 10.4695 6.90476L14.8975 2.42857C16.7818 0.52381 19.7025 0.52381 21.5868 2.42857C23.4711 4.33333 23.4711 7.28571 21.5868 9.19048L18.3835 12.4286M13.5785 10.3333C15.4628 12.2381 15.4628 15.1905 13.5785 17.0952L9.05623 21.5714C7.17194 23.4762 4.25129 23.4762 2.367 21.5714C0.482702 19.6667 0.576917 16.7143 2.46121 14.9048L5.66451 11.6667"/></svg>',
    link-success: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M2 12L9 19L23 5"/></svg>',
    linkedin: '<svg viewBox="0 0 24 24" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M6.527,21.5h-5v-13h5v13Zm-2.509,-15h-0.03c-1.511,0 -2.488,-1.182 -2.488,-2.481c0,-1.329 1.008,-2.412 2.547,-2.412c1.541,0 2.488,1.118 2.519,2.447c-0.001,1.299 -0.978,2.446 -2.548,2.446Zm11.509,6c-1.105,0 -2,0.896 -2,2v7h-5c0,0 0.059,-12 0,-13h5v1.485c0,0 1.548,-1.443 3.938,-1.443c2.962,0 5.062,2.144 5.062,6.304v6.654h-5v-7c0,-1.104 -0.895,-2 -2,-2Z" fill="#000"></path></svg>',
    pencil: '<svg id="pencil" width="25" height="24" viewBox="0 0 25 24" fill="none" stroke="{{color}}" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M7 21.5L0.5 23.5L2.5 17L15.33 4.16899L19.83 8.66899L7 21.5Z" /><path d="M15.33 4.169L18.416 1.083C19.1986 0.306074 20.4614 0.306074 21.244 1.083L22.916 2.755C23.6968 3.536 23.6968 4.802 22.916 5.583L19.83 8.669"/><path d="M17.58 6.41899L6 18"/><path d="M2.5 17L3.5 18H6V20.5L7 21.5"/><path d="M1.5 20.5L3.5 22.5"/><path d="M16.83 2.66899L21.33 7.16899"/></svg>',
    phone: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M7.492,10.012c0.78,-0.781 0.78,-2.048 0,-2.829l-2.122,-2.121c-0.78,-0.78 -2.048,-0.78 -2.828,0l-1.164,1.164c-1.009,1.008 -1.165,2.584 -0.378,3.774c3.38,5.11 7.889,9.619 13,13c1.188,0.785 2.768,0.629 3.773,-0.379l1.165,-1.164c0.78,-0.78 0.78,-2.047 0,-2.828l-2.122,-2.121c-0.781,-0.781 -2.047,-0.781 -2.828,0l-0.707,0.707c-2.326,-1.906 -4.591,-4.17 -6.496,-6.496l0.707,-0.707Z"></path><path d="M12,0.5c6.352,0 11.5,5.148 11.5,11.5"></path><path d="M12,4.5c4.142,0 7.5,3.357 7.5,7.5"></path><path d="M12,8.5c1.933,0 3.5,1.566 3.5,3.5"></path></svg>',
    placeholder: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"/><path d="M9.09 8.00001C9.3251 7.33167 9.78915 6.76811 10.4 6.40914C11.0108 6.05016 11.7289 5.91894 12.4272 6.03872C13.1255 6.15849 13.7588 6.52153 14.2151 7.06353C14.6713 7.60554 14.9211 8.29153 14.92 9.00001C14.92 11 11.92 12 11.92 12V13"/><line x1="12" y1="17" x2="12" y2="17"></line></svg>',
    route: '<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M20 7L23 4M23 4L20 1M23 4H10.5C8.3 4 6.5 5.8 6.5 8C6.5 10.2 8.3 12 10.5 12H13.5C15.7 12 17.5 13.8 17.5 16C17.5 18.2 15.7 20 13.5 20H1M4 17L1 20M1 20L4 23"/></svg>',
    tick: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="2.5" xmlns="http://www.w3.org/2000/svg"><path d="M6 12L10 16L18 8" /></svg>',
    twitter: '<svg viewBox="0 0 24 24" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M23.407,4.834c-0.814,0.363 -1.5,0.375 -2.228,0.016c0.938,-0.562 0.981,-0.957 1.32,-2.019c-0.878,0.521 -1.851,0.9 -2.886,1.104c-0.827,-0.882 -2.009,-1.435 -3.315,-1.435c-2.51,0 -4.544,2.036 -4.544,4.544c0,0.356 0.04,0.703 0.117,1.036c-3.776,-0.189 -7.125,-1.998 -9.366,-4.748c-0.391,0.671 -0.615,1.452 -0.615,2.285c0,1.577 0.803,2.967 2.021,3.782c-0.745,-0.024 -1.445,-0.228 -2.057,-0.568c-0.001,0.019 -0.001,0.037 -0.001,0.057c0,2.202 1.566,4.038 3.646,4.456c-0.666,0.181 -1.368,0.209 -2.053,0.079c0.579,1.804 2.257,3.118 4.245,3.155c-1.944,1.524 -4.355,2.159 -6.728,1.881c2.012,1.289 4.399,2.041 6.966,2.041c8.358,0 12.928,-6.924 12.928,-12.929c0,-0.198 -0.003,-0.393 -0.012,-0.588c0.886,-0.64 1.953,-1.237 2.562,-2.149Z"></path></svg>',
    venue: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M8.65216 7.21739C8.65216 7.65704 8.73875 8.09237 8.907 8.49855C9.07524 8.90473 9.32184 9.27379 9.63272 9.58466C9.94359 9.89554 10.3127 10.1421 10.7188 10.3104C11.125 10.4786 11.5603 10.5652 12 10.5652C12.4396 10.5652 12.875 10.4786 13.2811 10.3104C13.6873 10.1421 14.0564 9.89554 14.3673 9.58466C14.6781 9.27379 14.9247 8.90473 15.093 8.49855C15.2612 8.09237 15.3478 7.65704 15.3478 7.21739C15.3478 6.77775 15.2612 6.34241 15.093 5.93624C14.9247 5.53006 14.6781 5.161 14.3673 4.85012C14.0564 4.53925 13.6873 4.29265 13.2811 4.12441C12.875 3.95616 12.4396 3.86957 12 3.86957C11.5603 3.86957 11.125 3.95616 10.7188 4.12441C10.3127 4.29265 9.94359 4.53925 9.63272 4.85012C9.32184 5.161 9.07524 5.53006 8.907 5.93624C8.73875 6.34241 8.65216 6.77775 8.65216 7.21739V7.21739Z"/><path d="M12 1C13.7391 1.00025 15.4069 1.69129 16.6365 2.92111C17.8661 4.15093 18.5569 5.81881 18.5569 7.55791C18.5569 10.6331 13.8298 18.2566 12.4027 20.4872C12.3594 20.5548 12.2998 20.6103 12.2295 20.6488C12.1591 20.6873 12.0802 20.7075 12 20.7075C11.9198 20.7075 11.8409 20.6873 11.7705 20.6488C11.7001 20.6103 11.6406 20.5548 11.5973 20.4872C10.1702 18.2566 5.44302 10.635 5.44302 7.55791C5.4429 6.69676 5.61241 5.84402 5.94187 5.04838C6.27133 4.25274 6.75429 3.5298 7.36317 2.92083C7.97206 2.31186 8.69493 1.82879 9.49052 1.49921C10.2861 1.16963 11.1388 1 12 1V1Z"/><path d="M16.7826 19.3652C20.4633 19.6751 23 20.3303 23 21.087C23 22.1439 18.0758 23 12 23C5.92417 23 1 22.1439 1 21.087C1 20.3313 3.52044 19.678 7.17722 19.3652"/></svg>',
    xing: '<svg viewBox="0 0 24 24" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M6.789,4.5h-5.066l2.993,5.452l-3.991,6.548h5.066l3.991,-6.548Z"></path><path d="M23.5,0.5h-5.374l-7.983,13.983l5.144,9.017h5.374l-5.144,-9.017Z"></path></svg>',
    youtube: '<svg viewBox="0 0 24 24" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M23.5,7.939c0,-1.9 -1.539,-3.439 -3.438,-3.439h-16.124c-1.899,0 -3.438,1.539 -3.438,3.439v9.121c0,1.9 1.539,3.439 3.438,3.439h16.125c1.898,0 3.438,-1.539 3.438,-3.439v-9.121Zm-14,8.502v-8.662l6.769,4.331l-6.769,4.331Z"></path></svg>',
) !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

.icon {

    &.is-go-back {
        width: 36px;
    }

}
