$modalDialog_backdrop-color: rgba($ci_structure-color, .9) !default;

$modalDialog_title_font-size: largest !default;

$modalDialog_icons: (
    login: '<svg width="64" height="64" viewBox="0 0 64 64" fill="none" stroke="{{color}}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M59.6078 20.2609C56.9013 13.8958 52.0786 8.66149 45.956 5.44389C39.8334 2.22629 32.7871 1.22311 26.0098 2.60417C19.2325 3.98522 13.1406 7.66566 8.76526 13.0225C4.38994 18.3793 2 25.0834 2 32C2 38.9166 4.38994 45.6207 8.76526 50.9775C13.1406 56.3343 19.2325 60.0148 26.0098 61.3958C32.7871 62.7769 39.8334 61.7737 45.956 58.5561C52.0786 55.3385 56.9013 50.1042 59.6078 43.7391"/><path d="M30.6667 21.3333L20 32M20 32L30.6667 42.6667M20 32L62 32"/></svg>',
) !default;

@import "_modalDialog.settings";
@import "_modalDialog.styles";

.modalDialog {
    $icon_size: 128px;
    $icon_size__mobile: 96px;

    z-index: 1000;

    &-box {
        border-top: $base_border-width solid $ci_impulse-color;

        &[data-icon] {
            padding-top: (.5 * $icon_size + px($box-padding));
            padding-top: calc(#{.5 * $icon_size} + var(--bp));

            text-align: center;

            position: relative;

            &::after {
                content: "";

                position: absolute;
                top: (-.5 * $base_border-width);
                left: 50%;

                transform: translate(-50%, -50%);

                display: block;
                width: $icon_size;
                height: $icon_size;

                border-radius: 50%;

                background: $ci_impulse-color 50% 50% no-repeat;
                background-size: 50%;
            }

            form {
                text-align: left;
            }

        }

        @each $name, $icon in $modalDialog_icons {

            &[data-icon="#{$name}"]::after {
                background-image: svg-url($icon);
            }

        }

    }

    &-box[data-icon] &-title {
        @include heading-style(2);

        padding-right: 0;
        margin-top: 0 !important;
    }

    &.has-forminsert &-box {
        background-color: $formFieldset_background-color;
    }

    &.has-overflow.has-forminsert &-box {

        &::before { // Overlay
            background-image: linear-gradient(to top, $formFieldset_background-color, rgba($formFieldset_background-color, 0));
        }

    }

    @include not-on-desktop(){

        &-box {

            &[data-icon] {
                padding-top: (.5 * $icon_size__mobile + px($box-padding));
                padding-top: calc(#{.5 * $icon_size__mobile} + var(--bp));

                &::after {
                    width: $icon_size__mobile;
                    height: $icon_size__mobile;
                }

            }

        }

    }

        // Dynamics365 forms

    .formInsert {
        width: 100%;

        &.is-dynamics-365 .formInsert-content {

            form:not(:first-child) {
                margin-top: 0;
            }

            > div:not([id]) {
                padding: 0;
                margin-top: 0;
            }

        }

    }

}
