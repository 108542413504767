.pageHeader {
    $modifier: "is-wallpaper";
    $image-ratio: 1;
    $image-ratio__desktop: math.div(20, 9);

    &.#{$modifier} {
        display: block;
        margin: 0;

        position: relative;

        .heroImage-figure[style*="background-image"] {
            display: block;
            width: 100vw;
            max-width: $page_max-width;

            position: absolute;
            top: 0; left: 50%;

            z-index: -1;

            transform: translateX(-50%);

            &::before {
                padding-bottom: (math.div(1, $image-ratio) * 100%);
            }

        }

        @include color-themes(
            ".pageHeader-leadtext",
            (
                background-color: primary,
                color: text-color,
            ),
        );

    }

    &.#{$modifier} & {

        &-image {
            padding: 0;
            width: 100vw;
            max-width: $page_max-width;

            &::after { // Spacer
                content: "";

                display: block;
                width: 100%;
                height: 0;

                padding-bottom: (math.div(1, $image-ratio) * 100%);
            }

        }

        &-hgroup {
            width: get-column-width( math.div(10, 12));
            margin: 0;
            padding: 0;

            position: absolute;
            @include stack-spacing(component, top);
            left: get-column-indent();
        }

        &-topic {
            display: block;
        }

        &-title {
            display: block;
            width: 100%;
        }

        &-leadtext {
            $indent: $stack-spacing__section__mobile;

            min-height: $indent;

            padding: $box-padding__mobile;
            margin: (-1 * $indent) auto 0;

            background-color: $page-color;

            font-size: $base_font-size__largest;
            font-weight: $base_font-weight__bold;
        }

    }

    @include only-on-desktop(){

        &.#{$modifier} {

            .heroImage-figure[style*="background-image"] {

                &::before {
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

        }

        &.#{$modifier} & {

            &-image {

                &::after { // Spacer
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

            &-leadtext {
                $indent: (2 * $stack-spacing__section);

                width: get-column-width( math.div(10, 12));
                min-height: $indent;

                padding: $stack-spacing__component get-column-indent();
                margin-top: (-1 * $indent);
            }

        }

    }

    @include not-on-desktop(){

        &.#{$modifier} & {

            &-leadtext {
                font-size: 1.2rem;
                line-height: 1.475;
            }

        }

    }

}
