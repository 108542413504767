$footerNav_gutter: $grid_gutter !default;
$footerNav_font-size: $base_font-size__small !default;

.footerNav {
    $gutter: $footerNav_gutter;

    font-size: $footerNav_font-size;
    font-weight: $base_font-weight__medium;

    user-select: none;

    ul {
        list-style: none;
    }

    &-list {
        padding: 0;
        margin: 0;

        text-align: center;
    }

    &-item {
        padding: 0;

        @include stack-spacing();

        text-transform: uppercase;
        letter-spacing: .05em;

        position: relative;
        z-index: 1;

        &:first-child {
            margin-top: 0;
        }

    }

    &-link {
        display: inline-block;
        padding: 0 (.25 * $gutter);

        text-decoration: none !important;

        &:hover {
            color: $ui-color__hover;
        }

        &:active {
            color: $ui-color__active;
        }

    }

    @include only-on-desktop(){

        &-list {
            display: flex;
            flex-wrap: wrap;
            column-gap: $gutter;
            row-gap: .5em;
        }

        &-item {
            margin-top: 0;
        }

    }

}

@include only-on-desktop(){

    .mainFooter {

        .footerNav {

            &-list {
                // Align vertically
                margin-top: (.5 * ($button_height - px($footerNav_font-size) * $base_line-height));
                margin-bottom: (.5 * ($button_height - px($footerNav_font-size) * $base_line-height));
                position: relative;
                top: .1em;
            }

        }

    }

}
