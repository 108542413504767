.authorCard {
    $portrait-size: 176px;

    @include stack-spacing(component);

    text-align: center;

        // Push module downwards to prevent portrait from overlapping preceding elements
    padding-top: 1px;

    & > .wrapper {
        @include stack-spacing(component, (padding-top, padding-bottom));

            // Push module downwards to prevent portrait from overlapping preceding elements
        margin-top: (.5 * $portrait-size);
    }

    &-portrait,
    &-icon {
        transform: translateY(-50%);

        width: $portrait-size;
        height: $portrait-size;
        margin: (-1 * $stack-spacing__component) auto (-.5 * $portrait-size);

        border-radius: 50%;
        overflow: hidden;

        &[style^="background-image"]::before {
            padding-bottom: 100%;
            border-radius: 50%;
        }

    }

    // Legacy support: Hide container for icon

    &:not([data-icon]):not(.is-media) &-icon {
        display: none;
    }

    &:not([data-icon]):not(.is-media) &-icon + &-name {
        @include stack-spacing(0);
    }

    &-icon + &-icon {
        display: none;
    }

    &-label,
    &-name {
        @extend .is-uppercase;
        font-weight: $base_font-weight__bold;

        @include stack-spacing();
    }

    &-jobtitle {
        display: block;
    }

    &-shortbio {
        @include stack-spacing(component);
    }

    &-channels {
        $gutter: $grid_gutter;
        $icon_size: 24px;

        @include stack-spacing(component);

        &-list {
            list-style: none;
            padding: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            margin: 0 (-.5 * $gutter);
        }

        &-item {
            margin: 0 (.5 * $gutter);
        }

        .button {
            display: block;
        }

    }

    .contactDetails-list,
    .contactDetails-channels {
        justify-content: center;
    }

    @include not-on-desktop(){

        & > .wrapper {
            @include full-width-backdrop();
        }

    }

    @include only-on-desktop(){

        & > .wrapper {
            $padding-x: get-column-indent();

            padding-left: $padding-x;
            padding-right: $padding-x;

            background-color: $backdrop-color;
        }

    }

}


    //***** Media *****//

.authorCard {
    $type: "media";

    $icon: get-svg-data-url('<svg viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M13.077 22.5L12.837 22.114C12.1246 21.0372 11.7133 19.7894 11.646 18.5M7.353 22.5L7.593 22.114C8.30544 21.0372 8.71667 19.7894 8.784 18.5"/><path d="M5.61501 22.5H14.815"/><path d="M0.615005 15.5H20.615"/><path d="M20.615 12.5V17.5C20.615 17.7652 20.5096 18.0196 20.3221 18.2071C20.1346 18.3946 19.8802 18.5 19.615 18.5H1.61501C1.34979 18.5 1.09544 18.3946 0.907899 18.2071C0.720362 18.0196 0.615005 17.7652 0.615005 17.5V2.5C0.615005 2.23478 0.720362 1.98043 0.907899 1.79289C1.09544 1.60536 1.34979 1.5 1.61501 1.5H19.615C19.8802 1.5 20.1346 1.60536 20.3221 1.79289C20.5096 1.98043 20.615 2.23478 20.615 2.5"/><path d="M22.219 10.059C22.1932 10.1837 22.1252 10.2956 22.0265 10.3759C21.9277 10.4563 21.8043 10.5001 21.677 10.5H13.669C13.5866 10.5001 13.5053 10.4819 13.4309 10.4465C13.3565 10.4112 13.2909 10.3597 13.239 10.2957C13.187 10.2318 13.15 10.1571 13.1306 10.077C13.1113 9.99698 13.11 9.9136 13.127 9.833L14.281 4.941C14.3068 4.81634 14.3748 4.70439 14.4735 4.62405C14.5723 4.54371 14.6957 4.49989 14.823 4.5H22.831C22.9134 4.49987 22.9947 4.51814 23.0691 4.55348C23.1435 4.58882 23.2091 4.64033 23.2611 4.70426C23.313 4.76818 23.35 4.84291 23.3694 4.92297C23.3887 5.00303 23.39 5.0864 23.373 5.167L22.219 10.059Z"/><path d="M14.482 4.618L17.316 7.67C17.498 7.86624 17.745 7.98996 18.0111 8.01827C18.2773 8.04658 18.5447 7.97757 18.764 7.824L23.248 4.685"/><path d="M7.61501 4.5H11.615"/><path d="M7.61501 7.5H10.615"/><path d="M7.61501 10.5H9.61501"/></svg>',
    $color: $white
);

    &-icon[data-icon="#{$type}"],
    &.is-#{$type} &-icon { // Legacy support
        background: $ci_leading-color url($icon) 58% 53% no-repeat;
        background-size: 55%;
    }

}
