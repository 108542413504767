.countdownTimer {
    @include stack-spacing();

    $diameter: 160px;
    $inset: 2px;
    $marker_size: 19px;

    $PI: 3.1415926536;
    $SQRT_2: 1.4142135624;

    $progress: 0;

    border-color: $ci_leading-color;

    * {
        border-color: inherit;
    }

    &-values {
        display: flex;
        justify-content: center;

        margin-left: (-.25 * $diameter);
        margin-right: (-.25 * $diameter);
    }

    &-value {
        flex: 0 0;

        padding-left: (.25 * $diameter);
        padding-right: (.25 * $diameter);

        > .wrapper {
            display: block;
            width: $diameter;
            height: $diameter;

            box-sizing: content-box;
            padding: $inset;

            color: $ci_impulse-color;

            position: relative;
            z-index: 1;

            &::before { // Full circle
                content: "";

                display: block;

                position: absolute;
                top: (.5 * ($inset + 1px));
                bottom: (.5 * ($inset + 1px));
                left: (.5 * ($inset + 1px));
                right: (.5 * ($inset + 1px));

                z-index: -1;

                border: 1px solid;
                border-color: inherit;
                opacity: .4;
                border-radius: 50%;
            }

        }

        &-number {
            position: absolute;
            top: 54%;
            left: 50%;
            transform: translate(-50%, -50%);

            font-size: (.5 * $diameter);
            line-height: 1;
            font-weight: $font-weight__medium;
            font-variant-numeric: tabular-nums;
            letter-spacing: -.03em;
        }

        &-label {
            @include stack-spacing(small);

            text-align: center;
            @include font-size(small);
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }

        &-path {
            $length: math.div($PI * $diameter, 1px);
            stroke-dasharray: $length;
            stroke-dashoffset: ((1 - $progress) * $length);
        }

        &-marker-container {
            display: block;
            width: math.div($diameter, $SQRT_2);
            height: math.div($diameter, $SQRT_2);

            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%) rotate(45deg);
            transform-origin: center;
        }

        &-marker {
            display: block;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            transform: rotate($progress * 360deg);

            &::after {
                content: "";

                display: block;
                width: $marker_size;
                height: $marker_size;

                position: absolute;
                top: 0;
                left: 0;

                transform: translate(-50%, -50%);

                z-index: 1;

                background-color: $ci_impulse-color;
                border-radius: 50%;
            }

        }

    }

    @include not-on-desktop(){
        $diameter: 80px;
        $inset: 2px;
        $marker_size: 9px;

        $gutter: $grid_gutter__mobile;

        &-values {
            margin-left: (-.5 * $gutter);
            margin-right: (-.5 * $gutter);
        }

        &-value {
            padding-left: (.5 * $gutter);
            padding-right: (.5 * $gutter);

            > .wrapper {
                width: $diameter;
                height: $diameter;
            }

            &-number {
                font-size: (.5 * $diameter);
            }

            &-marker-container {
                width: math.div($diameter, $SQRT_2);
                height: math.div($diameter, $SQRT_2);
            }

            &-marker {

                &::after {
                    width: $marker_size;
                    height: $marker_size;
                }

            }

        }

        &-path {
            $length: math.div($PI * $diameter, 1px);
            stroke-dasharray: $length;
            stroke-dashoffset: ((1 - $progress) * $length);
        }

    }

}

