$headings_font-family: $base_font-family !default;
$headings_font-weight: 700 !default;

$headings_margin-top: $stack-spacing__component !default;
$headings_margin-top__mobile: $stack-spacing__component__mobile !default;

$headings_styles: (

    1: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: rem(100px),
        line-height: 1.15,
        margin-top: $stack-spacing__section,
    ),

    2: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: rem(40px),
        line-height: 1.35,
        margin-top: $stack-spacing__section,
        margin-bottom: -.15em,
    ),

    3: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: rem(28px),
        margin-top: $stack-spacing__component,
        line-height: 1.35,
    ),

    4: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: 1.15rem,
        margin-bottom: .15rem,
    ),

    5: (
        font-family: $headings_font-family,
        font-weight: $base_font-weight__medium,
        font-size: $base_font-size__small,
        //line-height: 2,
        text-transform: uppercase,
        letter-spacing: .025em,
        margin-bottom: .15rem,
    ),

    6: (
        font-family: $headings_font-family,
        font-weight: $base_font-weight__medium,
        font-size: $base_font-size__smallest,
        //line-height: 2.2,
        text-transform: uppercase,
        letter-spacing: .025em,
        margin-bottom: .15rem,
    )

) !default;

$headings_styles__mobile: (

    1: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: rem(40px, $base_font-size__mobile),
        line-height: math.div(46, 40),
        margin-top: $stack-spacing__section__mobile,
    ),

    2: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: rem(24px, $base_font-size__mobile),
        line-height: math.div(32, 24),
        margin-top: $stack-spacing__section__mobile,
    ),

    3: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: rem(20px, $base_font-size__mobile),
        line-height: 1.35,
    ),

    4: (
        font-family: $headings_font-family,
        font-weight: $headings_font-weight,
        font-size: 1.05rem,
        margin-bottom: .15rem,
    ),

    5: (
        font-family: $headings_font-family,
        font-weight: $base_font-weight__medium,
        font-size: $base_font-size__small,
        //line-height: 2,
        text-transform: uppercase,
        letter-spacing: .025em,
        margin-bottom: .15rem,
    ),

    6: (
        font-family: $headings_font-family,
        font-weight: $base_font-weight__medium,
        font-size: $base_font-size__smallest,
        //line-height: 2.2,
        text-transform: uppercase,
        letter-spacing: .025em,
        margin-bottom: .15rem,
    )

) !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";


@media (max-width: (360px - 1)){

    h1, h2, h3, h4, h5, h6 {
        hyphens: auto;
    }

}
