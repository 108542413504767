.affinityLinks {
    $gutter: $stack-spacing;

    @include stack-spacing(section);

    text-align: center;

    &-title {
        margin-bottom: (-1 * $gutter);
    }

    &-items {
        list-style: none;
        padding: 0;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin-top: (-1 * $gutter);
        margin-left: (-.5 * $gutter);
        margin-right: (-.5 * $gutter);
    }

    &-title + &-items {
        @include stack-spacing(component);
    }

    &-item {
        margin-top: $gutter;
        padding: 0 (.5 * $gutter);
    }

    &-link {
        margin-top: 0;
    }

    @include not-on-desktop(){
        $gutter: $stack-spacing__mobile;

        &-title {
            margin-bottom: (-1 * $gutter);
        }

        &-items {
            margin-top: (-1 * $gutter);
            margin-left: (-.5 * $gutter);
            margin-right: (-.5 * $gutter);
        }

        &-item {
            margin-top: $gutter;
            padding: 0 (.5 * $gutter);
        }

    }

}
