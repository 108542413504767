// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.downloadList {

    .downloadCard {
        margin-top: 0;
    }

    &-item + &-item {

        .downloadCard {
            border-top: none;
        }

    }

}
