.socialChannelsBar {
    $gutter: $grid_gutter;

    @include stack-spacing(component);

    &-list {
        list-style: none;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0 (-.5 * $gutter);
    }

    &-item {
        margin: 0 (.5 * $gutter);
    }

    .button {
        display: block;
        margin-top: 0;
    }

}
