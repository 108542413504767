.fiftyfiftySlide {
    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    display: flex;
    flex-direction: column;
    min-width: 100%;

    @include color-themes((
        color: text-color,
    ));

    .figure,
    .video {
        margin-top: 0;
    }

    @include full-width-backdrop();

    &::before {

        @include color-themes((
            background-color: primary,
        ));

    }

    &.is-off-white::before {
        background-color: $page-color;
    }

    > .wrapper {
        flex: 1 0 auto;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-topic {
        font-size: $base_font-size__small;
        font-weight: $base_font-weight__bold;
        text-transform: uppercase;
        letter-spacing: .025em;
    }

    &-title {
        @include stack-spacing(small);
    }

    &-content:not(:empty) {
        @include stack-spacing(component);

        & > *:first-child {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        min-height: 50vw;

        > .wrapper {
            width: 50%;
            @include stack-spacing(component, padding-left);
            margin: 0 0 0 auto;
        }

        &.is-reversed {

            > .wrapper {
                margin-right: auto;
                margin-left: 0;
            }

        }

        &-image,
        &-video {
            position: absolute;
            top: 0; left: 50%;

            transform: translateX(-100%);

            width: 50vw; height: 100%;
            max-width: (.5 * $page_max-width);
        }

        &-image {

            .figure {

                &,
                & * {
                    height: 100%;
                    width: 100%;
                }

                &-image {
                    object-fit: cover;
                }

            }

        }

        &-video {
            overflow: hidden;

            .video {
                position: absolute;
                top: 0; left: 50%;
                transform: translateX(-50%);

                height: 100%; width: auto;
                max-width: none;
            }

        }

            //** Reversed **//

        &.is-reversed {

            > .wrapper {
                padding-left: 0;
                @include stack-spacing(component, padding-right);

                margin-right: auto;
                margin-left: 0;
            }

        }

        &.is-reversed & {

            &-image,
            &-video {
                transform: translateX(0%);
            }

        }

    }

    @include media-query(
        $min-width: $page_max-width,
    ){
        min-height: (.5 * $page_max-width);
    }

    @include not-on-desktop(){

        &-image,
        &-video {
            margin-left: (-1 * $page_padding__mobile);
            margin-right: (-1 * $page_padding__mobile);

            margin-top: (-1 * $stack-spacing__section__mobile);
        }

        &-image + .wrapper,
        &-video + .wrapper {
            @include stack-spacing(component);
        }

        &-image {

            .figure::before {
                background-color: transparent !important;
            }

        }

    }

}
