// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

@include only-on-desktop(){

    .mainFooter {
        position: relative;
        z-index: 1;

        pointer-events: none;

        > *:not(.mainSection-title) {
            pointer-events: auto;
        }

    }

    .imageInsert {

        .imagePreview {
            position: relative;
            z-index: 1;

        }

    }

    .videoInsert {
        position: relative;
        z-index: 1;
    }

    .mainFooter {

        &::before {
            border-top: $base_border-width solid $page-color;
        }

        .topLink {
            top: $base_border-width;
        }

        .newsletterPanel + & {

            &::before {
                border-top: $base_border-width solid $backdrop-color__opaque;
            }

            .topLink {
                top: $base_border-width;
            }

        }

    }

}

.socialShareBar {

    @media (pointer: coarse) {
        display: none !important;
    }

    @include not-on-desktop(){
        display: none !important;
    }

}

.socialShareBar.js-makeSticky {

    &.is-sticky {
        position: absolute;
    }

    &.is-stuck {
        position: fixed;
    }

}
