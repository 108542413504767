.caseSummary {
    $border-width: 6px;

    @include stack-spacing(section);

    &-title {
        text-align: center;
    }

    &-content {
        @include stack-spacing(component);

        > *:first-child {
            margin-top: 0;
        }

    }

    &-summary {
        @include stack-spacing(component);

        > .wrapper {
            @include box-padding();

            background-color: $backdrop-color;
            border-top: $border-width solid $ci_impulse-color;
        }

        &-logo {
            @include stack-spacing(default, margin-bottom);
            width: 50%;

            background-color: $page-color;
        }

        &-title {
            font-size: 1rem;
            @extend .is-uppercase;

            &::before {
                content: "";

                display: inline-block;
                width: calc((100% + #{2 * $box-padding} + #{$grid_gutter}) * #{1/6} - #{$grid_gutter});
                min-width: 3ch;
                height: .66em;

                margin-right: .8em;

                background-color: $text-color;
            }

        }

        &-heading {
            $styles: get-heading-styles(3);

            @include styles($styles);

            @include stack-spacing();
        }

        &-description {
            margin-top: 0;

            > * {
                margin-top: 0;
            }

        }

    }

    .keyFactList {
        @include stack-spacing();

        &-fact {
            font-size: get-heading-font-size(2);
            line-height: get-heading-line-height(2);
        }

        &-description {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        $columns_span: 10;

        width: get-column-width(math.div($columns_span, 12));
        margin-left: auto; margin-right: auto;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        > * {
            flex: 0 1 auto;
            width: get-column-width(math.div(6, $columns_span));
        }

        &-title {
            flex: 1 0 auto;
            width: 100%;
        }

        &-content:not(:last-child) {
            width: 50%;
            padding-right: (.5 * $grid_gutter);
        }

        &-content + &-summary {
            width: 50%;
            padding-left: (.5 * $grid_gutter);
        }

        &-summary {

            &-title {

                &::before {
                    width: calc((100% + #{2 * $box-padding} + #{$grid_gutter}) * #{math.div(1, math.div($columns_span, 2))} - #{$grid_gutter});
                }

            }

        }

    }

    @include not-on-desktop(){

        &-summary {

            &-logo {
                width: (math.div(2, 3) * 100%);
            }

        }

        .keyFactList {

            &-fact {
                font-size: get-heading-font-size(2, $headings_styles__mobile);
                line-height: get-heading-line-height(2, $headings_styles__mobile);
            }

        }

    }

}
