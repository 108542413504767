.formValidator {
    $transition-duration__in: .1s;
    $transition-duration__out: .3s;

    label {
        transition: color $transition-duration__out;
    }

    .parsley-error label {
        color: $error-color;
        transition: color $transition-duration__in;
    }

    input {
        transition: border-color $transition-duration__out;

        &.parsley-error {
            border: 2px solid $error-color;
            text-indent: 0;

            transition: border-color $transition-duration__in;
        }

    }

    .button.disabled {
        cursor: pointer !important;
    }

}

.parsley-errors {

    &-list {
        list-style: none;
        padding: 0;

        li:not(:first-child) {
            @include stack-spacing();
        }

    }

}
