.leadCard {
    $modifier: "has-forminsert";

    $icon_size: 136px;
    $icon_data: get-icon-data-url(book-flip-page, $text-color, $ctaInsert_icons);
    $border-width: (2 * $base_border-width);

    &.#{$modifier} {
        @include box-padding();
        padding-top: 0;

        background-color: $backdrop-color__opaque;
        border-top: $border-width $ci_impulse-color solid;

        margin-top: calc(#{$stack-spacing__section} + #{.5 * $icon_size});
        margin-top: calc(var(--sp-section) + #{.5 * $icon_size});

        &::before {
            content: "";

            display: block;
            width: $icon_size; height: $icon_size;

            position: relative;
            top: (-.5 * $icon_size - .5 * $border-width);
            left: 50%;
            z-index: 1;

            margin-bottom: (-.5 * $icon_size);

            background: $ci_impulse-color url($icon_data) center center no-repeat;
            background-size: 60%;
            border-radius: 50%;
        }

        > .wrapper {
            @include stack-spacing(component, padding-top);
        }

        .highlightList-item {

            &::before {
                background-color: $ci_impulse-color;
            }

        }

        .authorCard {

            > .wrapper {
                width: 100%;
                background: transparent;
            }

        }

            // Align form

        .formInsert {
            width: 100%;
        }

        .formInsert.is-dynamics-365 .formInsert-content {
            margin-top: (-1 * $stack-spacing);
            margin-top: calc(-1 * var(--sp));

            > * {
                margin-top: 0;
                padding: 0;
            }

            form:not(:first-child) {
                margin-top: 0;
            }

        }


    }

    &.#{$modifier} & {

        &-image {
            display: none;
        }

        &-author {
            text-align: center;
        }

        &-author-cta {
            @include heading-style(4, $headings_styles);
            font-weight: $font-weight__medium;
            margin-top: 0;
        }

        &-header,
        &-summary {
            order: initial;
        }

    }

    &.#{$modifier}.has-authorcard {

        &::before {
            $icon_data: get-icon-data-url(notes-edit, $text-color, $ctaInsert_icons);
            background-image: url($icon_data);
        }

    }

    &.#{$modifier}.has-authorcard & {

        &-summary {
            display: none;
        }

    }

    @include only-on-desktop(){

        &.#{$modifier} {

            > .wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                margin-left: (-.5 * $grid_gutter);
                margin-right: (-.5 * $grid_gutter);

                > * {
                    flex: 0 1 auto;
                    width: 50%;

                    padding-left: (.5 * $grid_gutter);
                    padding-right: (.5 * $grid_gutter);
                }

            }

        }

        &.#{$modifier} & {
            $columns_span: 4;

            &-header {
                width: 100%;
            }

            &-summary {
                width: calc((100% + #{$grid_gutter} + #{$box-padding}) * #{math.div($columns-span, 12)} - #{$grid_gutter} + #{$box-padding});
                margin-left: calc(#{$grid_gutter} + (100% + #{$grid_gutter} + #{$box-padding}) * #{math.div(1, 12)} - #{$grid_gutter} - #{$box-padding});
            }

        }

    }

    @include not-on-desktop(){

        &.#{$modifier} &-summary + &-form {
            @include stack-spacing(component);
        }

        &.#{$modifier} & {

            &-author-cta {
                @include heading-style(4, $headings_styles__mobile);
                font-weight: $font-weight__medium;
                margin-top: 0;
            }

        }

    }

}
