.revealableContent {

    @include stack-spacing();

    border-bottom: 1px $border-color solid;

    &-summary {
        $styles: map-get($revealableContent_summary_styles, default);
        @include styles($styles);

        @include box-padding($revealableContent_box-padding);

        user-select: none;
        cursor: pointer;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &::-webkit-details-marker {
            display: none;
        }

        &::after {
            content: "";
            display: block;

            $styles: map-get($revealableContent_marker_styles, default);
            @include styles($styles);
        }

    }

    &-content {

        .wrapper {
            @include box-padding($revealableContent_box-padding);

            > *:first-child {
                margin-top: 0 !important;
            }

        }

    }

    &[open] &-summary,
    &-toggle:checked ~ &-summary {
        $styles: map-get($revealableContent_summary_styles, open);
        @include styles($styles);

        &::after {
            $styles: map-get($revealableContent_marker_styles, open);
            @include styles($styles);
        }

    }

        // Add compatibility for outdated browsers

    &-toggle {
        display: none;
    }

    &:not(details):not([open]) &-content {
        height: 0;
        overflow: hidden;
    }

    &-toggle:checked ~ &-content {
        height: auto;
        overflow: visible;
    }

}
