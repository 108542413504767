.eventSummary {
    $border-width: 6px;

    @include stack-spacing(section);

    &-title {
        text-align: center;
    }

    &-content {
        @include stack-spacing(component);

        > *:first-child {
            margin-top: 0;
        }

    }

    &-agenda {
        @include stack-spacing(component);

        > .wrapper {
            @include box-padding();
            background-color: $backdrop-color;
        }

        &-title {
            font-size: 1rem;
            @extend .is-uppercase;

            &::before {
                content: "";

                display: inline-block;
                width: calc((100% + #{2 * $box-padding} + #{$grid_gutter}) * #{1/6} - #{$grid_gutter});
                min-width: 3ch;
                height: .66em;

                margin-right: .8em;

                background-color: $text-color;
            }

        }

        .timeTable {
            @include stack-spacing();
        }

    }

    @include only-on-desktop(){
        $columns_span: 10;

        width: get-column-width(math.div($columns_span, 12));
        margin-left: auto; margin-right: auto;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        > * {
            flex: 0 1 auto;
            width: get-column-width(math.div(6, $columns_span));
        }

        &-title {
            flex: 1 0 auto;
            width: 100%;
        }

        &.has-several-days &-content,
        &-content:last-child {
            width: get-column-width(math.div(8, $columns_span));
        }

        &:not(.has-several-days) &-content:not(:last-child) {
            width: 50%;
            padding-right: (.5 * $grid_gutter);
        }

        &:not(.has-several-days) &-content + &-agenda {
            width: 50%;
            padding-left: (.5 * $grid_gutter);
        }

        &-agenda {

            &-title {

                &::before {
                    width: calc((100% + #{2 * $box-padding} + #{$grid_gutter}) * #{math.div(1, math.div($columns_span, 2))} - #{$grid_gutter});
                }

            }

        }

        &.has-several-days &-agenda {
            width: 100%;

            > .wrapper {
                @include stack-spacing(component, (padding-top, padding-bottom));
                padding-left: get-columns-indent(1, $columns_span);
                padding-right: get-columns-indent(1, $columns_span);

                display: flex;
                flex-wrap: wrap;
                column-gap: $grid_gutter;

                display: flex;

                > * {
                    flex: 0 1 auto;
                }

            }

            &-title {
                width: 100%;

                &::before {
                    width: calc((100% + #{$grid_gutter}) * #{math.div(1, $columns-span - 2)} - #{$grid_gutter});
                }

            }

            &-timetable {
                width: 45%;
                width: calc(50% - #{.5 * $grid_gutter});
            }

        }

    }

}

html[dir="rtl"] {

    @include only-on-desktop(){

        .eventSummary {

            &-agenda {

                &-title {

                    &::before {
                        margin-right: auto;
                        margin-left: .8em;
                    }

                }

            }

            &-content:not(:last-child) {
                padding-right: 0;
                padding-left: (.5 * $grid_gutter);
            }

            &-content + &-agenda {
                padding-left: 0;
                padding-right: (.5 * $grid_gutter);
            }

        }

    }

}
