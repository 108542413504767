$grid_gutter: 2rem !default;

// $base_gutter: 2rem !default;
//   Deprecated! Use `$grid_gutter`

    //***** Page *****/

$page_max-width: 1440px !default; // To get full width layout, set $page_max-width to `none`.
$page_content_max-width: 1200px !default;

$page_padding: $grid_gutter !default;
$page_padding__mobile: $grid_gutter !default;

$page_background: null !default;
$page_box-shadow: 0 0 20px rgba(black, .05), 0 0 5px rgba(black, .1) !default;

    //***** Horizontal grid *****/

$grid_gutter__mobile: $page_padding__mobile !default;

$grid_number-of-cols: 12 !default;
$grid_col-spans: 2, 3, 4, 6 !default;

$grid_col-ratios: (
    2 1,
    1 2,
    7 5,
    5 7,
    3 1,
    1 3
) !default;

$grid_item_min-width: get-flowbox-item-min-width();

$breakpoint__tablet: 640px !default;
$breakpoint__desktop: 1024px !default;

    //***** Vertical spacing *****//

$stack-spacing: 1rem !default;
$stack-spacing__mobile: $stack-spacing !default;

$stack-spacing__small: (.5 * $stack-spacing) !default;
$stack-spacing__small__mobile: (.5 * $stack-spacing__mobile) !default;

$stack-spacing__component: (2 * $stack-spacing) !default;
$stack-spacing__component__mobile: (2 * $stack-spacing__mobile) !default;

$stack-spacing__section: (4 * $stack-spacing) !default;
$stack-spacing__section__mobile: (4 * $stack-spacing__mobile) !default;

$stack-spacings: (
    default: (
        default: $stack-spacing,
        small: $stack-spacing__small,
        component: $stack-spacing__component,
        section: $stack-spacing__section,
    ),
    mobile: (
        default: $stack-spacing__mobile,
        small: $stack-spacing__small__mobile,
        component: $stack-spacing__component__mobile,
        section: $stack-spacing__section__mobile,
    ),
) !default;

// $base_section_margin: (4.5rem) !default;
//   Deprecated! Use `$stack-spacing__section`

    //***** Boxes *****//

$box-padding: 1rem !default;
$box-padding__mobile: .5rem !default;
// $base_box_padding: 1rem !default;
//   Deprecated! Use `$box-padding`

$box-paddings: (
    default: (
        default: $box-padding,
    ),
    mobile: (
        default: $page_padding__mobile,
    ),
) !default;

$base_border-radius: 5px !default;

    //***** Calculated variables *****/

@if ($page_max-width != none){
    $page_padding: .5 * ($page_max-width - $page_content_max-width);
}
