//***** Dynamics 365 forms *****//

%dynamics365Form-fieldset {
    position: relative;

    > div:not([id]):not([data-form-block-id]),
    *[data-form-block-id] {
        @extend %formFieldset;
    }

    form {
        @extend %formFieldset-content;
    }

}

.dynamics365Form {

    > .wrapper {
        @extend %dynamics365Form-fieldset;
    }

}

.is-dynamics-365 {

    img {
        display: inline-block;
    }

    *[data-layout="true"] {
        margin-left: (-.5 * $grid_gutter);
        margin-right: (-.5 * $grid_gutter);
    }

    *[data-section="true"] *[data-container="true"] {
        padding: 0 (.5 * $grid_gutter) !important;
    }

    *[data-editorblocktype="Text"] {
        display: none;
    }

    .columnContainer {

        .lp-form-button {
            @include stack-spacing(component);
            width: 100%;
        }

    }

    .formLoader {
        position: relative;
        @include box-padding(default, top, $factor: .5);

        border-top-color: $ci_impulse-color !important;
    }

    @include only-on-mobile(){

            // Remove columns on mobile

        .columnContainer {
            width: 100% !important;
        }

    }

    div[data-form-block-id] .onFormSubmittedFeedback {
        width: 100% !important;
        height: auto !important;

        background-color: transparent !important;
        display: block !important;

        @include box-padding(default, padding-top);

        &::before {
            content: "";

            display: block;
            width: 0; height: 100%;

            position: absolute;
            top: 0; left: 0;

            border-left: $base_border-width $text-color solid;
        }

        img {
            display: none;
        }

        button {
            @extend %button;
        }

    }

    .onFormSubmittedFeedbackInternalContainerStyle {
        padding: 0 !important;
    }

    .onFormSubmittedFeedbackMessage {
        padding: 0 !important;
        text-align: left !important;

        font-family: $base_font-family !important;
        font-size: 1rem !important;
        color: $text-color !important;
    }

    .dialogProviderContainerStyle {
        width: 100% !important;
        height: auto !important;
    }

    .dialogProviderInternalContainerStyle {
        padding: 0 !important;
    }

        // Hide reload button

    *[data-submissionresponse="success"] {

        button {
            display: none !important;
        }

    }

}

.lp {

    &-form-field > &-ellipsis {
        @extend %formField-label;
        font-weight: 500 !important; //FIXME: Should use the variable set in label component
    }

    &-required {
        display: none;
    }

    &-form {

        &-fieldInput:not(&-button) {
            @extend %formField-input;
        }

        &-button {
            @extend %button;

            &[type="reset"] {
                display: none;
            }

        }

    }

    &-form-field[data-required-field="false"] &-ellipsis {

        &::after {
            content: "#{$label-marker__opional $label_supplement}" !important;
        }

    }

    &-radioButtonsContainer > &-ellipsis {
        @include stack-spacing(small, margin-bottom);
    }

    &-radioButton {
        margin-top: $stack-spacing__small !important;
        margin-top: var(--sp-small) !important;
        margin-right: $grid_gutter !important;
    }

    &-radioButton &-form-fieldInput {
        @extend %formField-radio-input;
    }

    &-radioButton &-ellipsis {
        @extend %formField-radio-label;
    }

    &-form-fieldInput[type="checkbox"] {
        @extend %formField-checkbox-input;
    }

    &-form-fieldInput[type="checkbox"] + &-ellipsis {
        @extend %formField-checkbox-label;
        @include stack-spacing();
    }

}

select.lp-form-fieldInput {
    // FIXME: Marker is not visible
    @extend %formField-select-input;
}

    // Adjustments for embedding in modal dialog

.modalDialog {

    .is-dynamics-365 {

        > .wrapper {

            > div:not([id]) {
                margin-top: 0;
                padding: 0;
            }

            *[data-form-block-id] {
                margin-top: 0 !important;

                .onFormSubmittedFeedback {
                    padding: 0;

                    &::before {
                        display: none;
                    }

                }

                .onFormSubmittedFeedbackMessage {
                    text-align: center !important;
                }

            }

        }

    }

}

    // Move submit button to left

@include not-on-mobile(){

    .is-dynamics-365 {

        .columnContainer[data-container-width="50"]:nth-child(2) + .columnContainer[data-container-width="50"]:nth-child(3) {

            .lp-form-button[type="submit"] {

                transform: translateX(-100%);
                position: relative;
                left: (-1 * $grid_gutter);
            }

        }

    }

}

html[dir="rtl"] {

    .is-dynamics-365 {

        .columnContainer[data-container-width="50"]:nth-child(2) + .columnContainer[data-container-width="50"]:nth-child(3) {

            .lp-form-button[type="submit"] {
                transform: translateX(100%);
                left: auto;
                right: (-1 * $grid_gutter);
            }

        }

    }

}

#ms_captcha_holder {
    @include stack-spacing();

    *[style="padding:0 0 8px 0"] {
        padding-bottom: 0 !important;
    }

    label {
        @extend %label;
    }

    label + * {
        @include stack-spacing(small);
        @include font-size(small);

        b {
            font-weight: $font-weight;
        }

    }

    table, thead, tbody, tr {
        display: block;
        width: auto;
        margin: 0;
        padding: 0;

        &:empty {
            display: none;
        }

    }

    table {
        width: 100% !important;
    }

    td, th {
        display: inline;
        margin: 0;
        padding: 0;

        &:empty {
            display: none;
        }

    }

    img {
        @include stack-spacing(small);
        @include stack-spacing(small, margin-bottom);

        &:not([style="display: none;"]) {
            display: block !important;
        }

    }

    input {
        @extend %field;

        width: 100% !important;
        padding: $field_padding !important;
        border-width: $field_border-width !important;
    }

    #wlspispHIPErrorContainer {
        @include stack-spacing(small, margin-bottom);

        align-items: center;

        @include font-size(small);
        color: $error-color;

        &[style="padding: 0px; display: block;"] {
            display: flex !important;
        }

        td {
            width: auto !important;
            padding: 0 !important;
        }

        img {
            display: none !important;
            margin: 0 .5em 0 0;
        }

    }

}

//***** Hide Dynamics 365 error messages in Design Library *****//

.doc-mainContainer  {

    .dialogProviderContainerStyle {
        display: none !important;
    }

}
