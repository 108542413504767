$label_styles: map-merge(
    (
        font-size: $base_font-size__smallest,
        color: $text-color !important,
    ),
    $base_uppercase_styles
) !default;

$label-supplement: "" !default;
$label_optional-marker: " (optional)" !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";
