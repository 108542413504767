$headings_styles: add-default-margins();

$headings_styles__mobile: add-default-margins(
    $styles-collection: $headings_styles__mobile,
    $margin-top: $headings_margin-top__mobile,
    $margin-bottom: $headings_margin-bottom__mobile,
);

@each $level, $styles in $headings_styles {

    h#{$level} {
        @include styles($styles);

        &:first-child {
            margin-top: 0;
        }

            // Remove space between headlines
        @include set-heading-adjacent-margin($styles, $headings_styles);

    }

}

//** mobile **//

@include not-on-desktop() {

    @each $level, $styles in $headings_styles__mobile {

        h#{$level} {
            @include styles($styles);

                // Remove space between headlines
            @include set-heading-adjacent-margin($styles, $headings_styles__mobile);

        }

    }

}

    // Fix Bootstraps "bolder"

h1, h2, h3, h4, h5, h6 {

    strong, em {
        font-weight: inherit;
    }

}
