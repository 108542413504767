@mixin has-max-width(
    $max-width: $page_max-width,
){
    max-width: $max-width;
}

@mixin has-full-width(
    $max-width: $page_max-width,
){
    width: 100vw;
    width: calc(100vw - var(--scrollbar-width, 0));

    @if $max-width {
        max-width: $max-width;
    }

    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@mixin has-limited-width(
    $max-width: $page_max-width,
){
    padding-left: $page_padding__mobile;
    padding-right: $page_padding__mobile;

    @include only-on-desktop(){
        padding-left: $page_padding;
        padding-right: $page_padding;

        margin-left: auto;
        margin-right: auto;

        &:not(.has-limited-wrapper),
        &.has-limited-wrapper > .wrapper {
            max-width: $max-width;
        }
    }

    @media (min-width: $breakpoint__desktop - 1) and (max-width: (.5 * $page_content_max-width + .5 * $breakpoint__desktop)){
        padding-left: (.5 * $page_padding);
        padding-right: (.5 * $page_padding);
    }

}

@mixin has-limited-wrapper(
    $wrapper-class: ".wrapper",
    $max-width: $page_max-width,
){

    & > #{$wrapper-class} {
        padding-left: $page_padding__mobile;
        padding-right: $page_padding__mobile;

        margin-left: auto;
        margin-right: auto;
    }

    @include only-on-desktop(){

        & > #{$wrapper-class} {
            max-width: $max-width;

            padding-left: $page_padding;
            padding-right: $page_padding;
        }

    }

}

@mixin full-width-backdrop(
    $background: $backdrop-color,
    $pseudo-element: before,
    $max-width: $page_max-width,
){
    position: relative;

    &::#{$pseudo-element} {
        content: "";

        display: block;
        width: 101vw;
        width: calc(100vw + 1px); // Fix backdrop offset on odd screen resolutions
        height: 100%;

        @if $max-width {
            max-width: $max-width;
        }

        position: absolute;
        top: 0; left: 50%;

        transform: translateX(-50%);

        z-index: -1;

        background: $background;
        background-size: cover;
    }

}

    //****************//
    //***** Grid *****//
    //****************//

@function get-column-width(
    $fraction: 1
){
    $fallback_gutter: 2%;

    $width: 100%;

    @if $fraction == 1 {
        @return $width;
    } @else {
        @return calc((100% + #{$grid_gutter}) * #{$fraction} - #{$grid_gutter});
    }
}

@mixin set-column-width(
    $fraction: 1
){
    $fallback_gutter: 2%;

    @if $fraction == 1 {
        width: 100%;
    } @else {
        width: ((100% + $fallback_gutter) * $fraction - $fallback_gutter); // Fallback
        width: get-column-width($fraction);
    }
}

@mixin grid-row(
    $gutter: $grid_gutter,
){
    display: flex;
    margin-left: (-.5 * $gutter);
    margin-right: (-.5 * $gutter);
}

@mixin grid-column(
    $ratio: 0,
    $centered: false,
    $gutter: $grid_gutter
){

    @if $ratio == 0 {
            // If witdh is not set, balance width of all columns in a row.
        flex: 1;
    } @else {
        flex: 0 1 auto;
        width: math.div(floor(10000% * $ratio), 100);
    }

    padding: 0 (.5 * $gutter);

    @if ($centered){
        margin-left: auto;
        margin-right: auto;
    }

}

@function get-column-indent(
    $columns: $grid_number-of-cols,
){
    // Get calculated value to indent element one single column

    @return get-columns-indent(1, $columns);
}

@function get-columns-indent(
    $span: $grid_number-of-cols,
    $number-of-cols: $grid_number-of-cols,
){
    // Get calculated value to indent element n columns)

    $indent: calc(#{$grid_gutter} + (100% + #{$grid_gutter}) * #{math.div($span, $number-of-cols)} - #{$grid_gutter});

    @return $indent;
}

@mixin only-last-row(
    $column-count: 1
){
    // Select elements in last row of a grid with "$column-count" columns
    // ------------------------------------------------------------------
    // Thanks to Hugh Grigg
    // https://www.hughgrigg.com/posts/css-select-last-grid-row/

    &:nth-of-type(#{$column-count}n+1):nth-last-of-type(-n+#{$column-count}),
    &:nth-of-type(#{$column-count}n+1):nth-last-of-type(-n+#{$column-count}) ~ * {
        @content;
    }

}

@function get-flowbox-item-min-width(
    $max-columns: 4,
){
    @return floor(math.div(($page_content_max-width - $scrollbar-width - (($max-columns - 1) * $grid_gutter)), ($max-columns * 10))) * 10;
}

@mixin grid-flowbox(
    $min-width: $grid_item_min-width,
    $max-columns: null,
    $stack-spacing: 0,
){

    @if $max-columns != null {

        $min-width: get-flowbox-item-min-width(
            $max-columns: $max-columns,
        );

    }

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(#{"min(100%,"} $min-width#{")"}, 1fr));
    grid-auto-flow: row;
    gap: $stack-spacing var(--gg, #{$grid_gutter});

    > .has-full-width {
        grid-column: 1 / -1;
    }

}

    //***** Breakpoints *****//

@mixin only-on-mobile(){

    @include media-query(
        $max-width: $breakpoint__tablet - 1
    ){
        @content;
    }

}

@mixin not-on-mobile(){

    @include media-query(
        $min-width: $breakpoint__tablet
    ){
        @content;
    }

}

@mixin only-on-tablet(){

    @include media-query(
        $min-width: $breakpoint__tablet,
        $max-width: $breakpoint__desktop
    ){
        @content;
    }

}

@mixin not-on-tablet(){

    @include only-on-mobile(){
        @content;
    }

    @include only-on-desktop(){
        @content;
    }

}

@mixin only-on-desktop(){

    @include media-query(
        $min-width: $breakpoint__desktop
    ){
        @content;
    }

}

@mixin not-on-desktop(){

    @include media-query(
        $max-width: $breakpoint__desktop - 1
    ){
        @content;
    }

}

@mixin media-query(
    $min-width: 0,
    $max-width: none
){

    @if ($min-width == 0) and ($max-width == none){
        @content;
    }
    @else if ($min-width == 0){

        @media (max-width: $max-width){
            @content;
        }

    }
    @else if ($max-width == none){

        @media (min-width: $min-width){
            @content;
        }

    }
    @else {

        @media (min-width: $min-width) and (max-width: $max-width) {
            @content;
        }

    }

}

    //***** Fallback for Bootstrap’s grid mixins *****//

@mixin media-breakpoint-down($breakpoint) {

    @if $breakpoint == "sm" {

        @include only-on-mobile(){
            @content;
        };

    } @else if $breakpoint == "md" {

        @include not-on-desktop(){
            @content;
        };

    }

}

@mixin media-breakpoint-up($breakpoint) {

    @if $breakpoint == "md" {

        @include not-on-mobile(){
            @content;
        };

    }

    @if $breakpoint == "lg" {

        @include only-on-desktop(){
            @content;
        };

    }

}

    //*************************//
    //***** Stack spacing *****//
    //*************************//

@mixin stack-spacing(
    $type: default,
    $property: margin-top,
    $factor: 1,
    $spacings: $stack-spacings
){

    @if $type == 0 {

        #{$property}: 0;

    } @else {

        @include responsive-variable(
            $type: $type,
            $property: $property,
            $factor: $factor,
            $variables: $spacings,
            $prefix: "--sp",
        );

    }

}

    //***********************//
    //***** Box padding *****//
    //***********************//

@mixin box-padding(
    $type: default,
    $property: padding,
    $factor: 1,
    $paddings: $box-paddings
){

    @include responsive-variable(
        $type: $type,
        $property: $property,
        $factor: $factor,
        $variables: $paddings,
        $prefix: "--bp",
    );

}
