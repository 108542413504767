.timelineEntry {
    @include stack-spacing(component);

    &-year {
        display: block;

        @extend .is-uppercase;
        font-weight: $base_font-weight__bold;
    }

    &-images + &-year {
        @include stack-spacing(component);
    }

    &-title {
        @extend h2;
        margin-top: 0;
    }

    &-description {
        @include stack-spacing();
    }

    &-link {
        @include stack-spacing();
    }

    .imageInsert {
        @include stack-spacing(component);
    }

}
