%closeButton {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;

    display: inline-block;
    vertical-align: middle;

    cursor: pointer;

    position: relative;

    &-label {
        @extend %visually-hidden;
    }

    &-icon {
        display: block;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        transition-duration: $base_transition-duration__out;

        @include styles($closeButton_styles);

        svg {
            display: block;
            position: absolute;

            stroke-width: 2;
        }

    }

    &:hover &-icon {
        transition-duration: $base_transition-duration__in;

        @include state-styles($closeButton_states, hover);
    }

    &:focus &-icon {
        @include state-styles($closeButton_states, focus);
    }

    &:focus:not(:focus-visible):not(:hover) &-icon {
        @include state-styles($closeButton_states);
    }

    &:active &-icon {
        @include state-styles($closeButton_states, active);
    }

    @each $name, $size in $closeButton-sizes {

        $modifier: "";

        @if $name != default {
            $modifier: ".is-#{$name}";
        }

        $stroke-width: math.div(2 * $closeButton_size, $size);

        &#{$modifier} {
            width: $size;
            height: $size;
        }

        &#{$modifier} &-icon {
            display: block;
            width: $size;
            height: $size;

            svg {
                display: block;

                position: absolute;
                top: (.25 * $size - $closeButton_border-width);
                left: (.25 * $size - $closeButton_border-width);

                width: (.5 * $size);
                height: (.5 * $size);

                stroke-width: $stroke-width;
            }

        }

    }

}

.closeButton {
    @extend %closeButton;

    &-label {
        @extend %closeButton-label;
    }

    &-icon {
        @extend %closeButton-icon;
    }

}
