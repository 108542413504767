.ul {
    list-style: none;
    padding-left: 0;

    > li {
        @extend .ul-li !optional;
    }

    .ul, .ol {
        @include stack-spacing(small);
    }

}

.ul-li {
    padding-left: ($list_item_ident + $list_marker_ident);

    position: relative;

    & + & {
        @include stack-spacing(small);
    }

    &::before {
        @include styles($list_marker_styles);
    }

}

.ol {
    list-style: none;
    padding-left: 0;

    counter-reset: list;

    > li {
        @extend .ol-li !optional;
    }

    .ul, .ol {
        @include stack-spacing(small);
    }

}


.ol-li {
    padding-left: ($list_item_ident + $list_marker_ident);

    counter-increment: list;

    position: relative;

    & + & {
        @include stack-spacing(small);
    }

    &::before {
        content: counter(list) ".";
        position: absolute;
        margin-left: (-1 * $list_marker_ident);
    }

}


.dl {

    &-title {
        @include styles($list_title_styles);
    }

    &-data + &-title {
        @include stack-spacing(small);
    }

    > dt {
        @extend .dl-title !optional;
    }

    &-data {
        @include styles($list_data_styles);
    }

    > dd {
        @extend .dl-data !optional;
    }

}
