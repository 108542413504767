$closeButton_size: 24px !default;
$closeButton_border-width: 1px !default;
$closeButton_background-color: transparent !default;

$closeButton_sizes: (
    small: 16px,
    default: $closeButton_size,
    large: 32px,
) !default;

$closeButton_styles: (
    color: $ui-color,
    background-color: $closeButton_background-color,
    border: $closeButton_border-width solid $ui-color,
    transition-property: (background-color, color, border-color),
) !default;

$closeButton_states: (
    default: $closeButton_styles,
    hover: (
        background-color: $ui_background-color__hover,
        color: $white,
    ),
    focus: (
        background-color: $ui_background-color__hover,
        color: $white,
    ),
    active: (
        background-color: $ui_background-color__active,
    ),
) !default;
