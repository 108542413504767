.mainNav {
    $gutter: $mainNav_gutter;

    user-select: none;

    ul {
        list-style: none;
    }

    &-list {
        padding: 0;
    }

    &-item {
        @include styles($mainNav_item_style);
    }

    &-link {
        display: block;
        padding: 0 (.25 * $gutter);

        color: $ui-color;
    }

    @include only-on-desktop(){

        &-list {
            margin: 0 (-.5 * $gutter);

            display: flex;
            align-items: center;
        }

        &-item {
            padding: 0 (.25 * $gutter);
        }

    }

}
