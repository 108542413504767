.keyFactList {
    $fact_font-size: rem(64px);
    $fact_font-size__mobile: rem(42px);
    $icon_size: 96px;
    $icon_size__mobile: 64px;

    list-style: none;
    padding: 0;

    @include stack-spacing(component);

    &-item + &-item {
        @include stack-spacing();
    }

    &-illustration {
        color: inherit;

        font-size: $fact_font-size;
        line-height: 1;

        display: flex;

        svg {
            display: block;

            box-sizing: content-box;
            height: 48px; width: auto;

            position: relative;
            top: 0em;
        }

        &::after {
            content: "";

            display: block;
            width: 0; height: $fact_font-size;
        }

    }

    &-icon {
        display: inline-block;

        width: $icon_size;
        height: $icon_size;

        .streamlineIcon,
        .icon {
            display: block;
            width: 100%;
            height: 100%;

            top: 0;
        }

        .icon {
            @include icon--set-stroke-width(2);
        }

    }

    &-title {
        display: block;
        @include stack-spacing();

        font-weight: $font-weight__medium;
    }

    &-fact {
        display: block;

        font-size: $fact_font-size;
        line-height: 1;
        font-weight: $base_font-weight__bold;
    }

    &-unit {
        font-size: .66em;
        margin-left: .125em;
    }

        // Hide fact if illustration is set
    &-illustration + &-fact {
        display: none;
    }

    &-description {
        display: block;
        @include stack-spacing(small);

        @include font-size(small);
        @include line-height(small);
    }

    &-title + &-description {
        @include stack-spacing(0);
    }

    @include only-on-desktop(){

        &-title {
            @include font-size(large);
            @include line-height(large);
        }

        &-title + &-description {
            font-weight: $font-weight__medium;
        }

    }

    @include not-on-desktop(){

        &-illustration {
            font-size: $fact_font-size__mobile;
            height: math.div(48px * $fact_font-size__mobile, $fact_font-size);

            svg {
                transform: scale(math.div($fact_font-size__mobile, $fact_font-size));
                transform-origin: top left;

                overflow: hidden;
            }

            &::after {
                height: $fact_font-size__mobile;
            }

        }

        &-icon {
            width: $icon_size__mobile;
            height: $icon_size__mobile;

            .icon {
                @include icon--set-stroke-width(2);
            }

        }

        &-fact {
            font-size: $fact_font-size__mobile;
            line-height: 1;
        }

        &-icon + &-title {
            @include stack-spacing(small);
        }

        &-title + &-description {
            @include stack-spacing(small);
        }

    }

}

    //***** Side by side *****//

%keyFactList--side-by-side {

    @include only-on-desktop(){

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;

        margin-left: (-.5 * $grid_gutter);
        margin-right: (-.5 * $grid_gutter);

        margin-top: 0;

        .keyFactList-item,
        &-item {
            flex: 0 0 auto;
            width: 33.33%;

            padding-left: (.5 * $grid_gutter);
            padding-right: (.5 * $grid_gutter);

            @include stack-spacing(component);

            &:nth-child(3n+1):nth-last-child(4) {
                margin-left: (.25 * 33.33%);
            }

            &:nth-child(3n+2):nth-last-child(3) {
                margin-right: (.25 * 33.33%);
            }

        }

    }

}

.keyFactList {

    &.is-side-by-side {
        @extend %keyFactList--side-by-side;
    }

    &.is-side-by-side &-item {
        @extend %keyFactList--side-by-side-item
    }

}
