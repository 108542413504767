$responsiveTable_column_width: 40vw;

.responsiveTable {
    $padding: 1em;
    $column_width: $responsiveTable_column_width;

    &-header {
        display: none;
    }

    @include only-on-mobile(){
        width: 100%;
        overflow-x: scroll;

        &.has-fixed-column {
            width: calc(100% + #{$page_padding__mobile});

            overflow-x: hidden;
            position: relative;

            &::after {
                content: "";

                display: block;
                width: (1.5 * $page_padding__mobile); height: 100%;

                position: absolute;
                top: 0; right: 0;
                z-index: 3;

                background-image: linear-gradient(to left, rgba($page-color, 1) 10%, rgba($page-color, .5) 40%, rgba($page-color, 0));
            }

            .table {
                width: auto;
                min-width: 100%;

                &-th, &-td {
                    min-width: $column_width;

                    &:first-child {
                        padding-right: $padding;
                    }

                    &:nth-child(2) {
                        padding-left: $padding;
                    }

                        // Only two columns

                    &:nth-last-child(2):first-child,
                    &:nth-child(2):last-child {
                        min-width: calc(50vw - #{$page_padding__mobile});
                        min-width: calc(50vw - #{.5 * $page_padding__mobile + 1});
                    }

                }

            }

        }

        &-header {
            display: block;

            position: absolute;
            top: 0; left: 0;
            z-index: 2;

            overflow: hidden;

            pointer-events: none;

            .table {

                &-th,
                &-td {

                    &:first-child {
                        border-right: 1px solid $text-color;
                    }

                    &:not(:first-child) {
                        visibility: hidden;
                    }

                }

            }

        }

        &-content {
            display: block;

            width: calc(100% - #{$column_width});
            overflow-x: scroll;

            margin-left: $column_width;

            position: relative;
            z-index: 1;

            .table {
                position: relative;

                margin-left: (-1 * $column_width);

                &::after {
                    content: "";

                    display: block;
                    width: $page_padding__mobile; height: 100%;

                    position: absolute;
                    top: 0; right: 0;

                    background-color: $page-color;
                }

                &-caption {
                    visibility: hidden;
                }

                &-th,
                &-td {

                    &:first-child {
                        visibility: hidden;
                    }

                }

            }

        }

    }

    @include not-on-mobile(){

        &-content {
            width: auto !important;
            margin-left: 0 !important;

            .table {
                margin-left: 0 !important;
            }

        }

    }

}


    //***** Right to left *****//

html[dir="rtl"] {

    @include only-on-mobile(){

        .responsiveTable {
            $column_width: $responsiveTable_column_width;

            &.has-fixed-column {

                &::after {
                    right: auto;
                    left: 0;
                    transform: scaleX(-1);
                }

            }

            &-header {
                left: auto;
                right: 0;
            }

            &-content {
                margin-left: 0;
                margin-right: $column_width;

                .table {
                    margin-left: 0;
                    margin-right: (-1 * $column_width);
                }

            }

        }

    }

}
