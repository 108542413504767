.highlightListInsert {
    @include stack-spacing(section);

    &-description {

        &:not(:first-child) {
            @include stack-spacing();
        }

        > *:first-child {
            @include stack-spacing(0);
        }

    }

    .highlightList:not(:first-child) {
        @include stack-spacing(component);
    }

}

.highlightListInsert {
    $modifier: "is-emphasised";

    $border-width: 12px;

    &.#{$modifier} {
        @include stack-spacing(section, padding-top);
        @include stack-spacing(section, padding-bottom);

        @include full-width-backdrop();

        &::before {
            border-top: $border-width solid $ci_impulse-color;
        }

        @include only-on-desktop(){

            > .wrapper {
                width: get-column-width(math.div(8, 12));
                margin: 0 auto;
            }

        }

    }

}
