.searchPanel {
    $theme: leading-color;

    $text-color: get-theme-color($theme, text-color);
    $background-color: get-theme-color($theme, primary);
    $accent-color: get-theme-color($theme, accent-color);

    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    color: $text-color;

    @include full-width-backdrop($background-color);

    &-title {
        @extend h2;
        margin-top: -.7em !important;
    }

    &-form {
        width: 100%;
        display: flex;
        align-items: stretch;

        position: relative;
    }

    &-title + &-form {
        @include stack-spacing();
    }

    &-field {
        flex: 1 0 auto;
        margin-top: 0;

        position: relative;

        &-placeholder {

            position: absolute;
            top: 0; left: 0;

            height: 100%; min-height: 0;

            color: transparent;
            background-color: transparent;
            border-bottom-color: transparent;

            pointer-events: none;
            transition: none;

            display: none;
        }

        &-input {
            border-color: $gray__opaque !important;
            height: 100%; min-height: 0;

            &:focus {
                border-color: $accent-color !important;
            }

            &::placeholder {
                color: transparent;
            }

        }

        .label {
            position: absolute;

            display: block;
            width: 0;

            overflow: hidden;
        }

    }

    &-form.is-empty &-field-placeholder {
        display: block;
    }

    &-button {
        margin: 0 0 0 (.5 * $grid_gutter);

        display: block;

        color: $background-color;
        background-color: $accent-color;

        &[disabled] {
            color: $background-color !important;
            background-color: $accent-color !important;
        }

        &:hover {
            color: $background-color;
            background-color: $text-color;
        }

    }

    &-controls {
        display: none; // Hide controls by default

        position: absolute;
        @include stack-spacing(default, top);

        width: 100%;

        &-button {
            $size: 42px;

            display: block;
            height: $size; width: $size;

            margin: 0 0 0 auto;

            background-color: transparent;
            color: $text-color;

            cursor: pointer;

            @include base-transition();

            position: relative;

            &:hover {
                color: $background-color;
                background-color: $accent-color;

                @include base-transition(in);
            }

            .icon {
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);

                display: block;
            }

        }

    }

    @include only-on-desktop(){
        text-align: center;

	    > .wrapper {
            width: get-column-width(math.div(6, 12));
            margin: 0 auto;
        }

        &-field {
            font-size: $base_font-size__large;
        }

    }

    @include not-on-desktop(){

        &-button.is-icon-only {
            $size : $field_height__mobile;

            height: $size; min-height: 0;
            width: $size;

            padding: 0;
            margin-left: (.5 * $grid_gutter__mobile);
        }

    }

}
