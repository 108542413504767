.pageSummary {
    $border-width: 12px;

    @include stack-spacing(component);

    & > .wrapper {
        @include stack-spacing(component, padding-top);
        @include stack-spacing(component, padding-bottom);
    }

    &-text-cta {
        @include stack-spacing();
    }

    @include not-on-desktop(){

        @include full-width-backdrop();

        &::before {
            border-top: $border-width solid $ci_impulse-color;
        }

        & > .wrapper {

            & > *:first-child { // Push first child downwards
                margin-top: $border-width;
            }

            & > *:not(:first-child){
                @include stack-spacing(component);
            }

        }

    }

    @include only-on-desktop(){
        $padding-x: get-column-indent();

        background-color: $backdrop-color;
        border-top: $border-width solid $ci_impulse-color;

        & > .wrapper {
            display: flex;
            flex-wrap: wrap;

            @include grid-row();

            padding-left: $padding-x;
            padding-right: $padding-x;
        }

        &-title {
            flex: 1 0 auto;
            width: 100%;

            text-align: center;
        }

        &-list,
        &-text {
            @include grid-column();
        }

        &-text {

            &-title {
                margin-top: -.15em !important;
            }

        }

    }

}
