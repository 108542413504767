.is-applicationSupport {

    .pageHeader + .mainSection {
        @include stack-spacing(component);
    }

    .mainSection + .mainFooter {
        margin-top: 0;

        &::before { // Line
            border: none;
        }

    }

}
