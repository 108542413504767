$list_item_ident: 1em !default;

$list_marker_ident: 1em !default;
$list_marker_size: .7em !default;
$list_marker_color: lighten($text-color, 30%) !default;

$list_marker_styles: (
    content: "",
    display: inline-block,
    width: $list_marker_size,
    height: 0,
    margin-right: (-1 * $list_marker_size),
    position: relative,
    left: (-1 * $list_marker_ident),
    vertical-align: middle,
    top: -.075em,
    border-bottom: 1px solid $list_marker_color,
) !default;

$list_title_styles: (
    font-weight: $base_font-weight__bold,
) !default;

$list_data_styles: (
    margin-left: 0,
) !default;
