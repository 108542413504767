.contentBlock {
    $image_ratio: $contentBlock_default-image_ratio;

    @include stack-spacing(component);

    &-figure {
        @extend .figure;
        margin-top: 0;
    }

    &-caption {
        @extend .figure-caption;
    }

    &-image {
        @extend .figure-image;
    }

    &-content {

        > *:first-child {
            margin-top: 0;
        }

    }

    &-footer {
        @include stack-spacing();
    }

    &-cta {
        display: inline-block;
    }

    ul {
        @extend .ul;
    }

    ul > li {
        @extend .ul-li;
    }

    ol {
        @extend .ol;
    }

    ol > li {
        @extend .ol-li;
    }

}

@include only-on-desktop(){
    $max-width: (math.div(8, 12) * 100%);

    .contentBlock {

        &-figure {
            margin-bottom: 0;
        }

            //** Columns **//

        @include grid-row();

        justify-content: center;

        &-column {
            @include grid-column();

            flex-basis: 50%;
            max-width: $max-width;
        }

            // Alternating order

        &.has-reversed-order,
        & + &:nth-child(even) {
            flex-direction: row-reverse;
        }

        & + &:nth-child(odd) {
            flex-direction: row;
        }

            //** Small image **//

        &.has-small-image &-column {
            $image_col-span: $contentBlock_small-image_col-span;

            &:nth-child(odd) {
                flex-basis: (100% * math.div($image_col-span, 12));
            }

            &:nth-child(even) {
                flex-basis: (math.div(12 - $image_col-span, 12) * 100%);
            }

        }

            //** Large image **//

        &.has-large-image &-column {
            $image_col-span: $contentBlock_large-image_col-span;

            &:nth-child(odd) {
                flex-basis: (100% * math.div($image_col-span, 12));
            }

            &:nth-child(even) {
                flex-basis: (math.div(12 - $image_col-span, 12) * 100%);
            }

        }

    }

}
