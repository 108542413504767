.downloadCard {
    $gutter: $grid_gutter;

    $icons: (
        brochure,
        datasheet,
    );

    @include stack-spacing(component);

    @include stack-spacing(default, padding-top);
    @include stack-spacing(default, padding-bottom);

    border: 1px $gray;
    border-style: solid none;

    position: relative;

    & + & {
        margin-top: 0;
        border-top: none;
    }

    &-category {
        $icon_size: 10%;
        $icon_data__default: get-icon-data-url(file-download);

        @extend .is-uppercase;

        &::before { // Icon
            content: "";

            display: block;
            width: $icon_size; height: 0;
            padding-bottom: $icon_size;

            position: absolute;
            top: 50%; left: get-columns-indent(2);

            transform: translate(-100%, -50%);
            margin-left: (-1.2 * $grid_gutter);

            background: url($icon_data__default) $backdrop-color 50% 50% no-repeat;
            background-size: 50%;

            border-radius: 50%;
        }


        @each $icon in $icons {

            &.is-#{$icon}::before {
                $icon_data: get-icon-data-url($icon);
                background-image: url($icon_data);
            }

        }

    }

    &.is-highlight &-category {
        $icon_data__default: get-icon-data-url(file-download, $white);

        &::before { // Icon
            background-color: $accent-color;
            background-image: url($icon_data__default);
        }

        @each $icon in $icons {

            &.is-#{$icon}::before {
                $icon_data: get-icon-data-url($icon, $white);
                background-image: url($icon_data);
            }

        }

    }

    &-title {
        @include heading-style(3);
        @include stack-spacing(small);
    }

    &-metadata {
        font-size: $base_font-size__small;

        &-list {
            display: flex;
            flex-wrap: wrap;

            @include stack-spacing(small);
        }

        &-key {
            width: 0;

                // Hide text
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

        }

        &-value {
            margin: 0;

            &:not(:first-child){

                &::before {
                    content: "/";
                    padding: 0 .25em;
                }

            }

        }

        &-key:first-child + &-value {

            &::before {
               display: none;
            }

        }

    }

    @include only-on-desktop(){

        & > .wrapper {
            padding-left: get-columns-indent(2);
            padding-right: get-column-indent();

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-links {
            display: flex;

            .button {
                flex: 0 0 auto;
                margin: 0 0 0 $gutter;
            }

        }

    }

    @include not-on-desktop(){

        &-category {
            $icon_size: 96px;

            &::before {
                width: $icon_size; height: 0;
                padding-bottom: $icon_size;

                position: static;

                transform: none;
                margin-left: 0;

                @include stack-spacing(default, margin-bottom);
            }

        }

        &-links {
            @include stack-spacing(small);
        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .downloadCard {

        &-category {

            &::before {
                left: auto;
                right: get-columns-indent(2);

                transform: translate(100%, -50%);
                margin-left: auto;
                margin-right: (-1.2 * $grid_gutter);
            }

        }

    }

    @include only-on-desktop(){

        .downloadCard {

            > .wrapper {
                padding-right: get-columns-indent(2);
                padding-left: get-column-indent();
            }

            &-links {

                .button {
                    margin: 0 $grid_gutter 0 0;
                }

            }

        }

    }

}
