@mixin teaserCard--large (
    $modifier: is-large,
){
    $padding: $box-padding;

    $title_font-size: rem(50px);
    $title_line-height: 1.3;

    $title_vertical-indent: (0.5 * $title_font-size * $title_line-height);

    $image_ratio: 2;
    $image_ratio__mobile: math.div(3, 2);

    &.#{$modifier} {
        padding: $padding;

        background-color: $backdrop-color;

            //** Theming **//

        @include color-themes((
            color: secondary,
            background-color: primary,
        ));

    }

    &.#{$modifier} & {

        &-header {
            padding-top: 0;
        }

        &-topic {
            @include reset-topic();
        }

        &-title {
            font-size: $title_font-size;
            line-height: $title_line-height;
        }

        &-content {
            font-weight: $base_font-weight__medium;

            margin-top: $padding;
        }

        &-cta {
            @include stack-spacing(component);
        }

    }

        // Theming Active Blue

    &.#{$modifier}.is-active-color,
    &.#{$modifier}.is-active-blue {

        .button {
            background-color: get-theme-color(active-color, secondary__hover);

            &:hover {
                background-color: get-theme-color(active-color, secondary);
            }

            &.is-secondary {
                color: get-theme-color(active-color, secondary__hover);
                background-color: transparent;
                border-color: get-theme-color(active-color, secondary__hover);

                &:hover {
                    color: get-theme-color(active-color, secondary);
                    border-color: get-theme-color(active-color, secondary);
                }

            }

        }

    }

    &.#{$modifier}.is-active-color &,
    &.#{$modifier}.is-active-blue & {

        &-header,
        &-content,
        &-cta {
            color: $white;
        }

    }

    @include only-on-desktop(){

        &.#{$modifier} {
            max-width: get-column-width(math.div(6, 12));
        }

    }

        //***** With Image *****//

    &.#{$modifier}.has-image {
        padding-top: 0;

        .figure {

            &::before {
                background-color: $black;
                padding-bottom: (math.div(1, $image-ratio) * 100%);
            }

            &::after { // Gradient
                content: "";

                display: block;
                width: 100%; height: 75%;

                position: absolute;
                bottom: 0; left: 0;

                background: linear-gradient(to top, rgba(black, .8), rgba(black, .4), rgba(black, .15), rgba(black, 0));
                mix-blend-mode: multiply;
            }

        }

    }

    &.#{$modifier}.has-image & {

        &-image {
            margin: 0 (-1 * $padding);
        }

        &-header {
            position: absolute;
            top: 0; left: 0;

            width: 100%; height: 0;
            padding-bottom: (math.div(1, $image-ratio) * 100%);

            > .wrapper {
                position: absolute;
                bottom: 0; left: 0;

                padding: $padding $padding 0;
                width: 100%;

                transform: translateY($title_vertical-indent);
            }

        }

    }


        //***** Responsivness *****//

    @include not-on-desktop(){
        $padding: $box-padding__mobile;

        $title_font-size: $teaserCard_title_font-size__mobile;
        $title_line-height: $teaserCard_title_line-height__mobile;

        $title_vertical-indent: (0.5 * $title_font-size * $title_line-height);

        &.#{$modifier} {
            padding: 0 $padding $padding;
        }

        &.#{$modifier} & {

            &-image {
                margin: 0 (-1 * $padding);
            }

            &-header {

                > .wrapper {
                    padding-top: $padding;
                }

            }

            &-title {
                font-size: $title_font-size;
                line-height: $title_line-height;
            }

            &-content {
                margin-top: $stack-spacing__mobile;
            }

            &-cta {
                @include stack-spacing();
            }

        }

        &.#{$modifier}.has-image {

            .figure {

                &::before {
                    padding-bottom: (math.div(1, $image-ratio__mobile) * 100%);
                }

            }

        }

        &.#{$modifier}.has-image & {

            &-header {
                padding-bottom: (math.div(1, $image-ratio__mobile) * 100%);

                > .wrapper {
                    transform: translateY($title_vertical-indent);
                }

            }

            &-content {
                margin-top: $padding;
            }

        }

    }

    @include only-on-small-desktop(){
        $title_font-size: (.5 * $title_font-size + .5 * get-heading-font-size(3));
        $title_line-height: (.5 * $title_line-height + .5 * get-heading-line-height(3));

        $title_vertical-indent: (0.45 * $title_font-size * $title_line-height);

        &.#{$modifier} & {

            &-title {
                font-size: (.5 * $title_font-size + .5 * get-heading-font-size(3));
                line-height: (.5 * $title_line-height + .5 * get-heading-line-height(3));
            }

            &-cta {
                margin-top: $stack-spacing;
            }

        }

        &.#{$modifier}.has-image & {

            &-header {

                > .wrapper {
                    transform: translateY($title_vertical-indent);
                }

            }

        }

    }

}

.teaserCard {
    @include teaserCard--large();
}

.teaserCard {
    @include teaserCard--large(is-solution);
}

.teaserCard {
    @include teaserCard--large(is-case-study);
}
