@import "scss/_styles";

.wallpaperSlide:not(.is-centered) {

    .keyFactList.is-side-by-side {
        justify-content: flex-start;
    }

}

.wallpaperSlide {

    .keyFactList {
        @extend %keyFactList--side-by-side;
    }

}
