$leadCard_theme: impulse-color;
$leadCard_background-color: get-theme-color($leadCard_theme, primary);

.leadCard {
    @include stack-spacing(section);
    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    @include full-width-backdrop($leadCard_background-color);

    > .wrapper {
        display: flex;
        flex-direction: column;
    }

    &-header {
        order: -1;
    }

    &-title {
        @include stack-spacing(component, margin-bottom);
        text-align: center;
    }

    &-image {
        display: none;
    }

    &-summary {
        order: -1;

        .highlightList {

            &-item {

                &::before {
                    background-color: get-theme-color($leadCard_theme, text-color);
                }

            }

        }

    }

    &-content {

        & > *:first-child {
            margin-top: 0;
        }

    }

    &-content + &-highlights {
        @include stack-spacing(component);
    }

    .authorCard {

        & > .wrapper {
            background-color: $page-color;
        }

    }

    &-buttonset {
        $gutter: $formButtonset_gutter;

        margin-top: (-1 * $stack-spacing);
        margin-top: calc(-1 * var(--sp));

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        margin-left: (-.5 * $gutter);
        margin-right: (-.5 * $gutter);

        &-item {
            padding-left: (.5 * $gutter);
            padding-right: (.5 * $gutter);
        }

        .button {
            display: block;
            width: 100%;

            white-space: nowrap;
        }

    }

    .figure-caption {
        display: none;
    }

    @include only-on-desktop(){

        .authorCard {

            & > .wrapper {
               width: get-column-width(math.div(8, 12));
               margin-left: auto;
               margin-right: auto;
            }

        }

    }

    @include not-on-desktop(){

        .authorCard {

            & > .wrapper {

                &::before {
                    background-color: transparent;
                }

            }

        }

    }

    @include only-on-mobile(){

        &-buttonset {
            $gutter: $formButtonset_gutter__mobile;

            margin-left: (-.5 * $gutter);
            margin-right: (-.5 * $gutter);

            &-item {
                flex: 1 0 50%;

                padding-left: (.5 * $gutter);
                padding-right: (.5 * $gutter);
            }

        }

    }

}

//**********************//
//***** With Image *****//
//**********************//

.leadCard {

    &.with-image, // Legacy: Please use modifier .has-image
    &.has-image {
        padding-top: 0;
        padding-bottom: 0;

        &::before {
            display: none;
        }

        > .wrapper {
            display: block;
        }

        .authorCard {
            margin-top: 0;

            & > .wrapper {
                width: 100%;
                margin-top: 0;
                background-color: $backdrop-color;
            }

        }

    }

    &.with-image &, // Legacy: Please use modifier .has-image
    &.has-image & {

        &-image {
            display: block;
        }

        &-summary {
            @include stack-spacing(component, padding-top);
            @include stack-spacing(component, padding-bottom);
        }

        &-buttonset {
            margin-top: ($stack-spacing--component - $stack-spacing);
            margin-top: calc(var(--sp-component) - var(--sp));
        }

    }


    @include only-on-desktop(){

        &.with-image, // Legacy: Please use modifier .has-image
        &.has-image {

            > .wrapper {
                display: -ms-grid;
                display: grid;

                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto 1fr 1fr;
                grid-gap: 0;

                -ms-grid-columns: 1fr 1fr;
                -ms-grid-rows: auto 1fr 1fr;
            }

            .authorCard {

                &,
                & > .wrapper {
                    height: 100%;
                }

            }

        }

        &.with-image &, // Legacy: Please use modifier .has-image
        &.has-image & {

            &-header {
                grid-column: 1 / span 2;
                grid-row: 1;

                -ms-grid-column: 1;
                -ms-grid-column-span: 2;
                -ms-grid-row: 1;
            }

            &-image {
                -ms-grid-column: 1;
                -ms-grid-row: 2;

                .figure {
                    margin-top: 0;

                    &,
                    & * {
                        height: 100%;
                        width: 100%;
                    }

                    &-image {
                        object-fit: cover;
                    }

                }

            }

            &-author {
                -ms-grid-column: 1;
                -ms-grid-row: 3;
            }

            &-summary {
                $leadCard_theme: impulse-color;

                grid-column: 2;
                grid-row: 2 / span 2;

                -ms-grid-column: 2;
                -ms-grid-row: 2;
                -ms-grid-row-span: 2;

                background-color: $leadCard_background-color;

                padding-left: calc(1/6 * (100% + #{.5 * $grid_gutter}) + #{.5 * $grid_gutter});
                padding-right: calc(1/6 * (100% + #{.5 * $grid_gutter}));

                display: flex;
                flex-direction: column;
                justify-content: center;

                .highlightList {

                    &-title {

                        &::before {
                            width: get-column-width(.25); height: .66em;
                        }

                    }

                }

            }

            &-buttonset {
                display: block;

                .button {
                    display: inline-block;
                    width: auto;
                }

            }

        }

        &.has-image.is-fullwidth & {

            &-image {
                position: relative;
                z-index: -1;

                figure {
                    width: 50vw;
                    max-width: (.5 * $page_max-width);
                    height: 100%;

                    position: absolute;
                    right: 0;
                }

            }

            &-author,
            &-summary {
                position: relative;

                &::before {
                    content: "";

                    display: block;
                    width: 50vw;
                    height: 100%;

                    position: absolute;
                    z-index: -1;
                }

            }

            &-author {

                &::before {
                    right: 0;
                    background-color: $backdrop-color;
                }

                .authorCard > .wrapper {
                    @include stack-spacing(section, padding-bottom);

                    padding-left: 0;
                    padding-right: calc(1/6 * (100% + #{.5 * $grid_gutter}) + #{.5 * $grid_gutter});

                    background-color: transparent;
                }

            }

            &-summary {
                @include stack-spacing(section, (padding-top, padding-bottom));
                padding-right: 0;

                &::before {
                    background-color: $ci_impulse-color;
                }

            }

        }

    }

    @include not-on-desktop(){

        &.with-image, // Legacy: Please use modifier .has-image
        &.has-image {

            .authorCard {

                & > .wrapper {
                    background-color: transparent;

                    &::before {
                        background-color: $backdrop-color;
                    }

                }

            }

        }

        &.with-image &, // Legacy: Please use modifier .has-image
        &.has-image & {

            &-image {
                margin-left: (-1 * $page_padding__mobile);
                margin-right: (-1 * $page_padding__mobile);

                .figure {
                    margin-top: 0;
                }

            }

            &-summary {
                @include full-width-backdrop($leadCard_background-color);
            }

        }

    }

    @include only-on-mobile(){

        &.with-image &, // Legacy: Please use modifier .has-image
        &.has-image & {

        	&-buttonset {

            	.button {
                	display: block;
            	}

        	}

        }

    }

}

//***** Variants *****//

@import "variants/_with-form";
