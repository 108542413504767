$mainNav_item_style: (
    font-size: rem(20px),
    line-height: math.div(28px, 20px),
    font-weight: $base_font-weight__medium,
    text-transform: none,
    letter-spacing: .05em,
) !default;

$mainNav_border-width: $base_border-width !default;

$mainNav_svgs: (
    open: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" xmlns="http://www.w3.org/2000/svg"><path d="M21.5 5H2.5M21.5 12H2.5M21.5 19H2.5"/></svg>',
    close: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" xmlns="http://www.w3.org/2000/svg"><path d="M20 3L3 20M20 20L3 3"/></svg>',
    sublevel: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="2.5" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M6 10L12 15L18 10"/></svg>',
    sublevel__mobile: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="2.5" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 18.5L15.5 12.5L10.5 6.5"/></svg>',
);

$mainNav_col_count: 5;

// @import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

@mixin nav-item-marker(
    $border-colors: define-color-states(
        transparent,
        $link_text-decoration-color,
        $link_text-decoration-color
    ),
){
    position: relative;

    &::before {
        content: "";

        display: block;
        width: auto;

        position: absolute;
        bottom: (-1 * $mainNav_border-width);
        left: (.25 * $mainNav_gutter); right: (.25 * $mainNav_gutter);

        border-bottom: $mainNav_border-width solid color-state($border-colors);

        transition: inherit;
        transition-property: border-color;
    }

    &:hover::before {
        border-color: color-state($border-colors, hover);
    }

    &:active::before {
        border-color: color-state($border-colors, active);
    }

        // Fix for bug in IE11, where menu items reacting on pointer events
        //   although the menu list is not visible.

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: static;

        &::before {
            display: none;
        }

    }

}

.mainNav {
    @include stack-spacing();

    &-link {

        &-supplement {
            font-weight: $base_font-weight;
            font-size: $base_font-size__small;

            &::before {
                content: " • ";
            }

        }

    }

}

@include only-on-desktop(){

    .mainNav {
        $level-2_padding: (.75 * $grid_gutter);

        &-list {
            justify-content: center;
        }

        &-item {
            position: relative;
            white-space: nowrap;
        }

        &-item.is-level-1.has-sublevel > &-link {

            &::after {
                content: "";
                $icon_data: get-icon-data-url(sublevel, $svgs: $mainNav_svgs);
                background: url($icon_data) center center no-repeat;

                display: inline-block;
                width: 1em; height: 1em;

                vertical-align: middle;
                margin-top: -.1em;

                margin-left: .25em;
            }

        }

        &-link {
            color: $ui-color !important;
            text-decoration: none !important;

            cursor: pointer;
            transition: $base_transition-duration__out;

            &:hover {
                transition: $base_transition-duration__in;
            }

            @include nav-item-marker();
        }

        &-link.has-icon {

            &::after { // Marker
                display: none !important;
            }

        }

        &-item.is-current > &-link {

            @include nav-item-marker(
                $border-colors: define-color-states(
                    $text-color,
                    $link_text-decoration-color,
                    $link_text-decoration-color
                )
            );

        }

            //***** Level 2 *****//

        &-list.is-level-2 {
            display: block;
            position: absolute;

            padding: $level-2_padding ($level-2_padding - .25 * $mainNav_gutter);
            margin: 0;

            border-top: $mainNav_border-width solid $link_text-decoration-color;

            opacity: 0;
            pointer-events: none;
            z-index: -1;

            transition: all $base_transition-duration__out, z-index 0s $base_transition-duration__out;

            &::before { // Backdrop
                content: "";

                display: block;
                width: 100%; max-width: $page_max-width;
                height: 0;

                position: absolute;
                top: 0; left: 0;

                background: $ui_background-color;
                transition: inherit;
            }

        }

        &-item[data-toggle-on-click] {

            > .wrapper {
                pointer-events: none;
            }

        }

        &-item:not([data-toggle-on-click]):hover &-list.is-level-2,
        &-item[data-toggle-on-click-visible="true"] &-list.is-level-2 {

            opacity: 1;
            pointer-events: all;
            z-index: 1;
            transition: all $base_transition-duration__in, z-index 0s;

            &::before { // Backdrop
                height: 100%;
            }

        }

        &-item.is-level-2 {
            padding: 0;

            &:first-child {
                margin-top: (-.2 * $level-2_padding);
            }

            &:not(:first-child) {
                margin-top: (1.2 * $level-2_padding);
            }

            &:last-child {
                margin-bottom: (-.2 * $level-2_padding);
            }

        }

        &-link:not(.is-level-1) {
            color: $white !important;
            display: inline-block;
        }

        &-item.is-level-2 > &-link,
        &-item.is-level-2 > * > &-link {
            $gap: 8px;

            $underline_width: 2px;
            $underline_color: $ui-color;

            padding-left: 0; padding-right: 0;
            margin-left: 0 !important; margin-right: 0 !important;

            text-decoration: none;
            display: inline;
            line-height: 1.5;

            background-image: linear-gradient($link_text-decoration-color, $link_text-decoration-color), linear-gradient(transparent, transparent);
            background-size: 0 $underline_width, 100% $underline_width;
            background-position: 100% 100%, 0 100%;
            background-position: right 0 bottom $gap, left 0 bottom $gap;
            background-repeat: no-repeat;
            padding-bottom: ($gap + 2 * $underline_width); // Fill gap

            transition: background-size $base_transition-duration__out,
                        background-position 0s;

            &[href]:hover {
                background-size: 100% $underline_width;
                background-position: 0 100%;
                background-position: left 0 bottom $gap, left 0 bottom $gap;
                transition: background-size $base_transition-duration__in,
                            background-position 0s;
            }

            &::before {
                display: none;
            }

        }

        &-item.is-level-2 > &-link:not([href]),
        &-item.is-level-2 > * > &-link:not([href]) {
            cursor: default;
        }

        &-item.is-level-2.is-current > &-link,
        &-item.is-level-2.is-current > * > &-link {
            background-image: linear-gradient($link_text-decoration-color, $link_text-decoration-color), linear-gradient(white, white);
        }

            //***** Level 3 *****//

        &-item.is-level-1:not(.is-mega-menu) &-list.is-level-3 {
            display: none;
        }

        &-list.is-level-3 {
            display: block;
            margin: 0;

            @include stack-spacing();
        }

        &-item.is-level-3 {
            @include stack-spacing(small);
            padding: 0;

            font-weight: $base_font-weight;

                // Hide overview links on desktop

            &.is-overview {
                display: none;
            }

        }

        &-item.is-level-3 > &-link {

            &:hover {
                color: $link_text-decoration-color !important;
            }

            &::before { // Marker
                display: none;
            }

        }

        &-item.is-level-3.is-current > &-link {
            color: $link_text-decoration-color !important;

            &::before { // Marker
                $size: 20px;
                $icon_data: get-icon-data-url(sublevel__mobile, $color: $link_text-decoration-color, $svgs: $mainNav_svgs);

                content: "";

                position: static;
                border: none;

                background: url($icon_data) center center no-repeat;
                background-size: $size;

                display: inline-block;
                width: $size; height: $size;

                vertical-align: middle;
                margin-top: -.3em;

                margin-left: (-1 * $size);
            }

            &:hover::before {
                border-color: $link_text-decoration-color;
            }

        }

            //***** Local and language *****//

        &-item.is-locale > &-link,
        &-item.is-language > &-link {
            font-size: $base_font-size__small;
        }

        &-item.is-locale &-list.is-level-2,
        &-item.is-language &-list.is-level-2 {
            $is_centered: true;

            @if $is_centered {
                left: 50%;
                transform: translateX(-50%);
            } @else {
                right: 0;
            }

        }

    }

}

@include only-on-small-desktop(){

    .mainNav {

        &-item.is-locale &-list.is-level-2,
        &-item.is-language &-list.is-level-2 {
            left: auto;
            right: 0;
            transform: none;
        }

    }

}

.mainNav {

        // Reduce space between items on small desktops

    @include only-on-small-desktop(){

        $gutter: (.5 * $mainNav_gutter);

        margin-left: $gutter;

        &-list {
            margin: 0 (-.5 * $gutter);
        }

        &-item,
        &-link {
            padding: 0 (.25 * $gutter);
        }

        &-item.is-current > &-link::before,
        &-item.is-current > * > &-link::before,
        &-link::before {
            left: (.25 * $gutter);
            right: (.25 * $gutter);
        }

    }

}

    //***** Mega menu *****//

.mainNav {
    $modifier: "is-mega-menu";
    $gutter: $mainNav_gutter;
    $col_count: $mainNav_col_count;

    @include only-on-desktop(){

        &-list.is-level-1 {
            position: relative;
        }

        &-item.#{$modifier} {
            position: static;

            > .wrapper {
                @include has-limited-width();

                width: 100%;

                position: absolute;
                left: 50%; top: 100%;

                transform: translateX(-50%);
            }

        }

        &-item.#{$modifier} & {

            &-list.is-level-2 {
                padding: 0;
                @include stack-spacing(component, padding-bottom);

                width: 100%;

                border-top: 0;

                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;

                margin-left: (-.5 * $gutter);
                margin-right: (-.5 * $gutter);

                position: relative;

                &::before { // Backdrop
                    width: 100vw;

                    left: 50%;
                    transform: translateX(-50%);

                    margin-left: (.5 * $gutter);
                }

                > * {
                    flex: 0 1 auto;

                    width: math.div(100%, $col_count);

                    padding-left: (.5 * $gutter);
                    padding-right: (.5 * $gutter);
                }

            }

            &-item {
                font-size: $base_font-size__small;
                white-space: normal;

                &.is-level-2 {
                    @include stack-spacing(component);
                }

            }

            &-link {

                &:not(.is-level-1) {
                    margin-left: (-.25 * $gutter);
                    margin-right: (-.25 * $gutter);

                    @include only-on-small-desktop(){
                        margin-left: (-1 * $gutter, 8);
                        margin-right: (-1 * $gutter, 8);
                    }

                }

                &::after {
                    display: none !important;
                }

            }

        }

        &-item.#{$modifier} &-item.is-level-2.is-current > &-link:not(:hover),
        &-item.#{$modifier} &-item.is-level-2.is-current > * > &-link:not(:hover) {

            &::before { // Marker
                border-color: transparent;
            }

        }

        &-item.#{$modifier}.has-#{$col_count + 1}-items & {

            &-list.is-level-2 {
                $ratio: math.div($col_count - 1, $col_count);

                width: ($ratio * 100%);
                margin-left: math.div(50%, $col_count);

                > * {
                    width: math.div(100%, ($col_count - 1));
                }

                &::before { // Backdrop
                    margin-left: 0;
                }

            }

        }

        @for $i from 0 to $col_count {

            &-item.#{$modifier}.has-#{$i}-items &-list.is-level-2 {
                justify-content: center;
            }

        }

    }

}

    //***************************//
    //***** Off Canvas Menu *****//
    //***************************//

.mainNav {

    &-underlay,
    &-trigger {
        display: none;
    }

    &-item {

        &.is-go-back {
            display: none;
        }

    }

}

@include not-on-desktop(){
    $width: 80%;
    $min-width: 280px;
    $max-width: 320px;
    $padding: $box-padding__mobile;

    $trigger_size: 56px;
    $trigger_margin: (1.5 * $page_padding__mobile);
    $trigger_background-color: $ci_impulse-color;

    $z-index: 200;

    $transition-duration: .25s;

    .mainNav {
        position: fixed;
        top: 0; right: 0;

        z-index: $z-index;

        display: block;
        width: $width; max-width: $max-width; min-width: $min-width;
        height: 100vh;

        user-select: none;
        pointer-events: none;

        transform: translateX(100%);
        opacity: 0;
        transition: $transition-duration ease-in;

        background-color: $page-color;

        &::before { // Bleeding for bounce effect
            content: "";

            display: block;
            width: 100%; height: 100%;

            position: absolute;
            top: 0; right: 0;

            z-index: -1;
            transform: translateX(100%);

            background-color: inherit;
        }

        &::after { // Shadow
            $width: 20px;

            content: "";

            display: block;
            width: $width; height: 100%;

            position: absolute;
            top: 0; left: (-1 * $width);

            z-index: -1;

            background: linear-gradient(to left, $black, rgba($black, .3) 50%, rgba($black, 0));
            opacity: .1;
        }

        &.is-visible {
            pointer-events: all;

            transform: translateX(0%);
            opacity: 1;

            transition-timing-function: cubic-bezier(.4, .6, .6, 1.1);
        }

            //** Underlay **//

        &-underlay {
            position: fixed;
            top: -1vh;
            left: -1vw;

            display: block;
            width: 102vw; height: 102vh;

            background-color: rgba($white, .75);
            background-image: linear-gradient(to bottom, $lightgray, $lightgray);

            pointer-events: none;
            visibility: hidden;
            opacity: 0;

            transition: opacity $transition-duration, visibility 0s $transition-duration;
        }

        &.is-visible + &-underlay {
            pointer-events: all;

            visibility: visible;
            opacity: 1;

            transition: opacity $transition-duration;
        }

            //** Trigger **//

        &-trigger {
            $icon_size: 24px;
            $inset: (.5 * $icon_size);

            position: fixed;
            bottom: 0; right: 0;

            z-index: ($z-index + 1);

            margin-bottom: $trigger_margin;
            margin-right: $trigger_margin;

            display: block;
            width: $trigger_size; height: $trigger_size;
            border-radius: 50%;

            overflow: hidden;
            white-space: nowrap;
            text-indent: $trigger_size;

            background-color: $trigger_background-color;
            box-shadow: 0px 0px 7px rgba($accent-color, .4);

            &::before,
            &::after { // Icons
                content: "";

                display: block;
                width: $icon_size; height: $icon_size;

                position: absolute;
                top: 50%; left: 50%;

                transform: translate(-50%, -50%);

                background: center center no-repeat;
                transition: $base_transition-duration__out;
            }

            &::before {
                $icon_data: get-icon-data-url(open, $svgs: $mainNav_svgs);
                background-image: url($icon_data);

                clip-path: inset(0 0 0 0);
                opacity: 1;
            }

            &::after {

                $icon_data: get-icon-data-url(close, $svgs: $mainNav_svgs);
                background-image: url($icon_data);

                opacity: 0;
                clip-path: inset($inset $inset $inset $inset);
            }

            &.is-on {

                &::before {
                    opacity: 0;
                    clip-path: inset($inset $inset $inset $inset);
                }

                &::after {
                    opacity: 1;
                    clip-path: inset(0 0 0 0);
                }

            }

        }

            //** Menu **//

        > .wrapper {
            width: 100%; height: 100%;
            overflow: hidden;

            position: relative;

            &::after {
                content: "";

                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;

                z-index: 10;

                display: block;
                height: $stack-spacing__component__mobile;

                background: linear-gradient(to top, rgba($page-color, 1), rgba($page-color, .95) 20%, rgba($page-color, 0));
            }
        }

        &-list {
            $padding-bottom: ($stack-spacing__component__mobile + rem($trigger_margin + $trigger_size, $base_font-size__mobile));

            position: absolute;
            top: 0; left: 0;

            height: 100%; width: 100%;

            padding: $padding-bottom (2 * $padding) $padding-bottom $padding;
            margin: 0;

            display: flex;
            flex-direction: column;

            //justify-content: safe center; // FIXME: Safe centering only works in Firefox

                // Label

            &[data-label] {

                &::before {
                    content: attr(data-label);

                    display: block;
                    margin-bottom: $stack-spacing__mobile;

                    @include styles($base_uppercase_styles);
                    font-size: $base_font-size__small;
                }

            }

                // Animation

            opacity: 0;
            visibility: hidden;
            transform: translateX(100%);
            z-index: 3;

            transition: transform $transition-duration, opacity $transition-duration, visibility 0s $transition-duration;

            &.is-level-1 {
                transform: translateX(0);
            }

            &.is-current-level {
                opacity: 1;
                visibility: visible;
                z-index: 4;

                overflow-x: clip; // FIXME: When browsing back, level disappears suddenly instead of being scrolled to right
                overflow-y: auto;
                overscroll-behavior-y: contain;

                transition-delay: 0s;

                &::-webkit-scrollbar {
                    display: none;
                }

            }

            &.is-current-ancestor {
                opacity: 1;
                visibility: visible;
                transform: translateX(-100%);

                transition-delay: 0s;

                .is-current-ancestor {
                    transform: translateX(0);
                }

            }

            &:not(.is-current-ancestor):not(.is-current-level) {
                overflow-y: hidden;
            }

        }

        &-item {
            @include stack-spacing(small);

            font-size: $base_font-size__large;
            font-weight: $base_font-weight__medium;

            &:last-child {
                margin-bottom: 0;
            }

            &.is-go-back {
                $icon_height: 24px;
                $icon_width: (1.5 * $icon_height);

                font-size: $base_font-size__small;
                order: -1;

                margin-bottom: $stack-spacing__component__mobile;

                display: block;

                &::before { // Icon
                    content: "";

                    display: inline-block;
                    width: $icon_width; height: $icon_height;

                    line-height: 0;
                    vertical-align: middle;

                    position: relative;
                    top: -.25em;

                    margin-right: .5em;

                    $icon_data: get-icon-data-url(go-back);
                    background: url($icon_data) center center no-repeat;
                }

                > span {
                    position: relative;

                    &::after { // Underline
                        content: "";

                        display: block;
                        width: 100%; height: 0;

                        position: absolute;
                        bottom: (-1 * $base_border-width); right: 0;

                        border-bottom: $base_border-width solid $link_text-decoration-color;
                    }

                }

            }

        }

        &-link {
            text-decoration: none;

            display: inline-block;
            padding: (.5 * $stack-spacing__small) 0;

            > span {
                display: inline;
            }

        }

        $underline_width: 2px;
        $underline_color: $ui-color;

        &-item > &-link,
        &-item > * > &-link {
            margin-bottom: (-1 * $underline_width);

            span {
                line-height: 1.6;
                padding-bottom: (2 * $underline_width);
            }

        }

        &-item.is-current > &-link,
        &-item.is-current > * > &-link {

            span {
                text-decoration: none;

                background-image: linear-gradient($underline_color, $underline_color);
                background-size: 100% $underline_width;
                background-position: 0 100%;
                background-repeat: no-repeat;
            }

        }

        &-item.has-sublevel > &-link,
        &-item.has-sublevel > * > &-link {

            &::after { // Marker
                $icon_data: get-icon-data-url(sublevel__mobile, $svgs: $mainNav_svgs);
                $size: 20px;

                content: "";
                background: url($icon_data) center center no-repeat;

                display: inline-block;
                width: $size; height: $size;

                margin-top: 4px;
                margin-left: .6em;

                position: absolute;
            }

        }

        &-item.is-search,
        &-item.is-locale,
        &-item.is-language {
            margin-top: calc(var(--sp) + .2em);
        }

        &-item.is-search + &-item.is-locale,
        &-item.is-search + &-item.is-language,
        &-item.is-language + &-item.is-search,
        &-item.is-locale + &-item.is-search {
            @include stack-spacing(small);
        }

        &-item.is-locale > &-link,
        &-item.is-language > &-link {

            &::after {
                display: none;
            }

        }

        &-item.is-service-item {
            @include stack-spacing();

            font-size: $base_font-size__small;
            font-weight: $base_font-weight;

            & + & {
                margin-top: 0;
            }

        }

            // Reorder special nav items

        @include not-on-desktop(){

            &-item.is-search,
            &-item.is-locale {
                order: 2;
            }

        }

            //** Fix position of trigger on iPhone X in portrait mode **//

        @media (min-device-width: 375px) and (min-device-height: 812px) and (orientation: portrait) {

            $trigger_margin: (3 * $page_padding__mobile);

            &-trigger {
                margin-bottom: $trigger_margin;
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .mainNav {

        &-link.has-supplement {
            display: flex;
            align-items: flex-end;
        }

        &-link.has-supplement .mainNav-link {

            &-supplement {
                line-height: map-get($mainNav_item_style, font-size) * 1.8;

                &::before {
                    margin-right: .5ch;
                }

            }

        }

    }

    @include only-on-desktop(){

        .mainNav-item.is-level-1.has-sublevel > .mainNav-link::after {
            margin-left: auto;
            margin-right: .25em;
        }

        .mainNav-item.is-mega-menu.has-#{$mainNav_col_count + 1}-items .mainNav-list.is-level-2,
        .is-mega-menu.has-#{$mainNav_col_count}-items.intrapageNav-item .mainNav-list.is-level-2 {
            margin-left: auto;
            margin-right: math.div(50%, $mainNav_col_count);
        }

        .mainNav-item.is-mega-menu .mainNav-list.is-level-2::before,
        .is-mega-menu.intrapageNav-item .mainNav-list.is-level-2::before {
            margin-left: (-.5 * $mainNav_gutter);
        }

    }

    @include not-on-desktop(){

        .mainNav-list {
            transform: translateX(-100%);

            &.is-level-1 {
                transform: translateX(0);
            }

            &.is-current-ancestor {
                transform: translateX(100%);

                .is-current-ancestor {
                    transform: translateX(0);
                }

            }

        }

        .mainNav-item.has-sublevel > .mainNav-link::after,
        .mainNav-item.has-sublevel > .intrapageNav-link::after,
        .has-sublevel.intrapageNav-item > .mainNav-link::after,
        .has-sublevel.intrapageNav-item > .intrapageNav-link::after {
            margin-left: auto;
            margin-right: .6em;
            transform: scaleX(-1);
        }

        .mainNav-item.is-go-back::before,
        .is-go-back.intrapageNav-item::before {
            margin-right: auto;
            margin-left: .5em;
            transform: scaleX(-1);
        }

    }

}
