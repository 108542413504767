.is-careerArticle {
    @extend %pageLayout__narrow;

    .highlightListInsert {

        &-title {
            text-align: center;
        }

    }

    @include only-on-desktop(){

        .accordionInsert {
            width: get-column-width(math.div(10, 8));
            margin-left: get-columns-indent(-1, 8);
        }

    }

}
