.videoTeaserGroup {
    $caption_font-size: $base_font-size__small;

    @include stack-spacing(section);
    @include stack-spacing(section, padding-top);

    color: $white;

    @include full-width-backdrop($ci_leading-color);

    &::before { // Backdrop
        $offset: 0;

        height: auto;
        bottom: 0%;
    }

    &-section:last-child &-item:last-child {
        position: relative;

        &::before { // Backdrop ident at bottom
            $line-height: $caption_font-size * $base_line-height;

            content: "";

            display: block;
            width: 200vw;
            height: 50%;
            height: calc((calc(100% - var(--sp)) + #{$line-height} + var(--sp-small))/2);

            position: absolute;
            bottom: 0; left: 50%;
            z-index: -1;

            transform: translateX(-50%);

            background-color: $page-color;
        }

    }

    &-title {
        text-align: center;
    }

    &-section {
        @include stack-spacing(component);

        &-title {
            @extend .is-uppercase;
            font-size: $caption_font-size;
        }

    }

    &-item {
        @include stack-spacing();

        &-caption {
            @include stack-spacing(small);
            font-size: $caption_font-size;
        }

    }

    .videoPlayer {
        margin-top: 0;
    }

    @include only-on-desktop(){
        $column-count: 3;
        $gutter: $grid_gutter;

        &-section {
            display: flex;
            flex-wrap: wrap;

            margin-left: (-.5 * $gutter);
            margin-right: (-.5 * $gutter);

            &-title {
                flex: 1 0 auto;
                width: 100%;

                padding-left: (.5 * $gutter);
                padding-right: (.5 * $gutter);
            }

        }

        &-item {
            flex: 0 1 auto;
            width: (math.div(1, $column-count) * 100%);

            padding-left: (.5 * $gutter);
            padding-right: (.5 * $gutter);
        }

        &-section:last-child &-item {

            @include only-last-row($column-count){

                .videoTeaserGroup-item-caption {
                    color: $text-color;
                }

            };

        }

    }

    @include not-on-desktop(){

        &-item {
            @include stack-spacing(component);
        }

        &-section-title + &-item {
            @include stack-spacing();
        }

        &-section:last-child &-item:last-child {
            color: $text-color;
        }

    }


}
