$teaserBadge_ending: '<svg width="32" height="144" viewBox="0 0 32 144" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M0 72L32 0H0V72Z"/><path d="M0 72V144H32L0 72Z"/></svg>';

$teaserBadge_icons: (
    multiple-actions-warning: '<svg width="72" height="72" viewBox="0 0 72 72" fill="none" stroke="{{color}}" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="13" r="11"/><circle cx="48" cy="13" r="11"/><circle cx="55" cy="62.5" r="0.75" fill="{{color}}"/><path d="M52.7731 41.3646C53.7009 39.5462 56.2991 39.5462 57.2269 41.3646L69.5993 65.6138C70.4479 67.2772 69.2397 69.25 67.3724 69.25H42.6277C40.7603 69.25 39.5521 67.2772 40.4008 65.6138L52.7731 41.3646Z"/><path d="M55 56.4167V49.1251"/><path d="M39 31.5751C41.6101 29.9432 44.6949 29 48 29C51.7378 29 55.1938 30.2063 58 32.2508M3 46C3 36.6112 10.6112 29 20 29C29.3888 29 37 36.6112 37 46H3Z"/></svg>',
);

$teaserBadge_icon_size: 72px;
$teaserBadge_indent: 20px;

@keyframes teaserBadge-reveal {
    0% {
        clip-path: inset(0 100% (-1 * $teaserBadge_indent) 0);
    }
    100% {
        clip-path: inset(0 -200px (-1 * $teaserBadge_indent) 0);
    }
}

@mixin teaserBadge-ending(
    $background-color: $notification-color,
){
    content: "";

    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;

    display: block;
    width: 200px; // Only the width of the container, the "visible" width depends on the height

    background: svg-url($teaserBadge_ending, $background-color) center left no-repeat;
    background-size: 1000%;

    pointer-events: none;
}

.teaserBadge {
    $background-color: $notification-color;
    $background-color__darken: #6b5a2a;

    $animation_duration: 500ms;

    display: inline-block;
    margin-left: (-1 * $teaserBadge_indent);

    animation: teaserBadge-reveal $animation_duration cubic-bezier(.6,0,.83,.67);
    animation-fill-mode: both;
    animation-delay: inherit;

    position: relative;

    > .wrapper {
        padding: (math.div(2, 3) * $box-padding) $box-padding;

        background-color: $background-color;
        color: $text-color;

        position: relative;
        z-index: 1;

        &::after { // Ending
            @include teaserBadge-ending($background-color);
        }

        &::before { // Backside
            content: "";

            position: absolute;
            left: 0;
            top: 100%;
            z-index: -1;

            display: block;

            border-style: solid;
            border-width: (.5 * $teaserBadge_indent);
            border-color: $background-color__darken $background-color__darken transparent transparent;
        }

    }

    &-content {
        @include font-size(largest);
        @include line-height(largest);
        font-weight: $base_font-weight__medium;
    }

    &-shadow {
        $offset: 4px;
        $background-color: rgba($black, .25);

        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        z-index: -1;

        transform: translate((.5 * $offset), $offset);

        background-color: $background-color;

        filter: blur(8px);
        mix-blend-mode: multiply;

        &::after { // Ending
            @include teaserBadge-ending($background-color);
        }

    }

    &[data-icon] &-content {
        display: flex;
        align-items: center;

        &::before {
            content: "";

            flex-shrink: 0;

            display: block;
            width: $teaserBadge_icon_size;
            height: $teaserBadge_icon_size;

            margin-right: (.5 * $box-padding);

            background: 50% 50% no-repeat;
            background-size: contain;
        }

    }

    @each $name, $icon in $teaserBadge_icons {

        &[data-icon="#{$name}"] &-content::before {
            background-image: svg-url($icon);
        }

    }

    @include not-on-desktop(){
        $icon_size: 40px;
        $teaserBadge_indent: (.5 * $page_padding__mobile);

        $offset: 2px;

        margin-left: (-1 * $teaserBadge_indent);
        max-width: 95%;

        > .wrapper {
            padding: (.25 * $box-padding) (.5 * $box-padding);

            &::before { // Backside
                border-width: (.5 * $teaserBadge_indent);
            }

        }

        &-content {
            @include font-size(large);
            @include line-height(large);

            transform: translateY($offset);
        }

        &[data-icon] &-content {

            &::before {
                width: $icon_size;
                height: $icon_size;

                transform: translateY(-1 * $offset);

            }

        }


    }

}

html[dir="rtl"] {

    .teaserBadge {
        transform: scaleX(-1);
        margin-left: 0;
        margin-right: (-1 * $teaserBadge_indent);

        > .wrapper {

            &::after { // Ending
                background-position: left -1px center; // Fix gap
            }

        }

        &-content {
            transform: scaleX(-1);

            &::before {
                margin-right: 0;
                margin-left: (.5 * $box-padding);
            }

        }

        @include not-on-desktop(){
            margin-left: 0;
            margin-right: (-.5 * $page_padding__mobile);
        }

    }

}
