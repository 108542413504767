.js-pinchzoomer {

    .marker, .zoomHolder img
    {
    	display: block
    }

    .fullscreenToggle, .zoomIn, .zoomOut
    {
    	background: url(../assets/pz_sheet.png) no-repeat;
    	width: 30px;
    	height: 30px;
    	overflow: hidden;
    	position: relative;
    	display: block;
    	cursor: pointer;
    	z-index: 100;
    	margin: 5px 0
    }

    .zoomIn
    {
    	background-position: 0 0
    }

    .zoomOut
    {
    	background-position: -30px 0
    }

    .fullscreenToggle
    {
    	background-position: -60px 0;
    	cursor: pointer
    }

    .zoomIn.on, .zoomOut.on
    {
    	opacity: 1;
    	-moz-opacity: 1;
    	-khtml-opacity: 1;
    	cursor: pointer
    }

    .zoomIn.off, .zoomOut.off
    {
    	opacity: .5;
    	-moz-opacity: .5;
    	-khtml-opacity: .5;
    	cursor: auto
    }

    .controlHolder
    {
    	border-radius: 5px;
    	background: rgba(0,0,0,.4);
    	position: absolute;
    	right: 10px;
    	top: 10px;
    	padding: 5px;
    	z-index: 100
    }

    .fullscreenDiv, .marker
    {
    	left: 0!important;
    	top: 0!important
    }

    .fullscreenToggle.on
    {
    	background-position: -90px 0
    }

    .fullscreenToggle.off
    {
    	background-position: -60px 0
    }

    .fullscreenDiv
    {
    	width: auto!important;
    	height: auto!important;
    	right: 0!important;
    	bottom: 0!important;
    	position: absolute!important;
    	background: #FFF;
    	z-index: 999999;
    	oveflow: hidden
    }

    .zoomHolder
    {
    	width: 100%;
    	height: auto;
    	position: relative;
    	overflow: hidden
    }

    .marker
    {
    	position: absolute!important;
    	bottom: auto!important;
    	right: auto!important
    }

}
