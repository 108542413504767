.mainStage {
    @include full-width-backdrop();

    @include stack-spacing(section, padding-bottom);

    .figure {
        margin-top: 0;
    }

    &-topic {
        @extend .is-uppercase;
        font-size: rem(25px);
        line-height: 1.35;

        font-weight: $base_font-weight__bold;

        text-align: center;
    }

    &-title {
        font-size: rem(64px);
        line-height: 1.25;

        @include stack-spacing(component);
    }

    &-content {
        font-size: $base_font-size__large;
        line-height: 1.35;

        font-weight: $base_font-weight__medium;

        @include stack-spacing();
    }

    &-cta {
        @include stack-spacing();

        .navigationLink {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        min-height: 650px;
        @include stack-spacing(component, padding-top);

        position: relative;

        .figure {

            &,
            & * {
                height: 100%;
                width: 100%;
            }

            &-image {
                object-fit: cover;
            }

        }

        &-title,
        &-content {
            width: get-column-width(math.div(6, 12));
        }

        &-image {
            position: absolute;
            top: 0; left: 50%;

            width: 50vw; max-width: (.5 * $page_max-width);
            height: 100%;

            z-index: -1;
        }

    }

    @include not-on-desktop(){
        text-align: center;

        display: flex;
        flex-direction: column;

        &-topic {
            margin-top: $stack-spacing__component__mobile;
            order: -1;
        }

        &-title {
            @include heading-style(1, $headings_styles__mobile);
            margin-top: $stack-spacing__small__mobile;
            margin-bottom: $stack-spacing__mobile;
            order: -1;
        }

        &-image {
            margin: $stack-spacing__mobile (-1 * $page_padding__mobile) 0;
        }

        &-content {
            margin-top: $stack-spacing__component__mobile;
        }

    }

        //** Theming **//

    @include color-themes((
        color: secondary
    ));

    &::before {

        @include color-themes((
            background-color: primary
        ));

    }

}

    //***** Supersized *****//

.mainStage {

    @include only-on-desktop(){
        $heading_spacing: default;
        $image_offset: .5 * (get-heading-font-size(1) * get-heading-line-height(1));

        &.is-supersized {
            min-height: 750px;

            @include stack-spacing(component, padding-top);
            @include stack-spacing(component, padding-bottom);

            display: flex;
            flex-direction: column;

            & + .spacer {
                width: 100%; height: 0%;

                @include stack-spacing(section, padding-bottom);
            }

        }

        &.is-supersized & {

            &-title {
                @include heading-style(1);
                @include stack-spacing($heading_spacing);

                width: get-column-width(math.div(7, 12));
            }

            &-image {
                @include stack-spacing(component);

                left: auto;
                top: 0; right: 0;

                width: get-column-width(math.div(8, 12)); max-width: none;
                height: 100%;

                @include stack-spacing($heading_spacing, padding-top);

                transform: translateY($image_offset);
            }

            &-cta {
                flex: 1 0 auto;
                margin-top: $stack-spacing__component;

                .button {
                    margin-top: 0;
                }

            }

        }

    }

    @include not-on-desktop(){
        $image_ratio: 1;

        $padding-y: $stack-spacing__component__mobile;
        $padding-x: (2 * $page_padding__mobile);

        $text_indent: $padding-x;

        $title_offset: (-.5 * get-heading-font-size(1, $headings_styles__mobile) * get-heading-line-height(1, $headings_styles__mobile));

        &.is-supersized {
            display: block;

            margin: 0 ($text_indent - $page_padding__mobile) 0 (-1 * $page_padding__mobile);
            padding: 0 $padding-x $padding-y;

            background-color: $backdrop-color__opaque;

            text-align: left;

            &::before {
                display: none;
            }

        }

        &.is-supersized & {

            &-image {
                margin: 0 (-1 * ($padding-x + $text_indent)) 0 (-1 * $padding-x);

                .figure {

                    &::before {
                        padding-bottom: (math.div(1, $image-ratio) * 100%);
                    }

                }

            }

            &-title {
                $padding-top: (.8 * $padding-y);

                margin: ($title_offset - $padding-top) (-1 * $padding-x) 0;

                padding: $padding-top $padding-x 0;

                position: relative;
                z-index: 1;

                background-color: $backdrop-color__opaque;
            }

            &-content {
                margin-top: $stack-spacing__mobile;
            }

        }

        @each $theme in map-keys($color-themes) {

            $color: get-theme-color(
                $theme: $theme,
                $key: primary,
            );

            &.is-supersized.is-#{$theme} {
                background-color: $color;
            }

            &.is-supersized.is-#{$theme} &-title {
                background-color: $color;
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    @include only-on-desktop(){

        .mainStage {

            &-image {
                left: auto;
                right: 50%;
            }

        }

        .mainStage.is-supersized {

            .intrapageLink {
                text-align: right;
            }

        }

        .mainStage.is-supersized .mainStage {

            &-image {
                right: auto;
                left: 0;
            }

        }

    }

}
