.authorCardGroup {
    @include stack-spacing(section);

    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);
    @include full-width-backdrop($backdrop-color__opaque);

    &-title {
        text-align: center;
    }

    @include only-on-desktop(){

        &-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

        }

        &-item {
            flex: 0 0 auto;
            width: (math.div(1, 3) * 100%);

            padding-left: (.5 * $grid_gutter);
            padding-right: (.5 * $grid_gutter);

            &:only-child {
                width: (math.div(8, 12) * 100%);
            }

        }

        &.has-2-items &-item {
            width: (.5 * 100%);
        }

    }

}
