.quoteInsert {

    &-content {
        $icon_data-url: get-svg-data-url($quoteInsert_blockquote_icon, $ci_calming-color);

        color: $ci_calming-color;

        &::before {
            background-image: url($icon_data-url);
        }

    }

}
