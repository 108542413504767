.htmlSlide {
    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    display: flex;
    flex-direction: column;
    min-width: 100%;

    @include color-themes((
        color: text-color,
    ));

    & + & {
        margin-top: 0;
    }

    @include full-width-backdrop();

    &::before {

        @include color-themes((
            background-color: primary,
        ));

    }

    > .wrapper {
        flex: 1 0 auto;
        height: 100%;

        display: flex;
        flex-direction: column;

        justify-content: flex-start;
    }

    &-topic {
        font-size: $base_font-size__small;
        font-weight: $base_font-weight__bold;
        text-transform: uppercase;
        letter-spacing: .025em;
    }

    &-title {
        @include stack-spacing(small);
    }

    &-insert {
        @include stack-spacing(component);

        .htmlInsert {
            margin-top: 0;
        }

    }

    &-content {
        flex: 1 0 auto;
        @include stack-spacing(component);

        & > *:first-child {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        $col-span: 10;

        > .wrapper {
            width: get-column-width(math.div($col-span, 12));
        }

        &-insert {
            width: get-column-width(math.div(12, $col-span));
        }

    }

}

//***** Centered *****//

.htmlSlide {

    @include only-on-desktop(){
        $col-span: 8;

        &.is-centered {
            text-align: center;

            > .wrapper {
                width: get-column-width(math.div($col-span, 12));
                margin: 0 auto;
            }

        }

        &.is-centered & {

            &-insert {
                width: get-column-width(math.div(12, $col-span));
                margin-left: get-columns-indent(-.5 * (12 - $col-span), $col-span);
            }

        }

    }

}
