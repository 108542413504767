@keyframes notificationBanner-fadein {

    0% {
        transform: translateX(-50%) translateY(-100%);
    }

    100% {
        transform: translateX(-50%) translateY(0%);
    }

}

.notificationBanner {
    $transition-duration: $base_transition-duration__out;
    $delay: 500ms;

    position: fixed;
    top: 0; left: 50%;
    transform: translateX(-50%);
    z-index: 1000;

    width: 100%;
    @include has-limited-width();

    font-size: $base_font-size__small;
    pointer-events: none;

    animation: notificationBanner-fadein $base_transition-duration__out $delay ease-out;
    animation-fill-mode: both;

    &-switch {
        position: absolute;
        z-index: -1;
        transform: translateY(-100%);

        opacity: 0;
        pointer-events: none;
    }

    &-message {
        max-height: 100vh;
        overflow: visible;
        pointer-events: all;

        > .wrapper {
            @include box-padding();

            background-color: $backdrop-color__opaque;
            border-left: $base_border-width $text-color solid;

            > *:first-child {
                margin-top: -.2em;
            }

        }

    }

    &-switch:checked + &-message {
        pointer-events: none;

        transform: translateY(-100%);
        max-height: 0;
        overflow: hidden;

        transition: all $transition-duration ease-in-out, max-height 0s $transition-duration;
    }

    &-title {
        display: block;

        font-size: $base_font-size;
        font-weight: $base_font-weight__bold;
    }

    &-content {
        @include stack-spacing(small);

        > * {
            margin-top: 0;
        }

        > * + * {
            @include stack-spacing(small);
        }

    }

    &-button {
        @extend .button
    }

    &.is-success &-message {

        > .wrapper {
            border-left-color: $success-color;
        }

    }

    &.is-error &-message {

        > .wrapper {
            border-left-color: $error-color;
        }

    }

    @include only-on-desktop(){

        &-message {
            width: get-column-width(math.div(8, 12));
            margin: 0 auto;
        }

    }

}
