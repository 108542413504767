$grid_gutter: 32px !default;
$grid_gutter__mobile: 24px !default;

$page_box-shadow: none;

$page_content_max-width: 1440px !default;
$page_max-width: 1680px !default;

$page_padding__mobile: 20px !default;

$scrollbar-width: 15px !default;

$stack-spacing: 1.143rem !default;
$stack-spacing__mobile: $stack-spacing !default;

$stack-spacing__component: (math.div(7, 3) * $stack-spacing) !default;
$stack-spacing__component__mobile: (math.div(7, 3) * $stack-spacing__mobile) !default;
$stack-spacing__section: (2 * $stack-spacing__component) !default;
$stack-spacing__section__mobile: (1.5 * $stack-spacing__component__mobile) !default;

$box-padding: (1.5 * $stack-spacing) !default;
$box-padding__mobile: (1.5 * $stack-spacing__mobile) !default;

$box_padding__small: (.5 * $box_padding) !default;
$box_padding__small__mobile: (.5 * $box_padding__mobile) !default;

$box_paddings: (
    default: (
        default: $box_padding,
        small: $box_padding__small,
    ),
    mobile: (
        default: $box_padding__mobile,
        small: $box_padding__small__mobile,
    ),
) !default;

$box_padding__small: (.5 * $box_padding) !default;
$box_padding__small__mobile: (.5 * $box_padding__mobile) !default;

$box_paddings: (
    default: (
        default: $box_padding,
        small: $box_padding__small,
    ),
    mobile: (
        default: $box_padding__mobile,
        small: $box_padding__small__mobile,
    ),
) !default;

$base_box_border-radius: 0 !default;

$base_border-width: 3px !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

@mixin only-on-small-desktop(){

    @include media-query(
        $min-width: $breakpoint__desktop,
        $max-width: (.5 * $page_content_max-width + .5 * $breakpoint__desktop)
    ){
        @content;
    }

}

@mixin has-full-height(
    $height: 100vh,
){
    min-height: $height;
}

@mixin layout--removeGapAtTop(){

    & + &,
    .slideGroup + &,
    .newsletterPanel + &,
    .teaserCardGroup.is-emphasised + & {
        margin-top: 0;

        &::before { // Remove white border
            border-top: none;
        }

        .topLink {
            top: 0;
        }

    }


}

:root {
    --scrollbar-width: #{$scrollbar-width};
}

body {
    overflow-y: hidden;
}

%pageLayout__narrow {

    .mainSection {

        &.has-full-width {
            width: 100%;
            max-width: 100%;
        }

        &.has-full-width .mainSection-title {
            text-align: center;
        }

    }

    @include only-on-desktop(){
        $max-width: get-column-width(math.div(8, 12));

        .mainSection {
            max-width: $max-width;
            margin-left: auto; margin-right: auto;

            .contentBlock-column {
                max-width: none;
            }

            .imageInsert,
            .infographicInsert {

                &-items {
                    $indent: calc(-2 * (#{$grid_gutter} + (100% + #{$grid_gutter}) * #{math.div(1, 8)} - #{$grid_gutter}) - #{.5 * $grid_gutter});
                    margin-left: $indent;
                    margin-right: $indent;
                }

            }

            .dataInsert {

                &-description {
                    width: (math.div(5, 8) * 100%);
                }

                &-data {
                    width: (math.div(3, 8) * 100%);
                }

            }

            .authorCard {

                & > .wrapper {
                    $padding-x: get-column-indent(8);

                    padding-left: $padding-x;
                    padding-right: $padding-x;
                }

            }

            .highlightListInsert.is-emphasised {

                > .wrapper {
                    width: 100%;
                }

            }

            .formInsert {
                width: 100%;
            }

        }

    }

}

// Remove gap between modules with full-width backdrop

.has-full-width-backdrop {

    & + &,
    & + .highlightListInsert.is-emphasised,
    .highlightListInsert.is-emphasised + &,
    foobar {
        @include stack-spacing(0);
    }

    .imageTeaserGroup + &,
    .videoTeaserGroup + & {
        @include stack-spacing(section);
    }

}
