// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.infographicSlide {

    .js-imageZoom-trigger a::after {
        $icon_data__hover: get-icon-data-url(expand, $color: $ui-color);
        background-color: $ui_background-color__hover;
        background-image: url($icon_data__hover);
        opacity: 0;
    }

    .js-imageZoom-trigger a:hover::after {
        opacity: 1;
    }

}
