.videoPlayer {
    @include stack-spacing(component);

    width: 100%; height: 0; overflow: hidden;
    padding-bottom: 56.25%; //* 16:9 */

    background-color: $black;

    position: relative;

    iframe {
        position: absolute;
        top: 0; left: 0;

        width: 100% !important; height: 100% !important;
    }

}
