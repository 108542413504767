$field_font-size: 1rem !default;
$field_font-size__mobile: $field_font-size !default;

$field_line-height: $base_line-height !default;
$field_line-height__mobile: $field_line-height !default;

$field_padding: nth($button_padding, 1) (2 * nth($button_padding, 1)) !default;

    //** Colors **//

$field_focus-color: $ui-color__active !default;
$field_error-color: $error-color !default;
$field_success-color: $success-color !default;
$field_disabled-color: $disabled-color !default;

    //** Inputs **//

$field_border-color: $border-color !default;
$field_border-width: 1px !default;
$field_border-style: solid !default;
$field_border-radius: 0 !default;

$field_styles: (
    display: block,
    width: 100%,
    padding: $field_padding,
    color: $text-color,
    background-color: transparent,
    border-color: $field_border-color,
    border-width: $field_border-width,
    border-style: $field_border-style,
    border-radius: $field_border-radius,
    line-height: $field_line-height,
) !default;

$field_states: (
    default: $field_styles,
    focus: (
        border-color: $field_focus-color,
    ),
    success: (
        color: $field_success-color,
        border-color: $field_success-color,
    ),
    error: (
        color: $field_error-color,
        border-color: $field_error-color,
    ),
    disabled: (
        background-color: transparent,
        color: $field_disabled-color,
        border-color: $field_disabled-color,
    ),
) !default;

$field_placeholder_color: $minor-color !default;

    // Calculate height of field

$field_height: get-outer-height(
    $font-size: $field_font-size,
    $line-height: $field_line-height,
    $padding: $field_padding,
    $border: $field_border-width,
    $base: $font-size,
) !default;

$field_height__mobile: get-outer-height(
    $font-size: $field_font-size__mobile,
    $line-height: $field_line-height__mobile,
    $padding: $field_padding,
    $border: $field_border-width,
    $base: $font-size__mobile,
) !default;
