// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.videoInsert {

    .videoPlayer {
        margin-top: 0;
    }

}
