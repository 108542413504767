$sal-animation-duration: .6s !default;
$sal-animation-delay: .0s !default;
$sal-slide-offset: 10vh !default;

@import "scss/_helpers";
@import "scss/_settings";

@import "onscrollAnimations/_sal";

// Add fallback to transitions for browsers that do not support CSS variables

[data-sal] {
    transition-duration: $sal-animation-duration;
    transition-duration: var(--sal-duration, $sal-animation-duration);
    transition-delay: $sal-animation-delay;
    transition-delay: var(--sal-delay, $sal-animation-delay);
    transition-timing-function: map-get($sal-easings, 'ease');
    transition-timing-function: var(--sal-easing, map-get($sal-easings, 'ease'));
}
