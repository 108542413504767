$revealableContent_summary_styles: (
    default: (
        font-weight: $font-weight__medium,
        background-color: $palegray__opaque,
        border-bottom: $lightgray__opaque $base_border-width solid,
        transition: $base_transition-duration__in,
    ),
    open: (
        border-bottom-color: $ci_impulse-color,
    ),
) !default;

$revealableContent_marker_icons: (
    vertical: '<svg width="24" height="24" viewBox="0 0 24 24" stroke="{{color}}" stroke-width="2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0V24"/></svg>',
    horizontal: '<svg width="24" height="24" viewBox="0 0 24 24" stroke="{{color}}" stroke-width="2" fill="" xmlns="http://www.w3.org/2000/svg"><path d="M0 12L24 12"/></svg>',
) !default;

$revealableContent_marker_vertical: get-svg-data-url(map-get($revealableContent_marker_icons, vertical), $text-color);
$revealableContent_marker_horizontal: get-svg-data-url(map-get($revealableContent_marker_icons, horizontal), $text-color);

$revealableContent_marker_styles: (
    default: (
        width: $icon_size,
        height: $icon_size,
        background: 50% 50% no-repeat,
        background-image: (url($revealableContent_marker_horizontal), url($revealableContent_marker_vertical)),
        background-size: (100%, 100%),
        transition: $base_transition-duration__out,
    ),
    open: (
        background-size: (100%, 0%),
    ),
) !default;

@import "_revealableContent.settings";
@import "_revealableContent.styles";

.revealableContent {
    $padding: .6em .75em .4em;

    border-bottom: none;

    &-summary {
        padding: $padding;
    }

    &-content {

        > .wrapper {
            padding: $padding;
        }

    }

}
