$teaserCard_title_font-size__mobile: rem(28px, $base_font-size__mobile);
$teaserCard_title_line-height__mobile: 1.25;

.teaserCard {
    $metadata_gutter: 1.5ch;

    @include stack-spacing(section);

    position: relative;

    .figure {
        margin-top: 0;
        position: relative;

        &::before {
            padding-bottom: (math.div(9, 16) * 100%);
        }

        a {
            display: block;

            text-decoration: none !important;
        }

        img {
            aspect-ratio: math.div(3, 2);
            object-fit: cover;
        }

        &-caption {
            position: absolute;
            width: 100%;

            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
        }

    }

    &-header {
        position: relative;
        @include stack-spacing(default, padding-top);
    }

    &-topic,
    &-date {
        @extend .is-uppercase;
        font-size: $base_font-size__smallest;
        font-weight: $base_font-weight__bold;

        padding: .35em 1.5em;

        display: inline-block;
        vertical-align: top;

        position: absolute;
        top: 0;

        transform: translateY(-50%);

        background-color: $ci_impulse-color;
    }

    &-title {
        @include stack-spacing(small);
        clear: both;

        a {
            color: inherit;
            text-decoration: none;
        }

    }

    &-metadata {
        @include stack-spacing(small);

        &-list {
            margin-top: 0;

            font-size: $base_font-size__small;
        }

        &-key {
            position: absolute;

            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
        }

        &-value {
            margin: 0;

            display: inline;
            padding-right: (.5 * $metadata_gutter);

            &:not(:nth-child(2)) {
                padding-left: (.5 * $metadata_gutter);

                position: relative;

                &::before { // Compensate space between inline elements
                    content: "\00a0"; // Non-breaking space
                }

                &::after { // Line between values
                    $height: 1em * $base_line-height;

                    content: "";

                    padding-left: (.5 * $metadata_gutter);

                    display: block;
                    width: 0; height: $height;

                    position: absolute;
                    left: 0; top: (.6 * 1em * (1 - $base_line-height));

                    border-left: 1px solid $gray;
                }

            }

        }

    }

    &:not(.is-side-by-side) &-metadata {
        display: none;
    }

    &-content {
        @include stack-spacing(small);
    }

    &-cta {
        @include stack-spacing(small);

        &:not(.button){
            font-weight: $base_font-weight__medium;
        }

        .navigationLink {
            margin-top: 0;
        }

        a {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        max-width: get-column-width(math.div(4, 12));

        .figure {

            img {
                aspect-ratio: math.div(16, 9);
            }

        }

    }

}

@mixin reset-topic(){
    padding: 0;
    display: block;
    transform: none;
    background-color: transparent;

    position: static;
    top: auto;

    font-size: $base_font-size__small;
}


    //********************//
    //***** Variants *****//
    //********************//

@import "variants/_emphasised";
@import "variants/_featured";
@import "variants/_large";
@import "variants/_side-by-side";
@import "variants/_wallpaper";
