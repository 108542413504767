@function get-icon-data-url(
    $key: default,
    $color: $text-color,
    $svgs: $icon_svgs,
){
        /*
         * Get data URL of selected icon
         *
         * $key: Name of selected icon
         * $color: Color of icon
         *   Color will be set by replacing `{{color}}` in the icon data variable
         * $svgs: Map with icons as SVGs
         */

    $data-url: "";

    @if map-has-key($svgs, $key) == false {
        @return $data-url;
    }

    $svg: map-get($svgs, $key);

    $data-url: get-svg-data-url($svg, $color);

    @return $data-url;

}

@mixin icon--set-stroke-width(
    $stroke-width: 1,
) {

    * {
            // Improve rendering on low resolution displays

        stroke-width: ($stroke-width + .1) !important;

        @media (min-resolution: 96dpi + 1) {
            stroke-width: $stroke-width !important;
        }

    }

}
