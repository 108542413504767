.wallpaperSlide {
    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    display: flex;
    flex-direction: column;
    min-width: 100%;

    text-align: center;

    position: relative;

    @include color-themes((
        color: text-color,
    ));

    .figure,
    .video {
        margin-top: 0;
    }

    > .wrapper {
        display: flex;
        flex-direction: column;
    }

    &-topic {
        font-size: $base_font-size__small;
        font-weight: $base_font-weight__bold;
        text-transform: uppercase;
        letter-spacing: .025em;
    }

    &-title {
        @include stack-spacing(small);
    }

    &-content {
        flex: 1 0 auto;
        @include stack-spacing(component);

        & > *:first-child {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        $ratio: math.div(20, 9);

        padding-top: 0;
        padding-bottom: 0;

        text-align: left;

        > .container {
            width: 100vw; max-width: $page_max-width;
            min-height: (math.div(1, $ratio)) * 100vw; max-height: (math.div(1, $ratio) * $page_max-width);

            position: relative;
            left: 50%;
            transform: translateX(-50%);

            display: flex;
            flex-direction: column;

            @media (min-width: $page_max-width) {
                min-height: (math.div(1, $ratio) * $page_max-width);
            }

            > .wrapper {
                flex: 1 0 auto;

                height: 100%; width: 100%;
                @include has-limited-width();

                @include stack-spacing(section, padding-top);
                @include stack-spacing(component, padding-bottom);

                margin: 0 auto;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                position: relative;

                    // White gradient

                $inset: (2 * $stack-spacing__section);
                $color: $white;

                @include full-width-backdrop(linear-gradient(to bottom, rgba($color, .5), rgba($color, .25), rgba($color, 0)));

                &::before {
                    box-sizing: content-box;
                    @include stack-spacing(section, padding-bottom);

                    height: 60%;

                    padding: $inset 0 0 0;
                    top: 0;
                }

            }

        }

        &-header,
        &-keyfacts,
        &-content {
            color: $text-color;
            padding-right: get-column-width(math.div(4, 12));
        }

        &-header {
            flex: 1 0 auto;
        }

        &-content {
            margin-top: 0;
            width: 100%;

            @include stack-spacing(section, padding-bottom);

            &:not(:empty) {
                flex: 0 0 auto;
                order: 99;

                @include stack-spacing(component, padding-top);

                @include full-width-backdrop($page-color);
            }

        }

        @include color-themes(
            (
                color: text-color,
            ),
            $child-selector: ".wallpaperSlide-content",
        );

        @include color-themes(
            (
                background-color: primary,
            ),
            $child-selector: ".wallpaperSlide-content::before",
        );

        &-keyfacts {
            color: $white;

            position: relative;
            z-index: 1;

                // Dark gradient

            &:not(:empty) {
                $inset: (2 * $stack-spacing__section);
                $color: black;

                @include full-width-backdrop(linear-gradient(to top, rgba($color, .5), rgba($color, .25) 33%, rgba($color, .1) 66%, rgba($color, 0)));

                &::before {
                    box-sizing: content-box;
                    @include stack-spacing(section, padding-bottom);

                    height: 200%;

                    padding: 0;
                    bottom: (-1 * $stack-spacing__component); top: auto;

                    mix-blend-mode: multiply;
                }

            }

        }

        &-header,
        &-content {
            position: relative;
            z-index: 2;
        }

        &-image,
        &-video {
            position: absolute;
            top: 0; left: 0;
            z-index: -2;

            width: 100%; height: 100%;
        }

        &-image {

            .figure {
                margin-top: 0;

                &,
                & * {
                    height: 100%;
                    width: 100%;
                }

                &-image {
                    object-fit: cover;
                }

            }

        }

        &-video {
            overflow: hidden;

            .video {
                position: absolute;
                top: 0; left: 50%;
                transform: translateX(-50%);

                height: auto; width: 100%;
                max-width: none;

                @supports (object-fit: cover) {
                    height: 100.25%; // QUICKFIX: +.5% to prevent white border below video
                    object-fit: cover;
                }

            }

        }

    }

    @include not-on-desktop(){
        @include full-width-backdrop();

        &::before {

            @include color-themes((
                background-color: primary,
            ));

        }

        &-image,
        &-video {
            margin-left: (-1 * $page_padding__mobile);
            margin-right: (-1 * $page_padding__mobile);

            margin-top: (-1 * $stack-spacing__section__mobile);
        }

        &-image + .wrapper,
        &-video + .wrapper {
            @include stack-spacing(component);
        }

    }

}

    //***** Inverted *****//

.wallpaperSlide {

    @include only-on-desktop(){

        &.is-inverted {

            > .container {

                > .wrapper {

                    &::before { // White gradient
                        display: none;
                    }

                }

            }

        }

        &.is-inverted & {

            &-header {
                color: $white;
            }

        }

    }

}


    //***** Centered *****//

.wallpaperSlide {

    @include only-on-desktop(){

        &.is-centered {
            text-align: center;

            > .container > .wrapper {
                width: get-column-width(math.div(8, 12));
                margin: 0 auto;
            }

        }

        &.is-centered & {

            &-header,
            &-keyfacts,
            &-content {
                padding-right: 0;
            }

            &-content {
                width: get-column-width(math.div(8, 12));
                margin: 0 auto;
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    @include only-on-desktop(){

        .wallpaperSlide {
            text-align: right;

            > .container {
                left: auto;
                right: 50%;
                transform: translateX(50%);
            }

            &-header,
            &-keyfacts,
            &-content {
                padding-right: 0;
                padding-left: get-column-width(math.div(4, 12));
            }

        }

        .wallpaperSlide.is-centered {
            text-align: center;
        }

        .wallpaperSlide.is-centered .wallpaperSlide {

            &-header,
            &-keyfacts,
            &-content {
                padding-left: 0;
            }

            &-content {
                margin: 0 auto;
            }

        }

    }

}
