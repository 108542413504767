%formFieldset {
    padding: 0;
    @include stack-spacing(section);

    border: none;

    & + & {
        @include stack-spacing(component);
    }

    &-legend {
        display: block;
        padding: 0;

        @include stack-spacing();
        @include styles($formFieldset_legend_styles);
    }

    &-legend + &-description {
        @include stack-spacing();
    }

    &-content {

        &:not(:first-child){
            @include stack-spacing();
        }

        .formField:first-child {
            margin-top: 0;
        }

    }

    &-buttons {
        margin-top: math.div(4 * $stack-spacing, 3);
    }

    &-remarks {
        @include stack-spacing();
        @include styles($formFieldset_remarks_styles);
    }

}

.formFieldset {
    @extend %formFieldset;

    &-legend {
        @extend %formFieldset-legend;
    }

    &-description {
        @extend %formFieldset-description;
    }

    &-content {
        @extend %formFieldset-content;
    }

    &-buttons {
        @extend %formFieldset-buttons;
    }

    &-remarks {
        @extend %formFieldset-remarks;
    }

}
