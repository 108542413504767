// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.authorCard {

    .contactDetails-channels {
        justify-content: center;
    }

}
