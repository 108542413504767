*,
*::after,
*::before {
    box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
}

input {
    // For iOS set border-radius to 0 by default
    border-radius: 0;
}
