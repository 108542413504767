//***** Color States *****/

@function define-color-states(
    $default,
    $hover: null,
    $active: null
){
    @if type-of($default) == "map" {
        @return $default;
    }

    $list: ($default,);

    @if $hover != null {
        $list: append($list, $hover);
    } @else {
        $list: append($list, nth($list, 1));
    }

    @if $active != null {
        $list: append($list, $active);
    } @else {
        $list: append($list, nth($list, 2));
    }

    @return (
            default: nth($list, 1),
            hover: nth($list, 2),
            active: nth($list, 3)
    );

}

@function color-state(
    $list,
    $status: default
){

    @if type-of($list) != "list" and type-of($list) != "map" {
        @return $list;
    }

    @if type-of($list) == "list" {

        @if $status == "hover" {
            $status: 2;
        }

        @if $status == "active" {
            $status: 3;
        }

        @if type-of($status) != "number" or $status > length($list) {
            $status: 1;
        }

        @return nth($list, $status) or nth($list, 1);

    }

    @if type-of($list) == "map" {
        @return map-get($list, $status) or map-get($list, default);
    }

}

//** Theming **//

@function get-theme-color(
    $theme: null,
    $key: color,
    $color-themes: $color-themes,
){
    $color: transparent;

    @if $theme == null {
        @return $color;
    }

    $colors: map-get($color-themes, $theme);

    @if (not map-has-key($colors, $key)) {
        @return $color;
    }

    $color: map-get($colors, $key);

    @return $color;
}

@mixin color-theme(
    $theme: null,
    $declarations: (),
    $color-themes: $color-themes,
){
    //* @include color-themes((
    //*     color: color,
    //*     border-bottom-color: color
    //* ));

    @if $theme != null {

        $colors: map-get($color-themes, $theme);

        @each $property, $key in $declarations {

            @if map-has-key($colors, $key) {
                #{$property}: map-get($colors, $key);
            } @else {
                #{$property}: $key;
            }

        }

    }

}

@mixin color-themes(
    $descendant: null,
    $declarations: (),
    $color-themes: $color-themes,
    $child-selector: null, // Legacy
){
    //* @include color-themes((
    //*     color: color,
    //*     border-bottom-color: color
    //* ));
    //*
    //* @include color-themes(
    //*     .childClass,
    //*     (
    //*         color: color,
    //*         border-bottom-color: color
    //*     )
    //* );

    @if (type-of($descendant) == 'map') {

        $temp: $declarations;
        $declarations: $descendant;
        $descendant: $temp;

        @if (type-of($descendant) == 'list') {
            $descendant: null;
        }

    }

    @if ($child-selector) { // Legacy
        $descendant: $child-selector;
    }


        // Detect if element has pseudo class or is a pseudo element

    $selector: "#{&}";
    $suffix: "";

    @if (str-index("#{&}", ':')) {

        $index: str-index("#{&}", ':');

        $selector: str-slice("#{&}", 0, $index - 1);
        $suffix: str-slice("#{&}", $index);

    }

        // Child  selector

    @if ($descendant){
        $descendant: " #{$descendant}";
    }

        // Include all themes

    $keys: map-keys($color-themes);

    @each $key in $keys {

        @at-root #{$selector}.is-#{$key}.is-#{$key}#{$suffix}#{$descendant},
                 .is-#{$key} #{$selector}#{$suffix}#{$descendant} {

            @include color-theme(
                $theme: $key,
                $declarations: $declarations,
            );

        }

    }

}
