@mixin field-style(
    $states: $field_states,
    $min-height: $field_height,
    $min-height__mobile: $field_height__mobile,
){
    @include state-styles($states);

    @include base-transition(out, border-color);

    &::placeholder {
        color: $field_placeholder_color;
    }

    &:focus {
        @include state-styles($states, focus);
        outline: none;

        @include base-transition(in, border-color);

        &::placeholder {
            color: transparent;
        }

    }

        // Set min-height for date fields on iOS

    &[type="date"] {
        min-height: $min-height;

        @include only-on-mobile(){
            min-height: $min-height__mobile;
        }

    }

}
