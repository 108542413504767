$imageZoom_padding: (2 * $box-padding);
$imageZoom_button_size: 42px;
$imageZoom_background-color: $black;

.imageZoom {
    $transition-duration: .3s;

    position: fixed;
    top: 0; left: 0;

    width: 100%; height: 100%;
    margin: 0;

    background-color: $imageZoom_background-color;

    opacity: 0;
    z-index: -1;

    pointer-events: none;

    transition: opacity $transition-duration;

    &.is-visible  {
        opacity: 1;
        z-index: 1000;
        pointer-events: all;
    }

    &-container {
        width: 100%; height: 100%;

        padding: $imageZoom_padding $imageZoom_padding;
        overflow: hidden;

        position: relative;

        & > .wrapper {
            position: relative;

                // Override pinchzoomer styles

            overflow: visible !important;
            width: 100% !important; height: 100% !important;
        }

    }

    &-item {
        width: 100%; height: 100%;
    }

    &-figure {
        width: 100%; height: 100%;
        background-size: contain !important;

        &::before { // Remove pseudo element of fixed ratio figures
            display: none !important;
        }
    }

        // Image is an infographic

    &-figure.is-infographic {
        position: relative;

        &::after { // Passe-partout
            $padding: $box-padding;

            content: "";

            box-sizing: content-box;
            display: block;
            width: 100%; height: 100%;

            position: absolute;
            top: (-1 * $box-padding); left: (-1 * $box-padding);

            z-index: -1;

            padding: $box-padding;
            background-color: $page-color;

            @include color-themes((
                background-color: primary
            ));

        }

    }

    &-figure[style$='.png");']:not(.is-infographic),
    &-figure[style$='.svg");']:not(.is-infographic) {

        &::after { // Passe-partout
            background-color: $page-color;
        }

    }

    &-controls {
        position: absolute;
        bottom: (.25 * $imageZoom_padding); right: (.25 * $imageZoom_padding);
        z-index: 1000;
    }

    &-download {
        position: absolute;
        bottom: (.25 * $imageZoom_padding); left: (.25 * $imageZoom_padding);
        z-index: 999;

        width: 100%;

        text-align: center;
    }

    &-button {
        display: block;
        height: $imageZoom_button_size;
        width: auto; min-width: $imageZoom_button_size;

        cursor: pointer;

        position: relative;

        &.is-close {
            display: block;

            position: absolute;
            top: (.25 * $imageZoom_padding); right: (.25 * $imageZoom_padding);
            z-index: 1000;

            margin: 0;

            background-color: $ui_background-color;
            color: $white;

            @include base-transition();

            &:hover {
                background-color: $ui_background-color__hover;
                color: $text-color;
                @include base-transition(in);
            }

        }

    }

    &-controls &-button,
    &-download &-button {
        background-color: $ui_background-color;
        color: $white;

        @include base-transition();

        &:hover {
            background-color: $ui_background-color__hover;
            color: $text-color;
            @include base-transition(in);
        }

    }

    &-button.is-close,
    &-button.is-zoom-in,
    &-button.is-zoom-out {
        width: $imageZoom_button_size;

        .icon {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);

            display: block;
        }

    }

    &-button.is-download {
        display: inline-block;

        padding: 0 nth($button_padding, 2);
        margin: 0 auto !important;

        font-size: $base_font-size__small;
        line-height: $imageZoom_button_size;

        text-decoration: none;

        user-select: none;

        span {
            padding-right: 1em;
        }

    }

    &-download:not(.is-visible) &-button.is-download {
        display: none;
    }

}

.js-imageZoom-trigger {
    $icon_data: get-icon-data-url(expand, $color: $white);
    $icon_data__hover: get-icon-data-url(expand, $color: $ui-color);

    a {
        position: relative;

        &::after {
            content: "";

            display: block;
            width: $imageZoom_button_size; height: $imageZoom_button_size;

            position: absolute;
            top: auto; left: auto;
            bottom: 0; right: 0;

            background: $ui_background-color url($icon_data) center center no-repeat;
            background-size: $icon_size;

            @include base-transition();
        }

        &:hover {

            &::after {
                background: $ui_background-color__hover url($icon_data__hover) center center no-repeat;
                background-size: $icon_size;

                @include base-transition(in);
            }

        }

        &:not(.is-active)::after{
            display: none;
        }

    }

}

    // Prevent page content to being scrolled

html.imageZoom-is-visible {
    overflow-y: hidden;
}

    //***** Right to left *****//

html[dir="rtl"] {

    .imageZoom {

        &-controls {
            right: auto;
            left: (.25 * $imageZoom_padding);
        }

        &-button.is-close {
            right: auto;
            left: (.25 * $imageZoom_padding);
        }

    }

    .js-imageZoom-trigger {

        a {

            &::after {
                right: auto;
                left: 0;
            }

        }

    }

}
