%formField-file-filename {
    @include state-styles($field_states);

    display: inline-block;
    width: auto;

    outline: none;
    cursor: default;
}

%formField-file-input {
    display: inline-block;

    position: relative;

    input {
        position: absolute;
        top: 0; left: 0;

        display: block;
        width: 100%; height: 100%;

        color: transparent;
        cursor: pointer;

        overflow: hidden;
        opacity: 0;

        &::-webkit-file-upload-button {
            visibility: hidden;
        }

        &:focus {
            outline: none;
        }

    }

    &::before {
        content: attr(data-cta);
        display: inline-block;

        @include button-style();
    }

    &:hover::before {
        @include state-styles($button-states, hover);
    }

    &:active::before {
        @include state-styles($button-states, active);
    }

}

.formField {

    &.is-file {

        .formField {

            &-filename {
                @extend %formField-file-filename;
            }

            &-input {
                @extend %formField-file-input;
            }

        }

    }

}
