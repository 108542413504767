.socialShareBar {
    width: max-content;

    text-align: center;

    position: absolute;

    transform: translateX(-50%);
    margin-left: (-.5 * $page_padding);

    @include only-on-small-desktop(){
        margin-left: (-.25 * $page_padding);
    }

    &-list {
        list-style: none;
        padding: 0;
    }

    &-title {
        font-size: 13px;
        font-weight: $base_font-weight__medium;

        padding: .6em 0 .5em;

        background-color: $ui-background-color;
        color: $white;
    }

    &-list {
        margin: 0;
    }

    .button {
        margin-top: 0;
        top: 0 !important;

        display: block;
    }

        //** Stickieness **//

    &::before { // Spacer
        content: "";

        display: block;
        width: 100%; height: 0;

        @include stack-spacing(section, padding-top);
    }

    &.is-stuck {
        margin-top: 0;
        z-index: 1 !important;
    }

}
