.scriptInsert {
    @include stack-spacing(section);

    position: relative;
    z-index: 1;

    &-title {
        @include stack-spacing(component, margin-bottom);
    }

}
