$serviceNav_height: 30px !default;

@include only-on-desktop(){

    .serviceNav {
        font-size: $base_font-size__smallest;
        user-select: none;

        margin-top: 0;
        transition: (math.div(2, 3) * $base_transition-duration__out);

        @include full-width-backdrop();

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;

            height: $serviceNav_height;

            display: flex;
            justify-content: flex-end;
            align-items: center;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            transform: translateY(.1em);

            > * {
                flex: 0 1 auto;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

        &-link {
            color: $darkgray;
            text-decoration-color: transparent;

            &:hover {
                text-decoration: underline;
            }

        }

        &.is-collapsed {
            margin-top: (-1 * $serviceNav_height);
        }

    }

}

@include not-on-desktop(){

    .serviceNav {
        display: none;
    }

}
