$closeButton_size: 40px !default;
$closeButton_border-width: 0 !default;
$closeButton_background-color: $ui-color !default;

$closeButton_styles: (
    background-color: $closeButton_background-color,
    color: $white,
    border-radius: 50%,
    transition: $base_transition-duration__out,
    transition-property: (background-color, color, border-color),
) !default;

@import "_closeButton.settings";
@import "_closeButton.styles";

%closeButton {

    @include not-on-desktop(){

        @each $name, $size in $closeButton-sizes {

            $size: .67 * $size;

            $modifier: "";

            @if $name != default {
                $modifier: ".is-#{$name}";
            }

            $stroke-width: math.div(2 * $closeButton_size, $size);

            &#{$modifier} {
                width: $size;
                height: $size;
            }

            &#{$modifier} &-icon {
                width: $size;
                height: $size;

                svg {
                    top: (.25 * $size - $closeButton_border-width);
                    left: (.25 * $size - $closeButton_border-width);

                    width: (.5 * $size);
                    height: (.5 * $size);

                    stroke-width: $stroke-width;
                }

            }

        }

    }

}
