%label {
    @include stack-spacing();

    user-select: none;
    cursor: pointer;

    display: block;

    @include state-styles($label_states);

    @include label-supplement();

    &:not([for]){
        cursor: default;
    }

    a {
        position: relative;
        z-index: 1;
    }

}

.label {
    @extend %label;
}
