$field_padding: .35em .75em .25em !default;
$field_line-height: $base_line-height !default;

    //** Inputs **//

$field_border-color: $text-color !default;
$field_border-width: $base_border-width !default;
$field_border-radius: 0 !default;
$field_border: $field_border-width solid $field_border-color !default;

$field_border-style: none none solid !default;

$field_styles: (
    display: block,
    width: 100%,
    padding: $field_padding,
    color: $text-color,
    background-color: $white,
    border-color: $field_border-color,
    border-width: $field_border-width,
    border-style: $field_border-style,
    line-height: $field_line-height,
) !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

// Remove top border from calculated field height

$field_height: ($field_height - $field_border-width);
$field_height__mobile: ($field_height__mobile - $field_border-width);
