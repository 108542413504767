.topicFinder {
    @include stack-spacing(section);

    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    @include full-width-backdrop();

    &-controls {
        @include stack-spacing(component, margin-bottom);

        position: relative;
        z-index: 2;

        .multipleSelect {
            margin-top: 0;
        }

    }

    &-views {
        $gutter: (.5 * $grid_gutter);

        display: flex;
        justify-content: flex-start;

        margin-left: (-.5 * $gutter);
        margin-right: (-.5 * $gutter);

        > * {
            flex: 0 1 auto;

            padding-left: (.5 * $gutter);
            padding-right: (.5 * $gutter);
        }

    }

    &-view {
        display: block;
        position: relative;

        input {
            visibility: hidden;
            position: absolute;
        }

        label {
            display: block;
            margin-top: 0;
        }

        input:checked + label {
            color: $ci_calming-color;
            border-color: $ci_calming-color;
        }

    }

    &-items {
        list-style: none;
        padding: 0;
    }

    &-item {

        &:not(:first-child) {
            @include stack-spacing(component);
        }

    }

    &.is-active &-item:not(.is-selected) {
        display: none;
    }

    &:not(.shows-tiles) &-items {
        border-top: $gray 1px solid;
    }

    &:not(.shows-tiles) &-item {
        border-bottom: $gray 1px solid;

        margin-top: 0;

        padding-top: calc(.5 * var(--sp-component));
        padding-top: (.5 * $stack-spacing__component);

        padding-bottom: calc(.5 * var(--sp-component));
        padding-bottom: (.5 * $stack-spacing__component);

    }

    &-message {
        display: none;

        &:not(:empty) {
            display: block;

            text-align: center;

            padding-top: calc(.5 * var(--sp-component));
            padding-top: (.5 * $stack-spacing__component);

            padding-bottom: calc(.5 * var(--sp-component));
            padding-bottom: (.5 * $stack-spacing__component);

            border-bottom: $gray 1px solid;
        }

    }

    @include only-on-desktop(){

        &-controls {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            > * {
                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

        &-title {
            margin-bottom: 0;
        }

        &.has-filters &-title {
            flex: 1 0 auto;
            width: 100%;
        }

        &-map {
            @include stack-spacing();

            flex: 1 0 auto;
            width: 100%;
        }

        &-filters {
            flex: 1 0 auto;

            display: flex;
            max-width: 100%;
            max-width: calc(100% + #{$grid_gutter});

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            position: relative;
            z-index: 100;

            > * {
                flex: 1;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

        &-filters + &-views,
        &-filters + &-filters:empty + &-views {
            @include stack-spacing(0);
        }

        &-title + &-map,
        &-title + &-filters,
        &-map + &-filters,
        &-title + &-views,
        &-map + &-filters + &-views {
            @include stack-spacing(component);
        }

    }

    @include not-on-desktop(){

        &-title {
            margin-bottom: 0;
        }

        &-filter {

            & + & {
                @include stack-spacing();
            }

        }

        &-title + &-map,
        &-map + &-filters,
        &-title + &-views,
        &-filters + &-views,
        &-title + &-filters + &-views,
        &-map + &-filters + &-views {
            @include stack-spacing();
        }

    }

}

    //***** Tile view *****//

.topicFinder {

    @include not-on-mobile(){

        &.shows-tiles & {

            &-items {
                display: flex;
                flex-wrap: wrap;

                margin: (-1 * $stack-spacing__component) (-.5 * $grid_gutter) 0;

                > * {
                    flex: 0 0 auto;
                    width: (math.div(1, 3) * 100%);

                    padding-left: (.5 * $grid_gutter);
                    padding-right: (.5 * $grid_gutter);
                }

            }

            &-item {
                margin-top: $stack-spacing__component;
            }

        }

    }

    @include only-on-tablet(){

        &.shows-tiles & {

            &-items {

                > * {
                    flex: 0 0 auto;
                    width: (.5 * 100%);
                }

            }

        }

    }

}
