.topicFinderV2 {
    $disabled-color: rgba(155, 163, 168, .7);

    $icons: (
        sort: '<svg width="12" height="24" viewBox="0 0 12 24" fill="none" stroke="#{$disabled-color}" stroke-width="2" stroke-miterlimit="16" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 15.502L6 19L2.5 15.502"/><path d="M2.5 8L6 4.502L9.5 8"/></svg>',
        sortAsc: '<svg width="12" height="24" viewBox="0 0 12 24" fill="none" stroke-width="2" stroke-miterlimit="16" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 15.502L6 19L2.5 15.502" stroke="#{$disabled-color}"/><path d="M2.5 8L6 4.502L9.5 8" stroke="{{color}}"/></svg>',
        sortDesc: '<svg width="12" height="24" viewBox="0 0 12 24" fill="none" stroke-width="2" stroke-miterlimit="16" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 15.502L6 19L2.5 15.502" stroke="{{color}}"/><path d="M2.5 8L6 4.502L9.5 8" stroke="#{$disabled-color}"/></svg>',
        details: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" xmlns="http://www.w3.org/2000/svg"><path d="M17 17.5L22.5 12M22.5 12L17 6.5M22.5 12H1"/></svg>',
    );

    $icon_width: 12px;
    $icon_height: 24px;

    $padding-block: var(--sp-small);
    $border-color: $text-color;

    @include stack-spacing(section);

    ul {
        list-style: none;
        padding: 0;
    }

    &-title {
        @include stack-spacing(component, padding-bottom);

        text-align: center;
    }

    &-controls {
        @include stack-spacing(component, margin-bottom);

        @include stack-spacing(default, (padding-top, padding-bottom));
        padding-bottom: calc(1.75 * var(--sp));

        position: relative;
        z-index: 2;

        @include full-width-backdrop();

        .multipleSelect {
            margin-top: 0;
        }

    }

    &-search {

        .formField {
            margin-top: 0;

            &-input {
                @extend %formField-search-input;
            }

        }

    }

    &-filters {

        @include grid-flowbox(
            $min-width: 340px,
            $stack-spacing: var(--sp, #{$stack-spacing}),
        );

    }

    &-search + &-filters {
        @include stack-spacing();
    }

    &-filter {

        .multipleSelect-message {
            display: none;
        }

    }

    &-items {
        @extend %table;

        @include stack-spacing(0);
        margin-bottom: 0;

        table-layout: auto;

        &-caption {
            @extend %table-caption;
        }

        &-header {
            @extend %table-thead;
        }

        &-body {
            @include stack-spacing(0);
        }

        *:not(&-header) + &-body,
        &-body:first-child {
            border-top: $table_row_border;
        }

    }

    &-label {
        @extend %table-th;

        border-width: $field_border-width !important;

        &.is-link {
            width: $button_height__small;
        }

        &::after {
            display: none;
        }

        &-button {
            padding: 0;
            border: none;

            display: flex;

            background-color: transparent;
            color: inherit;

            line-height: 1.4;

            cursor: pointer;

            text-decoration: underline;
            text-decoration-color: transparent;
            text-underline-offset: 4px;
            text-decoration-thickness: 2px;

            transition: $base_transition-duration__out text-decoration-color;

            &:hover {
                text-decoration-color: $ui-color__hover !important;
                transition-duration: $base_transition-duration__in;
            }

            &::after {
                content: "";

                display: block;
                width: $icon_width;
                height: $icon_height;

                background: svg-url(map-get($icons, sort)) 50% 50% no-repeat;

                margin-left: .5em;
            }

        }

        &.is-current-sorting &-button {
            text-decoration-color: $text-color;

            &[data-sorting-order="-1"]::after {
                background-image: svg-url(map-get($icons, sortAsc));
            }

            &[data-sorting-order="1"]::after {
                background-image: svg-url(map-get($icons, sortDesc));
            }

        }


    }

    &-sorting {
        display: none;

        &-order {

            span {
                @extend %visually-hidden;
            }

        }

    }

    &-item {
        @extend %table-tr;
        background-color: transparent;

        &:not([data-matches-filters="true"]),
        &:not([data-matches-search="true"]) {
            display: none;
        }

        & > * {
            @extend %table-td;
            @include stack-spacing(small, (padding-top, padding-bottom));

            vertical-align: middle;

            &::after {
                display: none;
            }

            &:last-child {
                padding-right: 0;
            }

        }

        &-title {
            @extend %table-th;

            font-size: 1rem;
            line-height: $line-height;

            a {
                text-decoration: none;
            }

        }

        &-link {
            width: $button_height__small;

            a {
                @extend %button;
                @extend %button--secondary;
                @extend %button--icon-only;
                @extend %button--icon-only--small;

                display: block;
                margin-top: 0;

                background: svg-url(map-get($icons, details)) 50% 50% no-repeat;
            }

            span {
                @extend %visually-hidden;
            }

        }

    }

    &[data-debugging="true"] &-item:not([data-matches-filters="true"]),
    &[data-debugging="true"] &-item:not([data-matches-search="true"]) {
        display: table-row;
        opacity: .25;
    }

    &-message {
        @include stack-spacing(default, (padding-top, padding-bottom));

        text-align: center;
        border-top: $border-color 1px solid;
        border-bottom: $border-color 1px solid;

        margin-top: -1px;

        &[aria-hidden="true"] {
            display: none;
        }

    }

    &-backlink {
        @extend %navigationLink--go-back;
        @include stack-spacing(component);

        text-align: center;

        a {
            @extend %navigationLink-link;
        }

    }

    .teaserCard {
        @include stack-spacing(0);
    }

    @include only-on-desktop(){

        &-title {
            flex: 1 0 auto;
            width: 100%;

            margin-bottom: 0;
        }

        &-filters {
            flex: 1 0 auto;
            max-width: 100%;

            position: relative;
            z-index: 100;
        }

        &-title + &-filters {
            @include stack-spacing(component);
        }

        &-filter  {
            flex: 0 1 100%;
            min-width: 0;

            & + & {
                margin-top: 0;
            }

        }

        &-item-title a {

            &:link {
                display: block;

                span {
                    transition: background $base_transition-duration__out;
                }

            }

            &:link:hover {

                span {
                    transition-duration: $base_transition-duration__in;
                    background-color: $ui-color__hover;
                }

            }

        }

    }

    @include not-on-desktop(){

        &-items {
            display: block;

            > * {
                display: block;
            }

        }

        &-sorting {
            font-size: 1rem;

            display: block;

            padding: $box-padding__small__mobile;
            background-color: $backdrop-color;

            > * {
                display: flex;
                align-items: flex-end;
            }

            .formField {
                @include stack-spacing(0);
                flex-grow: 1;
            }

            &-order {
                @include styles(map-get($button-states, default));

                min-height: 0;
                padding: 0;

                width: $field_height__mobile;
                height: $field_height__mobile;

                display: flex;
                justify-content: center;
                align-items: center;

                margin-left: $box_padding__small__mobile;

                &::after {
                    content: "";

                    display: block;
                    width: $icon_width;
                    height: $icon_height;

                    background: svg-url(map-get($icons, sort), $white) 50% 50% no-repeat;
                }

                &[data-sorting-order="-1"]::after {
                    background-image: svg-url(map-get($icons, sortAsc), $white);
                }

                &[data-sorting-order="1"]::after {
                    background-image: svg-url(map-get($icons, sortDesc), $white);
                }

            }

        }

        &-labels {
            display: none;
        }

        &-item {
            display: block;
            position: relative;

            padding: $stack-spacing__mobile $box_padding__small__mobile;
            padding-right: calc(#{$stack-spacing__mobile} + #{$button_height__small__mobile} + #{$box_padding__small__mobile});

            border-bottom: $table_row_border;

            &:first-child {
                border-top: $table_row_border;
            }

            > * {
                display: block;
                padding: 0;
                border: none;
            }

            &-title {
                font-size: 1rem;
            }

            &-tag {
                display: inline-block;

                &.is-current-sorting {
                    text-decoration: underline;
                    text-decoration-line: underline;
                    text-underline-offset: 2px;
                    text-decoration-skip-ink: auto;
                }

                & + & {

                    &::before { // Line between values
                        content: "";

                        margin-left: .5em;
                        padding-left: .5em;

                        display: inline;
                        width: 0;
                        height: 1em * $base_line-height;

                        border-left: 1px solid $gray;
                    }

                }

            }

            &-link {
                position: absolute;
                top: 50%;
                right: $box_padding__small__mobile;
                transform: translateY(-50%);

                width: auto;
            }

        }

    }

}
