.pageHeader {
    $modifier: "is-billboard";
    $image-ratio: 1;
    $image-ratio__desktop: math.div(16, 9);

    $color-theme: leading-color;

    $text-color: get-theme-color(
        $theme: $color-theme,
        $key: text-color,
    );

    $backdrop-color: get-theme-color(
        $theme: $color-theme,
        $key: primary,
    );

    $accent-color: get-theme-color(
        $theme: $color-theme,
        $key: accent-color,
    );

    &.#{$modifier} {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 0;
        @include stack-spacing(section, padding-top);

        text-align: center;
        color: $text-color;

        a {
            color: $text-color;

            &.button {
                border-color: $text-color;
            }

            &:hover {
                color: $accent-color;
                border-color: $accent-color;
            }

        }

        @include full-width-backdrop($ci_leading-color);

        .heroImage {
            position: relative;

            &-figure[style*="background-image"] {
                display: block;

                &::before {
                    padding-bottom: (math.div(1, $image-ratio) * 100%);
                }
            }

        }

        .intrapageLink {
            display: none;
        }

    }

    &.#{$modifier} & {

        &-image,
        &-video,
        &-hgroup,
        &-title,
        &-leadtext,
        &-metadata {
            padding: 0;
            width: 100%;
        }

        &-hgroup {
            margin: 0;
        }

        &-leadtext {
            @include stack-spacing(component);
        }

        &-metadata {
            position: static;
            transform: none;

            @include stack-spacing();

            &-list {
                justify-content: center;
            }

            &-value:not(:last-child) {

                &::after { // Line
                    border-color: rgba($text-color, .3);
                }

            }

        }

        &-image,
        &-video {
            @include stack-spacing(component);
            order: 1;

            position: relative;

            .heroImage,
            .video {
                z-index: 1;
            }

            &::before { // Indent on bottom
                content: "";

                display: block;
                width: 100vw;
                max-width: $page_max-width;
                height: 50%;

                position: absolute;
                bottom: -1px; left: 50%;
                z-index: 1;

                transform: translateX(-50%);

                background-color: $page-color;
            }

        }

        &-topic {
            display: block;
        }

        &-title {
            display: block;
            width: 100%;
        }

        &-video {
            position: relative;

            &::after { // Spacer
                content: "";

                display: block;
                width: 100%; height: 0;
                padding-bottom: (math.div(1, $image-ratio) * 100%);
            }

            .video {
                position: absolute;
                top: 0; left: 50%;
                transform: translateX(-50%);

                height: auto; width: 100%;
                max-width: none;

                @supports (object-fit: cover) {
                    height: 100.25%; // QUICKFIX: +.5% to prevent white border below video
                    object-fit: cover;
                }

            }

        }

    }

    @include only-on-desktop(){

        &.#{$modifier} {

            .heroImage-figure[style*="background-image"] {

                &::before {
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

        }

        &.#{$modifier} & {

            &-hgroup,
            &-leadtext,
            &-metadata {
                width: get-column-width(math.div(8, 12));
            }

            &-image,
            &-video {

                &::after { // Spacer
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

        }

    }

}
