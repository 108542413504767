%formField-search {
    $icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" xmlns="http://www.w3.org/2000/svg"><path d="M13.4619 13.4619L22 22"/><circle cx="8.5" cy="8.5" r="7"/></svg>';
    $icon_width: 24px;

    display: block;

    &-input {
        background: svg-url($icon) no-repeat;
        background-position: center right nth($field_padding, 2);

        padding-right: calc(#{2 * nth($field_padding, 2)} + #{$icon_width}) !important;
    }

}

.formField.is-search {
    @extend %formField-search;

    .formField-input {
        @extend %formField-search-input;
    }

}
