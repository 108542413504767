.newsletterPanel {
    @include stack-spacing(section);

    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    @include full-width-backdrop();

    text-align: center;

    &-title {
        @extend h2;
        margin-top: -.7em !important;
    }

    &-form {
        width: 100%;
        display: flex;
    }

    &-title + &-form {
        @include stack-spacing();
    }

    .formField {
        flex: 1 0 auto;
        margin-top: 0;

        &-input {
            height: $button_height;
        }

        .label {
            position: absolute;

            display: block;
            width: 0;

            overflow: hidden;
        }

    }

    &-button {
        margin: 0 0 0 (.5 * $grid_gutter);

        display: block;

        color: $text-color;
        background-color: $ui_background-color__hover;

        &:hover {
            color: $white;
            background-color: $ui-background-color;
        }

    }

    @include only-on-desktop(){
        width: get-column-width(math.div(6, 12));
        margin-left: auto;
        margin-right: auto;
    }

    @include not-on-desktop(){

        .formField {

            &-input {
                height: $button_height__mobile;
            }

        }

        &-button {
            margin-left: (.5 * $grid_gutter__mobile);
        }

    }

    @include only-on-mobile(){

        &-button {
            width: $button_height__mobile;
            height: $button_height__mobile;

            padding-left: 0; padding-right: 0;

            white-space: nowrap;
            overflow: hidden;
            text-indent: $button_height;

            position: relative;

            svg {
                position: absolute;
                left: 50%; top: 50%;
                transform: translate(-50%, -50%);

                line-height: 0;

                margin: 0 !important;
            }

        }

    }

    @include not-on-mobile(){

        &-button {

            .icon {
                display: none;
            }

        }

    }

}


html[dir="rtl"] {

    @include only-on-mobile(){

        .newsletterPanel {

            &-button.is-proceed {

                svg {
                    transform: translate(-50%, -50%) scaleX(-1);
                }

            }

        }

    }

}
