.topicInsert {
    @include stack-spacing(section);

    &-header {
        @include stack-spacing(component, padding-top);
        @include stack-spacing(component, padding-bottom);

        color: $white;
        text-align: center;

        position: relative;
        z-index: 1;

        @include full-width-backdrop($ci_leading-color);
    }


    &-title {
        font-size: rem(80px);
    }

    &-content {
        background-size: 0 0;

        @include full-width-backdrop();

        &::before { // Backdrop
            background-image: inherit;
            background-size: cover;
        }

        > .wrapper {
            background-color: $page-color;
        }

        .textBlock,
        .highlightInsert,
        .quoteInsert,
        .ctaInsert {
            @include stack-spacing(component);
        }

    }

    &-heading {
        @include heading-style(2);
    }

    &-heading + .textBlock {
        @include stack-spacing();
    }

    &-module {
        @include stack-spacing(section);

        &-title {
            @extend h2;

            text-align: center;
        }

    }

    .videoInsert,
    .imageInsert,
    .teaserCardGroup,
    .authorCard {
        @include stack-spacing(section);
    }

    &-module {

        .videoInsert,
        .imageInsert,
        .teaserCardGroup,
        .authorCard {
            margin-top: 0;
        }

        &-title {

            & + .videoInsert,
            & + .imageInsert,
            & + .teaserCardGroup,
            & + .authorCard {
                @include stack-spacing(component);
            }

        }

    }

    @include only-on-desktop(){

        &-content {
            width: get-column-width( math.div(10, 12));
            margin-left: auto; margin-right: auto;

            &::before { // Backdrop
                background-image: inherit;
                background-size: cover;

                filter: blur(8px);
                transform: translateX(-50%) scale(1.05);
            }

            &::after { // Hard border at bottom
                content: "";

                display: block;
                width: 100vw; max-width: $page_max-width;
                height: $stack-spacing__section;

                position: absolute;
                top: 100%; left: 50%;
                transform: translateX(-50%);
                z-index: -1;

                background-color: $page-color;
            }

            > .wrapper {
                padding-top: $stack-spacing__section;
                padding-bottom: $stack-spacing__component;

                padding-left: get-column-indent(10);
                padding-right: get-column-indent(10);

                background-color: $page-color;
            }

        }

        .videoInsert {
            width: get-column-width(math.div(8, 12));
            margin-left: auto;
            margin-right: auto;
        }

        .authorCard {
            width: get-column-width(math.div(8, 12));
            margin-left: auto;
            margin-right: auto;
        }

    }

    @include not-on-desktop(){
        $padding: $box-padding__mobile;
        $image-ratio: math.div(3, 2);

        &-header {
            padding: $page_padding__mobile 0;
        }

        &-content {
            $indent: (2 * $stack-spacing__component);

            &::before { // Backdrop
                width: calc(100% + 2 * #{$padding}); height: 0;
                padding-top: (math.div(2, $image-ratio) * $padding);
                padding-bottom: (math.div(1, $image-ratio) * 100%);

                margin-left: (-1 * $padding);
                margin-right: (-1 * $padding);

                position: static;
                transform: none;
            }

            > .wrapper {
                padding: $box-padding__mobile $box-padding__mobile 0;
                margin-top: (-1 * $indent);

                min-height: $indent;
            }

        }

        &-title {
            @include heading-style(1, $headings_styles__mobile);
        }

        &-heading {
            @include heading-style(2, $headings_styles__mobile);
        }

        .teaserCardGroup-title {
            text-align: center;
        }

    }

        //***** Indent on bottom *****//

    @include only-on-desktop(){
        $indent: 4;

        &.has-modules &,
        &.has-bottom-indent & {

            &-content > .wrapper {
                padding-bottom: ($indent * $stack-spacing__section);
                padding-bottom: calc(#{$indent} * var(--sp-section));
            }

        }

        &.has-modules &-content + *,
        &.has-bottom-indent &-content + * {
            margin-top: ((1 - $indent) * $stack-spacing__section);
            margin-top: calc(#{1 - $indent} * var(--sp-section));

            position: relative;
            z-index: 1;
        }

    }

}
