.infographicSlide {
    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    display: flex;
    flex-direction: column;
    min-width: 100%;

    @include color-themes((
        color: text-color,
    ));

    @include full-width-backdrop();

    &::before {

        @include color-themes((
            background-color: primary,
        ));

    }

    .figure {
        margin-top: 0;

        &-image-container {
            background-color: transparent !important;

            &::before {
                display: none !important;
            }

        }

    }

    > .wrapper {
        flex: 1 0 auto;
        height: 100%;

        display: flex;
        flex-direction: column;

        justify-content: flex-start;
    }

    &-topic {
        font-size: $base_font-size__small;
        font-weight: $base_font-weight__bold;
        text-transform: uppercase;
        letter-spacing: .025em;
    }

    &-title {
        @include stack-spacing(small);
    }

    &-image {
        @include stack-spacing(component);
    }

    &-content {
        @include stack-spacing(component);

        & > *:first-child {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        $col-span: 10;

    	> .wrapper {
            width: get-column-width(math.div($col-span, 12));
    	}

        &-image {
            width: get-column-width(math.div(12, $col-span));
        }

    }

    @include not-on-desktop(){

        &-image + .wrapper {
            @include stack-spacing(component);
        }

    }

}

    //***** Centered *****//

.infographicSlide {

    @include only-on-desktop(){
        $col-span: 8;

        &.is-centered {
            text-align: center;

        	> .wrapper {
                width: get-column-width(math.div($col-span, 12));
                margin: 0 auto;
        	}

        }

        &.is-centered & {

            &-image {
                width: get-column-width(math.div(12, $col-span));
                margin-left: get-columns-indent(-.5 * (12 - $col-span), $col-span);
            }

        }

    }

}

    //***** Cut-out *****//

.infographicSlide {

    $themes: (
        leading-color: $ci_leading-color,
        active-color: $ci_active-color,
        calming-color: $ci_calming-color,
        structure-color: $ci_structure-color,
        leading-blue: $ci_leading-color,
        active-blue: $ci_active-color,
        calming-green: $ci_calming-color,
        structure-silver: $ci_structure-color,
    );

    @function get-gradient($gradient-color: $ci_leading-color){
        @return radial-gradient(rgba($gradient-color, .15) 20%, rgba($gradient-color, .4) 60%, rgba($gradient-color, .55) 80%, rgba($gradient-color, .65));
    }

    @include only-on-desktop(){
        $col-span: 8;

        &.is-cut-out {
            text-align: center;

            &::before {
                background-color: $page-color !important;
                background-image: get-gradient($ci_leading-color);
            }

        	> .wrapper {
                width: get-column-width(math.div($col-span, 12));
                margin: 0 auto;
        	}

        }

        @each $theme, $color in $themes {

            &.is-cut-out.is-#{$theme} {
                color: $text-color;

                &::before {
                    background-image: get-gradient($color);
                }

            }

        }

        &.is-cut-out & {

            &-image {
                width: get-column-width(math.div(12, $col-span));
                margin-left: get-columns-indent(-.5 * (12 - $col-span), $col-span);
            }

        }

    }

    @include not-on-desktop(){

        &.is-cut-out {
            padding-top: 0;

            &::before { // Backdrop
                background: $backdrop-color !important;
            }


            > .wrapper {
                display: flex;
                flex-direction: column;

                > * {
                    flex: 0 0 auto;
                }

            }

        }

        &.is-cut-out & {

            &-image {
                order: -1;

                padding: $stack-spacing__component__mobile $page_padding__mobile;
                margin: 0 (-1 * $page_padding__mobile);

                background-image: get-gradient($ci_leading-color);
            }

            &-header {
                @include stack-spacing(component);
            }

            &-content {
                @include stack-spacing();
            }

        }

        @each $theme, $color in $themes {

            &.is-cut-out.is-#{$theme} {
                color: $text-color;
            }

            &.is-cut-out.is-#{$theme} &-image {
                background-image: get-gradient($color);
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    @include only-on-desktop(){
        $col-span: 8;

        .infographicSlide.is-centered .infographicSlide {

            &-image {
                margin-left: 0;
                margin-right: get-columns-indent(-.5 * (12 - $col-span), $col-span);
            }

        }

    }

}
