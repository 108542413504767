.shareButton {
    $icon_size: 24px;
    $icon_data: get-icon-data-url(link-success, $white);

    $duration: $base_transition-duration__out;

    &.js-copy-url,
    &[data-copy-text] {
        position: relative;

        .icon {
            opacity: 1;
        }

        &,
        &[href^="http"] {

            &::after {
                content: "";

                display: block;
                width: $icon_size; height: $icon_size;

                position: absolute;
                top: 50%; left: 50%;

                transform: translate(-50%, -50%);

                margin: 0;

                background: url($icon_data) center center no-repeat;

                opacity: 0;
                transition: $duration;

                clip-path: inset(0 24px 0 0);
            }

        }

        &.was-successful {
            background-color: $ui-background-color;

            transition: background-color $duration, border-color $duration;

            .icon {
                opacity: 0;
                transition: (.5 * $duration);
            }

            &::after {
                clip-path: inset(0 0 0 0);
                opacity: 1;
                transition: opacity $duration (.5 * $duration), clip-path (.5 * $duration) (1 * $duration);
            }

        }

    }

    &.is-secondary {
        $icon_data: get-icon-data-url(link-success, $ui-color);

        &.js-copy-url,
        &[data-copy-text] {

            &::after {
                background-image: url($icon_data);
            }

            &.was-successful {
                background-color: transparent;
                border-color: $ui-background-color;
            }

        }

    }

}
