$multipleSelect_pill_height: 2 * floor(.5 * $field_line-height * $base_font-size);

.multipleSelect {
    @include stack-spacing();

    &-label {
        @extend .label;
        margin-top: 0;
    }

    &-message {
        @extend .formField-message;

        opacity: 1;
        max-height: (3 * $base_line-height * $base_font-size__small);
        transform: translateY(0%);
        overflow: hidden;

        transition: $base_transition-duration__out;

        &:not(.is-visible) {
            opacity: 0;
            max-height: 0;
            transform: translateY(-100%);

            transition: $base_transition-duration__out;
        }

    }

    @include only-on-desktop(){

        &-message {
            position: absolute;
        }

    }

}

.choices {
    $pill_height: $multipleSelect_pill_height;

    $icon_size: 10;
    $icon_stroke-width: 1.25;
    $icon: get-svg-data-url('<svg viewBox="0 0 #{$icon_size} #{$icon_size}" fill="none" stroke="{{color}}" stroke-width="#{$icon_stroke-width}" stroke-linecap="round" xmlns="http://www.w3.org/2000/svg"><line x1="#{.5 * $icon_stroke-width}" y1="#{.5 * $icon_stroke-width}" x2="#{$icon_size - .5 * $icon_stroke-width}" y2="#{$icon_size - .5 * $icon_stroke-width}"/><line x1="#{.5 * $icon_stroke-width}" y2="#{.5 * $icon_stroke-width}" x2="#{$icon_size - .5 * $icon_stroke-width}" y1="#{$icon_size - .5 * $icon_stroke-width}"/></svg>', $white);

    $icon_size: $icon_size * 1px;

    $dropdown_padding: (.5 * $grid_gutter);

    position: relative;
    overflow: hidden;

    &.is-open {
        overflow: initial;
    }

    &__inner {
        @extend %field;

        display: block;

        width: auto;
        min-height: $pill_height;

        box-sizing: content-box;
        padding: $field_padding;
        padding-top: 0;

        background-color: $white;

        transition: none;

        &::after {
            content: "";
            display: block;
            clear: left;
        }

    }

    &.is-focused &__inner,
    &.is-open &__inner {
        border-color: $ui-color__hover;
    }

    &__input {
        @extend %field;

        display: inline-block;
        float: left;

        margin-top: nth($field_padding, 1);

        min-width: 0 !important;
        max-width: 100%;
        min-height: $pill_height;

        padding: 0;

        border: none;
        background-color: transparent;

        &:focus {
            outline: 0;
        }

        &[type="search"] {

            &::-ms-clear, &::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none;
            }

        }

    }

    &__list--multiple:empty + &__input:empty {
        width: 100% !important;
    }


    &__placeholder {
        opacity: 1;
        color: $field_placeholder_color;
    }

    &__list--multiple:not(:empty) + &__input {

        &::placeholder {
            color: transparent;
        }

    }

    &__item {
        cursor: default;

        &::selection {
            background-color: transparent;
        }

        &.has-no-choices,
        &.has-no-results {
            color: rgba($white, .5);
        }

    }

    &__item--selectable {
        cursor: pointer;
    }

        //***** Pills *****//

    $pill_text-offset: 2px;

    &__list--multiple &__item {
        display: inline-block;
        float: left;

        margin-top: nth($field_padding, 1);

        height: $pill_height;
        line-height: ($pill_height + $pill_text-offset);

        border-radius: (.5 * $pill_height);
        padding: 0 (.5 * $pill_height);

        font-size: $base_font-size__small;

        background-color: $text-color;
        color: $white;

        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;

        margin-right: nth($field_padding, 1);

        &:first-child {
            margin-left: -.2em;
        }

        &.is-highlighted {
            background-color: $ui_background-color__active;
        }

    }

        //***** Dropdown list *****//

    &__list--dropdown {
        width: 100%;
        padding: 0;

        position: absolute;
        top: 100%;

        color: $white;

        overflow: hidden;
        word-break: break-all;

        opacity: 0;
        pointer-events: none;
        z-index: -1;

        transition: all $base_transition-duration__out, z-index 0s $base_transition-duration__out;

        &::before { // Backdrop
            content: "";

            display: block;
            width: 100%; height: 0;

            position: absolute;
            top: 0; left: 0;

            background: $text-color;
            transition: inherit;
        }

        &::after { // Fade out on bottom
            $max-scrollbar-width: 17px;

            content: "";

            display: block;
            width: 100%;
            width: calc(100% - #{$max-scrollbar-width});
            height: $dropdown_padding;

            position: absolute;
            bottom: 0; left: 0;
            z-index: 1;

            background: linear-gradient(to top, rgba($text-color, 1), rgba($text-color, 0));

            pointer-events: none;
        }

        &.is-active {
            z-index: 1;
            opacity: 1;
            pointer-events: all;

            transition: all $base_transition-duration__in, z-index 0s;

            &::before {
                height: 100%;
            }

        }

    }

    &__list--dropdown &__list {
        padding: 0 $dropdown_padding $dropdown_padding;
    }

    &__list--dropdown &__placeholder {
        display: none !important;
    }

    &__list--dropdown &__item {
        $border-colors: define-color-states(
            transparent,
            $ui-color__hover,
            $ui-color__active
        );

        $border-width: $base_border-width;

        $padding: .2em 0 $border-width;

        @include stack-spacing(small);

        display: block;

        padding: $padding;

        font-size: $base_font-size__small;
        line-height: 1.2;

        position: relative;

        // Force displaying each item in single line
    	float: left;
    	clear: both;

        &:not(.choices__item--disabled) {
            cursor: pointer;
        }

        &--selectable {
            position: relative;

            &::before {
                content: "";

                display: block;
                width: 100%;

                position: absolute;
                bottom: 0;

                border-bottom: $border-width solid color-state($border-colors);

                @include base-transition();
                transition-property: border-color;
            }

            &:active::before {
                border-color: color-state($border-colors, active);
            }

        }

        &--selectable.is-highlighted {

            &::before {
                border-color: color-state($border-colors, hover);
                @include base-transition(in);
            }

        }

        @media (hover: none) {
            padding-bottom: nth($padding, 1);

            &--selectable::before {
                display: none;
            }

        }

    }

        //***** Single *****//

    &[data-type*='select-one'] {

        &::after {
            display: none;
        }

    }

    &[data-type*='select-one'] &__list {
        cursor: default;
    }

    &__list--single {
        padding: 0 ($formField_select_markersize - nth($field_padding, 1)) 0 (nth($field_padding, 2) - nth($field_padding, 1));

        &::after { // Marker
            content: "";

            position: absolute;
            top: 50%; right: 0;

            transform: translateY(-50%);

            display: block;
            height: 100%; width: $formField_select_markersize;

            margin-top: (-.5 * $field_border-width);

            pointer-events: none;

            @include state-styles($formField_select_marker_states);

            transition: inherit;
        }

        &:hover {
            @include base-transition(in);

            select {
                border-color: $ui-color__hover;
                transition: inherit;
            }

            &:after {
                @include state-styles($formField_select_marker_states, hover);
            }

        }

        &:focus {

            &:after {
                @include state-styles($formField_select_marker_states, focus);
            }

        }

    }

        //***** Button *****//

    &__button {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;

        appearance: none;
        cursor: pointer;

        border: none;

        &:focus {
            outline: none;
        }
    }

    &[data-type*='select-multiple'] &__button,
    &[data-type*='select-one'] &__button,
    &[data-type*='text'] &__button {
        box-sizing: content-box;
        width: $pill_height; height: $pill_height;

        display: inline-block;
        margin: 0 (-.4 * $pill_height) 0 0;
        padding: 0;

        position: relative;
        top: (-.5 * $pill_text-offset);

        background: transparent;

        &::after { // Backdrop
            content: "";

            display: block;
            width: 100%; height: 100%;

            position: absolute;
            top: 0; left: 0;

            background: url($icon) 50% 50% no-repeat;
            background-size: $icon_size;

            opacity: 1;
            transform: scale(1);

            @include base-transition();
        }

        &:hover::after {
            opacity: .5;
            transform: scale(.8);

            @include base-transition(in);
        }

    }

    &[data-type*='select-one'] &__item {
        position: relative;
    }

    &[data-type*='select-one'] &__button {
        width: .75 * $pill_height; height: .75 * $pill_height;

        position: absolute;
        right: 0; top: 50%;

        transform: translateY(-50%);

        background: $ui_background-color;
        opacity: 1;

        margin-right: 0;

        &:hover {
            background-color: $ui_background-color__hover;

            &::after {
                opacity: 1;
            }

        }

    }

        //***** Desktop only *****//

    @include not-on-desktop(){
        $pill_height__mobile: 2 * floor(.5 * $field_line-height * $base_font-size__mobile);

        &__list--multiple &__item {
            height: $pill_height__mobile;
            line-height: ($pill_height__mobile + $pill_text-offset);

            border-radius: (.5 * $pill_height__mobile);
            padding: 0 (.5 * $pill_height__mobile);

            position: relative;
            top: .5 * ($pill_height - $pill_height__mobile);
        }

        &[data-type*='select-multiple'] &__button,
        &[data-type*='text'] &__button {
            width: $pill_height__mobile; height: $pill_height__mobile;
            margin-right: (-.4 * $pill_height__mobile);
        }

    }

}

html[dir="rtl"] {

    .choices {

        &__input {
            margin-left: 0;
            margin-right: (nth($field_padding, 2) - nth($field_padding, 1));
        }

        &__list--dropdown {

            .choices__item {
            	float: right;
            }

        }

        &__list--multiple {

            .choices__item {
                margin-right: 0;
                margin-left: (.5 * nth($field_padding, 2));
            }

        }

        &[data-type*='select-multiple'],
        &[data-type*='select-one'],
        &[data-type*='text'] {

            .choices__button {
                margin-right: 0;
                margin-left: (-.4 * $multipleSelect_pill_height);
            }

        }

        &__list--single {

            padding-left: ($formField_select_markersize - nth($field_padding, 1));
            padding-right: (nth($field_padding, 2) - nth($field_padding, 1));

            &::after {
                right: auto;
                left: 0;
            }

        }

        &[data-type*='select-one'] {

            .choices__button {
                margin: 0;
                right: auto;
                left: 0;
            }

        }


    }

}
