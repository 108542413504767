$highlightList_icon_size: 24px;
$highlightList_item_text-indent: math.div(7 * $highlightList_icon_size, 4);

.highlightList {
    $icon_size: $highlightList_icon_size;
    $icon_data: get-icon-data-url(tick, white);
    $item_text-indent: $highlightList_item_text-indent;

    &-title {
        font-size: 1rem;
        @extend .is-uppercase;

        &::before {
            content: "";

            display: inline-block;
            width: get-column-width(math.div(1, 12)); height: .66em;

            margin-right: .8em;

            background-color: $text-color;
        }

    }

    &-list {
        padding: 0;
        list-style: none;
    }

    &-item {
        font-weight: $base_font-weight__medium;
        font-size: 1.05rem;

        @include stack-spacing(small);
        padding-left: $item_text-indent;

        &::before {
            content: "";

            display: inline-block;
            width: $icon_size; height: $icon_size;

            vertical-align: middle;

            position: relative;
            left: (-1 * $item_text-indent); top: -.1em;
            margin-right: (-1 * $icon_size);

            background: $ci_impulse-color url($icon_data) center center no-repeat;
            border-radius: 50%;
        }

    }

    @include not-on-desktop(){

        &-title {

            &::before {
                width: calc(#{$item_text-indent} - .8em);
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {
    $icon_size: $highlightList_icon_size;
    $item_text-indent: $highlightList_item_text-indent;

    .highlightList {

        &-title {

            &::before {
                margin-right: 0;
                margin-left: .8em;
            }

        }

        &-item {
            padding-left: 0;
            padding-right: $item_text-indent;

            &::before {
                left: auto;
                right: (-1 * $item_text-indent);
                margin-right: 0;
                margin-left: (-1 * $icon_size);
            }

        }

    }

}
