.sourceList {

    &-link {

        &[href^="http"] {
            text-decoration-color: $ci_active-color;
        }

    }

}
