@import "scss/_pinchzoomer";

// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.imageZoom {

    .figure-caption {
        display: none;
    }

}

*[data-elem="pinchzoomer"]{
    width: 100%; height: 100%;
}


    // On lazy-loading figures show zoom only if figure is loaded

.lazyloadingFigure .js-imageZoom-trigger .figure:not(.is-loaded) {

    a {
        cursor: default;

        &::after{
            display: none;
        }

    }

}
