// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.affinityLinkBar + .topicFinder {
    margin-top: 0;
}

.topicFinder {

    .teaserCard {
        margin-top: 0;
        background-color: transparent;
    }

    @include only-on-desktop(){

        &.shows-tiles {

            .teaserCard {
                max-width: 100%;
            }

        }

    }

    &:not(.shows-tiles) {

        .teaserCard {
            @extend %teaserCard--side-by-side;
        }

        @include only-on-mobile(){

            .teaserCard-image {
                display: none;
            }

        }

    }

}
