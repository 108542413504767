%fixedRatioFigure {
    @extend %figure;

    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 0 0;

    position: relative;

    &::before { // Placeholder for image
        content: "";

        display: block;

        width: 100%; height: 0;
        padding-bottom: math.div(1, $fixedRatioFigure_ratio) * 100%;

        background: $figure_background-color center center no-repeat;
        background-image: inherit;
        background-position: inherit;
        background-size: cover;
    }

    a {
        position: absolute;
        top: 0; left: 0;

        display: block;

        width: 100%; height: 0;
        padding-bottom: math.div(1, $fixedRatioFigure_ratio) * 100%;
    }

    img { // Hide image
        width: 1px;
        height: 1px;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        margin-top: 0;
    }

    &[data-orientation="portrait"] {
        @include fixedRatioFigure-set-ratio(math.div(1, $fixedRatioFigure_ratio));
    }

    &[style*="--ratio"] {

        a,
        &::before {
            padding-bottom: calc(1/var(--ratio) * 100%);
        }

    }

}

.fixedRatioFigure {
    @extend %fixedRatioFigure;

    &-caption {
        @extend %figure-caption;
    }

}
