.is-applicationIndex {

    @include only-on-desktop(){
        $column-span: 8;

        .affinityLinks,
        .dataInsert,
        .mainSection,
        .textBlock {
            width: get-column-width(math.div($column-span, 12));
            margin-left: auto; margin-right: auto;
        }

        .mainSection .textBlock {
            width: 100%;
        }

        .dataInsert {
            width: calc((100% + #{$grid_gutter}) * #{(math.div($column-span, 12))});
            @include dataInsert-set-columns($column-count: $column-span);
        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    @include only-on-desktop(){

        .is-applicationIndex .dataInsert {
            @include dataInsert-set-columns($rtl: true);
        }

    }

}
