$intrapageNav_height: 74px !default;
$intrapageNav_height__mobile: 56px !default;

$intrapageNav_gutter: $mainNav_gutter !default;

@import "scss/_styles";

    //** WIP: Layout only **//

.is-demo {
    @extend %pageLayout__narrow;
}

    //***** In combination with Main Header *****//

.intrapageNav {
    padding-top: $mainHeader_height;
    padding-top: var(--mainHeader-height);

    position: relative;
    top: (-1 * $mainHeader_height) !important;
    top: calc(-1 * var(--mainHeader-height)) !important;

    margin-bottom: (-1 * $mainHeader_height);
    margin-bottom: calc(-1 * var(--mainHeader-height));

    z-index: 199;

    .mainHeader {
        display: none;
        position: absolute;
    }

    .mainHeader-spacer + &,
    .mainHeader-spacer + & + &-spacer {
        margin-top: 0;
    }

    &.js-makeSticky {

        &.is-stuck {
            top: 0 !important;
            z-index: 201;

            .mainHeader {
                display: block;
            }

            &.is-collapsed {
                transition: transform (math.div(2, 3) * $base_transition-duration__out) ease-in-out;
            }

            &.is-revealed {
                transform: translate(-50%, 0) !important;
                transition: transform $base_transition-duration__out ease-in-out;
            }

        }

    }

    @include only-on-desktop(){

        &.js-makeSticky {

            &.is-stuck {

                &.is-collapsed:hover {
                    transform: translate(-50%, 0) !important;
                    transition: transform $base_transition-duration__out ease-in-out;
                }

            }

        }

    }

    @include not-on-desktop(){

        .mainHeader {
            display: block !important;
        }

    }

}

.mainHeader,
.mainHeader-spacer {

    & + .intrapageNav,
    & + .intrapageNav + .intrapageNav-spacer {
        margin-top: 0;
    }

}


    //***** In combination with off-canvas menue *****//

@include not-on-desktop(){

    .intrapageNav {
        padding-top: $mainHeader_height__mobile;

        .mainHeader {

            > .wrapper {
                height: $mainHeader_height__mobile !important;
            }

            &-branding {
                display: block;
            }

        }

    }

    .menu-is-visible {

        .intrapageNav {
            z-index: 199;
        }

    }

}


    //***** In combination with Page Header *****//

.pageHeader,
.pageHeader-continue,
.careerPageHeader {

    & + .intrapageNav,
    & + .intrapageNav + .intrapageNav-spacer {
        margin-top: 0;
    }

}

    //***** Regarding viewport *****//
    //** Set position of navigation to top on small viewports **//

$min-height: floor($pageHeader_wallpaper_min-height + $intrapageNav_height + $mainHeader_height);
$max-height: floor((math.div($page-content-max-width + $grid-gutter, $pageHeader-wallpaper-ratio)) + $intrapageNav_height + $mainHeader_height);
$max-width: floor(($pageHeader_wallpaper_ratio * $pageHeader_wallpaper_min-height) + (2 * $page_padding));

:root {
    --intrapageNav-height: #{$intrapageNav_height};

    @include only-on-mobile(){
        --intrapageNav-height: #{$intrapageNav_height__mobile};
    }

}

@mixin intrapageNav--fixed(
    $is-mobile: false,
){

    .intrapageNav.js-makeSticky {
        position: fixed !important;
        top: 0 !important;

        & + .intrapageNav-spacer {
            display: none;
        }

    }

    body:has(.intrapageNav) .mainHeader + .mainHeader-spacer,
    .has-intrapageNav .mainHeader + .mainHeader-spacer {

        @if ($is-mobile) {
            padding-top: ($mainHeader_height__mobile + $intrapageNav_height__mobile) !important;
            padding-top: calc(var(--mainHeader-height) + var(--intrapageNav-height)) !important;
        } @else {
            padding-top: ($mainHeader_height + $intrapageNav_height) !important;
            padding-top: calc(var(--mainHeader-height) + #{$intrapageNav_height}) !important;
        }

    }

}

@include not-on-desktop(){
	@include intrapageNav--fixed($is-mobile: true);
}

@media (min-width: $breakpoint__desktop) and (max-width: $max-width) and (max-height: $min-height){
    @include intrapageNav--fixed();
}

@media (min-width: $max-width + 1) and (max-width: $page_max-width) and (min-aspect-ratio: #{math.div($max-width, 1px)}/#{math.div($min-height, 1px)}){
    @include intrapageNav--fixed();
}

@media (min-width: $page_max-width + 1) and (max-height: $max-height){
    @include intrapageNav--fixed();
}

    //***** Right to left *****//

html[dir="rtl"] {

    @include only-on-desktop(){

        .has-intrapageNav {

            .mainHeader {
                left: auto;
                right: 50%;
            }

        }

        .intrapageNav {

            &.js-makeSticky.is-stuck.is-revealed {
                transform: translate(50%, 0) !important;
            }

            &.js-makeSticky.is-stuck.is-collapsed:hover {
                transform: translate(50%, 0) !important;
            }

            .mainHeader {
                left: 50%;
                right: auto;
            }

        }

    }

}
