.copy {
    @include styles($copy_styles);
}

.is-standfirst {
    @include styles($copy_styles__standfirst);

    strong, b {
        // Override Bootstrap’s value `bolder`
        font-weight: inherit;
    }

}

.copy.is-small:not(.button) {
    @include styles($copy_styles__small);

    a {
        @include styles($copy_styles__small, color);
        text-decoration: underline;
    }

}
