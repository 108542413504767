$passwordRevealer_width: 24px !default;
$passwordRevealer_height: $passwordRevealer_width !default;

$passwordRevealer_color: $minor-color !default;
$passwordRevealer_color__hover: $ui-color !default;
$passwordRevealer_color__revealed: $ui-color !default;
$passwordRevealer_color__disabled: $disabled-color !default;

$passwordRevealer_icons: (
    hidden: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M3 21L21 3"/><path d="M15.8 12.5C15.6 14.4 14.1 15.9 12.3 16M19.6 8.6C20.8 9.6 22 10.7 23 12C23 12 18.1 18.5 12 18.5C11.3 18.5 10.7 18.4 10 18.2M8.2 11.5C8.4 9.6 9.9 8.2 11.7 8M4.4 15.4C3.1 14.4 2 13.3 1 12C1 12 5.9 5.5 12 5.5C12.7 5.5 13.3 5.6 14 5.7"/></svg>',
    visible: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M12 8.5C13.8492 8.5 15.3483 10.067 15.3483 12C15.3483 13.933 13.8492 15.5 12 15.5C10.1508 15.5 8.65167 13.933 8.65167 12C8.65167 10.067 10.1508 8.5 12 8.5Z"/><path d="M22.883 11.672C21.7704 10.352 17.321 5.5 12 5.5C6.67902 5.5 2.22956 10.352 1.11695 11.672C0.961016 11.86 0.961016 12.14 1.11695 12.328C2.22956 13.648 6.67902 18.5 12 18.5C17.321 18.5 21.7704 13.648 22.883 12.328C23.039 12.14 23.039 11.86 22.883 11.672Z"/></svg>',
) !default;
