$streamlineIcon_size: $icon_size;

.streamlineIcon {
    @extend %icon;

    .icon {
        width: 100%;
        height: 100%;
    }

    svg {
        width: $streamlineIcon_size;
        height: $streamlineIcon_size;

        * {
            stroke: currentColor !important;
        }

        @include icon--set-stroke-width();

    }

}
