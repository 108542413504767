.teaserCardGroup {
    @include stack-spacing(section);

    &:empty {
        margin-bottom: 0;
    }

    &-title {
        @include stack-spacing(component, margin-bottom);
    }

    &-description {
        margin-top: ($stack-spacing - $stack-spacing__component);
    }

    &-description + &-items {
        @include stack-spacing(component);
    }

    &-item {
        @include stack-spacing(component);

        .teaserCard {
            max-width: none;
            margin-top: 0;
        }

    }

    &-cta {
        @include stack-spacing(component);
        text-align: center;
    }

    @include only-on-desktop(){

        &-title,
        &-description {
            @include set-column-width(math.div(8, 12));

            margin-left: auto;
            margin-right: auto;

            text-align: center;
        }

        &-items {
            display: flex;
            flex-wrap: wrap;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            margin-bottom: (-1 * $stack-spacing__component);
            transform: translateY(-1 * $stack-spacing__component);
        }

        &-item {
            flex: 0 0 auto;
            width: (math.div(1, 3) * 100%);
            max-width: (math.div(1, 3) * 100%);

            padding: 0 (.5 * $grid_gutter);
        }

    }

    @include not-on-desktop(){

        &-description {
            margin-top: ($stack-spacing__mobile - $stack-spacing__component__mobile);
        }

    }

    @include only-on-mobile(){

        &-cta {

            .button {
                display: block;
            }

        }

    }

}

    //***** Emphasised *****//

.teaserCardGroup {

    &.is-emphasised {
        @include stack-spacing(section, padding-top);
        @include stack-spacing(section, padding-bottom);

        @include color-themes((
            color: secondary,
        ));

        &.is-calming-color.is-calming-color,
        &.is-calming-green.is-calming-green {
            color: $text-color;

            a {
                color: $text-color;
                text-decoration-color: $text-color;

                &:hover {
                    text-decoration-color: transparent;
                }

            }

            .navigationLink-link {

                &::before {
                    border-bottom-color: $text-color;
                }

            }

            .teaserCard-topic {
                color: $white;
            }

        }

        @include full-width-backdrop();

        &::before {

            @include color-themes((
                background-color: primary,
            ));

        }

        .teaserCard {
            background-color: transparent;
        }

    }

    @include not-on-desktop(){

        &.is-emphasised & {

            &-title,
            &-description {
                text-align: center;
            }

        }

    }

}

    //***** Large *****//

.teaserCardGroup {

    @include only-on-desktop(){

        &.is-large {
            align-items: stretch;
        }

        &.is-large & {

            &-items {
                margin-bottom: (-1 * $grid_gutter);
                transform: translateY(-1 * $grid_gutter);
            }

            &-item {
                width: (.5 * 100%);
                max-width: (.5 * 100%);

                margin-top: $grid_gutter;

                .teaserCard {
                    min-height: 100%;

                    display: flex;
                    flex-direction: column;

                    & > .wrapper {
                        flex: 1 0 auto;

                        display: flex;
                        flex-direction: column;
                    }

                    &-content {
                        flex: 1 0 auto;
                    }

                }

            }

        }

    }

}

    //***** Featured and Wallpaper *****//

.teaserCardGroup {

    @include only-on-desktop(){

        &.is-featured &,
        &.is-wallpaper & {

            &-item {
                width: 100%;
                max-width: 100%;
            }

        }

    }

}

html:not([dir="rtl"]) {

    .teaserCardGroup.is-featured .teaserCardGroup {

        &-item:nth-child(even) {

            .teaserCard.is-featured {
                @extend %teasercard--fatured-alteration;
            }

        }

    }

}

@include only-on-desktop(){

    html[dir="rtl"] {

        .teaserCardGroup.is-featured .teaserCardGroup {

            &-item:nth-child(even) {

                .teaserCard.is-featured {
                    margin-left: 0; margin-right: auto;
                }

                .teaserCard.is-featured .teaserCard-image {
                    right: auto;
                    left: get-columns-indent(5, 6);
                }

            }

        }

    }

}
