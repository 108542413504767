.pageHeader {
    $modifier: "is-large";
    $image-ratio: 1;
    $image-ratio__desktop:  math.div(5, 2);

    $color-theme: leading-color;

    $text-color: get-theme-color(
        $theme: $color-theme,
        $key: text-color,
    );

    $backdrop-color: get-theme-color(
        $theme: $color-theme,
        $key: primary,
    );

    $accent-color: get-theme-color(
        $theme: $color-theme,
        $key: accent-color,
    );

    &.#{$modifier} {
        display: block;
        margin: 0;

        @include stack-spacing(section, padding-bottom);

        color: $text-color;

        a {
            color: $text-color;

            &.button {
                border-color: $text-color;
            }

            &:hover {
                color: $accent-color;
                border-color: $accent-color;
            }

        }

        @include full-width-backdrop($backdrop-color);

        .heroImage {

            &-figure[style*="background-image"] {
                display: block;

                &::before {
                    background-color: $black;
                    padding-bottom: (math.div(1, $image-ratio) * 100%);
                }

                &::after { // Gradient
                    content: "";

                    display: block;
                    width: 100%; height: 75%;

                    position: absolute;
                    bottom: 0; left: 0;

                    background: linear-gradient(to top, rgba(black, .8), rgba(black, .4), rgba(black, .15), rgba(black, 0));
                    mix-blend-mode: multiply;
                }

            }

        }

        .intrapageLink {
            display: none;
        }

        .button {
            margin-top: 0;
        }

    }

    &.#{$modifier} & {

        &-image,
        &-video,
        &-hgroup,
        &-title,
        &-metadata {
            padding: 0;
            width: 100%;
        }

        &-image,
        &-video {
            position: absolute;
            top: 0; left: 50%;

            width: 100vw;
            max-width: $page_max-width;
            transform: translateX(-50%);
        }

        &-hgroup {
            $vertical-indent: (0.5 * get-heading-font-size(1) * get-heading-line-height(1));

            margin: 0;

            width: 100vw;
            max-width: $page_max-width;

            padding-bottom: $vertical-indent;

            position: relative;
            z-index: 1;

            &::before { // Spacer with height of image
                content: "";

                display: block;
                width: 100%; height: 0;
                padding-bottom: (math.div(1, $image-ratio) * 100%);
            }

            > .wrapper {
                position: absolute;
                bottom: 0; left: 0;

                padding: 0;
                width: calc(100% - #{2 * $page_padding});
            }

        }

        &-leadtext {
            @include stack-spacing(component);
        }

        &-cta {
            @include stack-spacing(component);
        }

        &-metadata {
            position: static;
            transform: none;

            @include stack-spacing(component);

            &-value:not(:last-child) {

                &::after { // Line
                    border-color: rgba($text-color, .3);
                }

            }

        }

        &-topic {
            display: block;
        }

        &-title {
            display: block;
            width: 100%;
        }

        &-video {
            overflow: hidden;
            z-index: 1;

            &::after { // Spacer
                content: "";

                display: block;
                width: 100%; height: 0;
                padding-bottom: (math.div(1, $image-ratio) * 100%);


                background: linear-gradient(to top, rgba(black, .8), rgba(black, .4), rgba(black, .15), rgba(black, 0) 75%);
                mix-blend-mode: multiply;
            }

            .video {
                position: absolute;
                top: 0; left: 50%;
                transform: translateX(-50%);
                z-index: -1;

                height: auto; width: 100%;
                max-width: none;

                @supports (object-fit: cover) {
                    height: 100.25%; // QUICKFIX: +.5% to prevent white border below video
                    object-fit: cover;
                }

            }

        }

    }

    @include only-on-desktop(){

        &.#{$modifier} {

            .heroImage-figure[style*="background-image"] {

                &::before {
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

        }

        &.#{$modifier} & {

            &-hgroup {

                &::before { // Spacer with height of image
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

                > .wrapper {
                    width: get-column-width(math.div(8, 12));
                }

            }

            &-subtitle {
                width: get-column-width(math.div(8, 12));
            }

            &-metadata {
            }

            &-image,
            &-video {

                &::after { // Spacer
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

        }

    }

    @include not-on-desktop(){

        &.#{$modifier} & {

            &-image,
            &-video {
                margin-left: 0;
                margin-right: 0;
            }

            &-hgroup {
                $vertical-indent: (0.5 * get-heading-font-size(1, $headings_styles__mobile) * get-heading-line-height(1, $headings_styles__mobile));

                padding-bottom: $vertical-indent;

                > .wrapper {
                    width: calc(100% - #{2 * $page_padding__mobile});
                }

            }

        }

    }

}

html[dir="rtl"] {

    .pageHeader.is-large .pageHeader-hgroup > .wrapper {
        left: auto;
        right: 0;
    }

}
