%table {
    text-align: left;

    table-layout: fixed;
    border-collapse: collapse;

    width: 100%;
    margin-bottom: nth($table_padding, 1);

    font-size: $base_font-size__small;

    &-caption {
        @include heading-style(3);

        display: table-caption;
        margin-top: 0;

        text-align: left;

        @include stack-spacing(default, margin-bottom);
    }

    &-tr {

        @if ($table_row_background-color__alt){

            background-color: $table_row_background-color__alt;

            &:nth-child(even) {
                background-color: $table_row_background-color;
            }

        } @else {
            background-color: $table_row_background-color;
        }

    }

    &-th, &-td {
        padding: $table_padding;
        vertical-align: top;

        @if ($table_row_border){
            border-bottom: $table_row_border;
        }

    }

    &-th {
        font-weight: $base_font-weight__bold;
    }

    &-thead & {

        &-th,
        &-td {
            border-bottom: $table_head_border;
            padding-bottom: (.5 * nth($table_padding, 1));
        }

        &-tr {
            background-color: transparent !important;
        }

    }

    &-thead + &-tbody & {

        &-tr {

            background-color: $table_row_background-color;

            &:nth-child(even) {
                background-color: $table_row_background-color__alt;
            }

        }

    }

}

.table {
    @extend %table;

    &-thead {
        @extend %table-thead;
    }

    &-tbody {
        @extend %table-tbody;
    }

    &-caption {
        @extend %table-caption;
    }

    &-tr {
        @extend %table-tr;
    }

    &-th {
        @extend %table-th;
    }

    &-td {
        @extend %table-td;
    }

}
