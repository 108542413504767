.textBlock {
    @include stack-spacing(component);

    &-content {

        > *:first-child {
            margin-top: 0;
        }

    }

    &-content + &-cta {
        @include stack-spacing();
    }

    ul {
        @extend .ul;
    }

    ul > li {
        @extend .ul-li;
    }

    ol {
        @extend .ol;
    }

    ol > li {
        @extend .ol-li;
    }

}
