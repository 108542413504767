$formFieldset_legend_styles: map-get($headings_styles, 3);

$formFieldset_background-color: $backdrop-color__opaque;

// @import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

form {
    margin-top: 0;
}

%formFieldset {
    @include stack-spacing(component);

    @include box-padding();
    padding-top: 0;

    background-color: $formFieldset_background-color;

    & + & {
        @include stack-spacing(component);
    }

    &-legend {
        display: block;
        box-sizing: content-box;
        width: 100%;

        @include box-padding();
        padding-top: calc(.9 * var(--bp));
        padding-bottom: 0;

        margin-left: (-1 * $box-padding);
        margin-right: (-1 * $box-padding);
        margin-left: calc(-1 * var(--bp));
        margin-right: calc(-1 * var(--bp));

        background-color: $formFieldset_background-color;
    }

    &-legend + &-description {
        @include stack-spacing();
        @include stack-spacing(small);
    }

    &-description {

        &:first-child {
            @include box-padding(default, margin-top);
            margin-top: calc(.9 * var(--bp));
        }

    }

    &-content {

        &:first-child {
            @include box-padding(default, margin-top);
        }

        &:not(:first-child){
            @include box-padding(default, margin-top);
        }

    }

    &-legend + &-content {

         &:not(:first-child) {
            @include box-padding(default, margin-top);
        }

    }

    .formField {
        @include box-padding(default, margin-top);

        &:first-child {
            margin-top: 0;
        }

    }

    &-remarks,
    &-buttons {
        //@include stack-spacing(component);
        @include box-padding(default, margin-top);
    }


}
