figure {
    margin: 0;

    img {
        margin-top: 0;
    }

}

img {
    display: block;
    max-width: 100%;
    height: auto;
}
