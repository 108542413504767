.contactDetails {
    $icon_size: 24px;

    font-style: normal;
    font-weight: $base_font-weight__medium;

    &-list {
        margin-top: 0;
    }

    &-label {
        display: block;

            // Hide text
        position: absolute;
        width: 0;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
    }

    &-value {
        margin: 0;
        font-variant-numeric: oldstyle-nums;

        white-space: nowrap;

        &::before {
            content: "";

            display: inline-block;
            width: $icon_size;
            height: $icon_size;

            vertical-align: middle;
            position: relative;
            top: -.1em;
            margin-right: .3em;

            background: 50% 50% no-repeat;
            background-size: contain;
        }

        @each $name in (phone, email, route){
            $icon_data: get-icon-data-url($name);

            &.is-#{$name}::before {
                background-image: url($icon_data);
            }

        }

        a  {
            text-decoration-color: transparent;

            &:hover {
                text-decoration-color: $ci_impulse-color;
            }

        }

    }

    &-channels {
        $gutter: (.5 * $grid_gutter);

        @include stack-spacing();

        list-style: none;
        padding: 0;

        display: flex;
        align-items: center;

        margin-left: (-.5 * $gutter);
        margin-right: (-.5 * $gutter);

        > * {
            margin-left: (.5 * $gutter);
            margin-right: (.5 * $gutter);
        }

        .button {
            display: block;
            margin-top: 0;
        }

    }

    @include not-on-desktop(){

        &-value {

            &::before { // Remove icons
                display: none;
            }

            &:not(:last-child) {
                @include stack-spacing(small, margin-bottom);
            }

            &.is-phone {
                @include stack-spacing(default, margin-bottom);

                a {
                    $icon_data: get-icon-data-url(phone, $white);

                    @include button-style();

                    position: relative;

                    &::before { // Icon
                        content: "";

                        display: inline-block;
                        width: $icon_size; height: $icon_size;

                        margin-right: .3em; margin-top: -.09em;
                        vertical-align: middle;

                        background: url($icon_data) center center no-repeat;
                    }

                }

            }

        }

    }

    @include only-on-desktop(){
        $gutter: $grid_gutter;

        margin-top: ($stack-spacing - $stack-spacing__small);

        &-list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            margin-left: (-.5 * $gutter);
            margin-right: (-.5 * $gutter);
        }

        &-value {
            margin-top: $stack-spacing__small;
            margin-left: (.5 * $gutter);
            margin-right: (.5 * $gutter);
        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .contactDetails {

        &-value {

            &::before {
                margin-right: 0;
                margin-left: .3em;
            }

        }

        @include not-on-desktop(){

            &-value.is-phone {

                a::before {
                    margin-right: 0;
                    margin-left: .3em;
                    transform: scaleX(-1);
                }

            }

        }

    }

}
