.sourceList {

    &-title {
        display: block;
        margin-bottom: (.5 * $stack-spacing);
    }

    &-list {
        @extend .ol;
    }

    &-item {
        @extend .ol-li;
    }

    &-link {
        @extend %external-link;

        &[href^="http"] {
            text-decoration-color: $ci_impulse-color;

            &:hover {
                text-decoration-color: transparent;
            }

        }

    }

}
