.js-passwordRevealer {
	$width: $passwordRevealer_height;
	$height: $passwordRevealer_height;

	$color: $passwordRevealer_color;
	$color__hover: $passwordRevealer_color__hover;
	$color__revealed: $passwordRevealer_color__revealed;
	$color__disabled: $passwordRevealer_color__disabled;

    $icon__hidden: map-get($passwordRevealer_icons, hidden);
    $icon__visible: map-get($passwordRevealer_icons, visible);

	&-trigger {
		display: none;
        width: $width; height: $height;

		cursor: pointer;

		&::after { // Marker
			content: "";

			display: block;
            width: 100%; height: 100%;

            background: 50% 50% no-repeat;
            background-image: url(get-svg-data-url($icon__hidden));
		}

		&:hover::after {
            background-image: url(get-svg-data-url($icon__hidden, $color__hover));
		}

	}

	input:placeholder-shown + &-trigger {
    	pointer-events: none;

    	&::after {
            background-image: url(get-svg-data-url($icon__hidden, $color__disabled));
        }

	}

	&.is-revealed &-trigger {

		&::after { // Marker
            background-image: url(get-svg-data-url($icon__visible));
		}

		&:hover::after {
            background-image: url(get-svg-data-url($icon__visible, $color__hover));
		}

	}

	&.is-revealed input:placeholder-shown + &-trigger {

    	&::after {
            background-image: url(get-svg-data-url($icon__visible, $color__disabled));
        }

	}

		// Show trigger only if script is loaded

	&.is-active &-trigger {
		display: block;
	}

}
