@import "scss/_styles";

.fiftyfiftySlide {

    .keyFactList {

        &-fact {
            font-size: rem(40px);
            font-weight: $base_font-weight;
        }

    }

}
