.slideGroup {

    // Fix for Internet Explorer 11
    //    In flexbox containers with only min-height
    //    `align-items: stretch` will be ignored by IE11

    display: flex;
    flex-direction: column;

}
