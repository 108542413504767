%formField {
    @include stack-spacing();

    .label {
        margin-top: 0;
    }

}

%formField-label {
    @extend %label;
}

%formField-label--disabled {
    @include state-styles($label_states, disabled);
    cursor: default;
}

%formField-input {
    @extend %field;
}

%formField-input--disabled {
    @include state-styles($field_states, disabled);
    user-select: none;

    &::placeholder {
        color: $field_disabled-color;
    }

}

.formField {
    @extend %formField;
}

.formField {

    &.is-text,
    &.is-password,
    &.is-email,
    &.is-tel,
    &.is-url,
    &.is-number,
    &.is-date,
    &.is-datetime-local {

        .formField-input {
            @extend %formField-input;
        }

    }

}

    //***** Variants *****//

@import "variants/_file";
@import "variants/_textarea";
@import "variants/_toggle";
@import "variants/_select";
@import "variants/_search";


    //***** States (FIXME: Make success and error states expandable) *****//

@mixin formField-validated(
    $status: success
){
    .label,
    .formField-group-label {
        @include state-styles($label_states, $status);
    }

    .formField-input:not(:focus),
    .formField-textarea:not(:focus) {
        @include state-styles($field_states, $status);
    }

    &.is-radio,
    &.is-checkbox,
    &.is-radio-group,
    &.is-checkbox-group {

        .label {

            &::before { // Marker
                $styles: get-state-styles($field_states, $status);

                @if map-has-key($styles, border-color){
                    box-shadow: 0 0 0 $field_border-width map-get($styles, border-color);
                }

            }

        }

    }

    @if ($status == success) {

        &.is-radio-group,
        &.is-checkbox-group {

            .formField-input:not(:checked) + .label {
                color: $text-color;

                &::before { // Marker
                    $styles: get-state-styles($field_states, default);

                    @if map-has-key($styles, border-color){
                        box-shadow: 0 0 0 $field_border-width map-get($styles, border-color);
                    }

                }

            }

        }

    }

    &.is-select {

        .formField-select {

            &::after { // Marker
                @include state-styles($formField_select_marker_states, $status);
            }

            select {
                @include state-styles($field_states, $status);
            }

        }

    }

    &.is-file {

        .formField-filename {
            @include state-styles($field_states, $status);
        }

    }

}

.formField {

    &.is-disabled &-label,
    &-label.is-disabled,
    &.is-disabled .label,
    .label.is-disabled {
        @extend %formField-label--disabled;
    }

    &.is-disabled &-input,
    &-input:disabled,
    &-input.is-disabled {
        @extend %formField-input--disabled;
    }

    &.is-valid {
        @include formField-validated(success);
    }

    &.is-required.is-invalid {
        @include formField-validated(error);
    }

}

    //***** Layout *****//

.formField {

    &.is-inline {
        display: inline-block;
        width: auto;

        & + & {
            margin-left: $grid_gutter;
        }

    }

    $widths: (
        one-half: math.div(1, 2),
        one-third: math.div(1, 3),
        two-third: math.div(2, 3),
        one-fourth: math.div(1, 4),
        three-fourth: math.div(3, 4),
    );

    @include only-on-desktop() {

        $selector: ();

        @each $name, $width in $widths {

            &.is-#{$name} {
                display: block;
                float: left;

                @include set-column-width($width);

            }

            @each $name_sibling in map-keys($widths) {
                $selector: append($selector, "&.is-#{$name} + &.is-#{$name_sibling}", comma)
            }

        }

        #{$selector} {
            margin-left: $grid_gutter;
        }

    }

}


    //***** Messages *****//

%error-message {
    color: $field_error-color;
}

.formField {

    &-message {
        display: block;
        width: 100%;

        @include styles($formField_message_styles);
        @include stack-spacing(small);

        &-container {
            display: block;
            width: 100%;
            flex: 1 0 100%;
        }

    }

    &.is-invalid {

        .formField {

            &-message {
                @extend %error-message;
            }

        }

    }

}

    //***** Parsley.js *****//

.formValidator .parsley-errors-list {
    @extend %error-message;
}

.parsley-errors-list.filled + .formField-message,
.formField-message-container:not(:empty) + .formField-message {
    display: none !important;
}


    //***** passwordRevealer *****//

.formField {

    .js-passwordRevealer {
        position: relative;
        display: block;

        &-trigger {
            position: absolute;
            top: 0; right: nth($field_padding, 2);

            width: $field_height; height: $field_height;
        }

    }

}
