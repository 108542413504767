.modalDialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100vw;

    z-index: 100;

    @include stack-spacing(section, padding-top);

    background-color: $modalDialog_backdrop-color;

    &-container {
        @include has-limited-width();

        height: 100vh;
        height: calc(100vh - 2 * var(--sp-section));
    }

    &-box {
        @include box-padding();

        margin-left: auto;
        margin-right: auto;

        max-height: 100%;

        display: flex;
        flex-direction: column;

        background-color: $modalDialog_box-color;
        box-shadow: (0 8px 16px rgba($black, .1), 0 16px 32px rgba($black, .15));
    }

    &-close {
        position: absolute;
        @include box-padding(default, top);
        @include box-padding(default, right);
        margin-top: -.1em;

        z-index: 1;

        height: calc(var(--fs-#{$modalDialog_title_font-size}) * var(--lh-#{$modalDialog_title_font-size}));

        .closeButton {
            display: block;
            height: 100%;
        }

        + * {
            margin-top: 0 !important;
        }

    }

    &-title {
        margin-top: -.1em;

        @include font-size($modalDialog_title_font-size);
        @include line-height($modalDialog_title_font-size);
    }

    &-close + &-header &-title {
        padding-right: $icon_size + px($box-padding);
        padding-right: calc(#{$icon_size} + var(--bp));
    }

    &-content {
        @include stack-spacing();

        flex: 0 1 auto;
        overflow-y: auto;

        @include box-padding(default, margin-left, $factor: -1);
        @include box-padding(default, margin-right, $factor: -1);
        @include box-padding(default, margin-bottom, $factor: -1);

        @include box-padding(default, padding-left);
        @include box-padding(default, padding-right);
        @include box-padding(default, padding-bottom);

        > *:first-child {
            margin-top: 0;
        }

    }

    &-footer {
        position: relative;

        & > *:first-child {
            margin-top: 0 !important;
        }

        .formButtonset {
            margin-top: 0;

            &-item {
                @include box-padding(default, margin-top);
            }

        }

    }

    &.has-overflow &-box {
        padding-bottom: 0;

        position: relative;

        &::before { // Overlay
            content: "";

            display: block;
            @include box-padding(default, height);

            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;

            background-image: linear-gradient(to top, $modalDialog_box-color, rgba($modalDialog_box-color, 0));
        }

    }

    &.has-overflow &-content {
        margin-bottom: 0;
    }

    &.has-overflow &-footer {
        @include box-padding(default, padding-bottom);

        position: relative;
        z-index: 1;

        &::before, // Overlay and shadow
        &::after {
            content: "";

            display: block;
            @include box-padding(default, height, $factor: 1);

            position: absolute;
            @include box-padding(default, top, $factor: -1);
            @include box-padding(default, left, $factor: -1);
            @include box-padding(default, right, $factor: -1);
            z-index: 1;
        }

        &::before { // Overlay
            background-image: linear-gradient(to top, $modalDialog_box-color, rgba($modalDialog_box-color, 0));
        }

        &::after { // Shadow
            background-image: linear-gradient(to top, rgba($black, .1) 6.25%, rgba($black, 0) 37.5%);
        }

    }

    @include only-on-desktop(){

        @each $name, $value in $modalDialog_sizes {

            $modifier: "";

            @if $name != default {
                $modifier: '[data-size="#{$name}"]';
            }

            &#{$modifier} &-box {
                width: calc((100% + #{$grid_gutter}) * #{math.div($value, 12)});
            }

        }

    }

        //----- Animation -----//

    transition: $base_transition-duration__in;
    opacity: 1;

    &-container {
        transition: inherit;
    }

    &-box {
        transform: translateY(0);

        transition: inherit;
        transition-timing-function: ease-out;
    }

    &[aria-hidden="true"] {
        pointer-events: none;
        z-index: -1;

        background-color: rgba($black, 0);
        opacity: 0;

        transition: $base_transition-duration__out;

        .modalDialog {

            &-box {
                transform: translateY(-1 * $stack-spacing__section);
                transform: translateY(calc(-1 * var(--sp-section)));

                transition-timing-function: ease-in;
            }

        }

    }

}

html[data-modal-visible="true"] {
    max-height: 100vh;
    overflow: hidden;

    padding-right: $scrollbar-width;
    padding-right: var(--scrollbar-width);
}
