$font-size: 16px !default;
$line-height: 1.5 !default;

$font-size__large: 1.25rem !default;
$line-height__large: 1.4 !default;

$font-size__largest: 1.5rem !default;
$line-height__largest: $line-height__large !default;

$font-size__small: .875rem !default;
$line-height__small: $line-height !default;

$font-size__smallest: .75rem !default;
$line-height__smallest: $line-height__small !default;

$font-size__mobile: 16px !default;
$line-height__mobile: $line-height !default;

$font-size__large__mobile: $font-size__large !default;
$line-height__large__mobile: $line-height !default;

$font-size__largest__mobile: $font-size__largest !default;
$line-height__largest__mobile: $line-height__large__mobile !default;

$font-size__small__mobile: $font-size__small !default;
$line-height__small__mobile: $line-height !default;

$font-size__smallest__mobile: $font-size__smallest !default;
$line-height__smallest__mobile: $line-height__small__mobile !default;

$font-sizes: (
    default: (
        default: $font-size,
        smallest: $font-size__smallest,
        small: $font-size__small,
        large: $font-size__large,
        largest: $font-size__largest,
    ),
    mobile: (
        default: $font-size__mobile,
        smallest: $font-size__smallest__mobile,
        small: $font-size__small__mobile,
        large: $font-size__large__mobile,
        largest: $font-size__largest__mobile,
    ),
) !default;

$line-heights: (
    default: (
        default: $line-height,
        smallest: $line-height__smallest,
        small: $line-height__small,
        large: $line-height__large,
        largest: $line-height__largest,
    ),
    mobile: (
        default: $line-height__mobile,
        smallest: $line-height__smallest__mobile,
        small: $line-height__small__mobile,
        large: $line-height__large__mobile,
        largest: $line-height__largest__mobile,
    ),
) !default;

    // Font-faces

$font-faces_root: "/fonts/" !default;
$font-faces_formats: "ttf", "woff", "woff2" !default;

//* $font-faces
//*   List of font-faces to be imported.
//*   Every entry have to be a map with the following keys:
//*   (
//*       font-family: "Comic Sans",
//*       font-file: "comic-sans",
//*       root: "#{$font-faces_root}",
//*       font-weight: 400,
//*       font-style: normal
//*   )

$font-faces: (
) !default;

$font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;

$font-weight: 400 !default;
$font-weight__bold: 700 !default;


// Legacy variables
//   Deprecated! Use variables without prefix

$base_font-size: $font-size !default;
$base_line-height: $line-height !default;

$base_font-size__mobile: $font-size__mobile !default;
$base_line-height__mobile: $line-height__mobile !default;

$base_font-size__large: $font-size__large !default;
$base_font-size__largest: $font-size__largest !default;

$base_font-size__small: $font-size__small !default;
$base_font-size__smallest: $font-size__smallest !default;

    // Font-faces

$base_font-faces_root: $font-faces_root !default;
$base_font-faces_formats: $font-faces_formats !default;

$base_font-faces: $font-faces !default;

$base_font-family: $font-family !default;

$base_font-weight: $font-weight !default;
$base_font-weight__bold: $font-weight__bold !default;
