.gdprcookie {
    $theme: active-color;

    $text-color: get-theme-color($theme, text-color);

    $primary-color: get-theme-color($theme, primary);
    $primary-color__hover: get-theme-color($theme, primary__hover);

    $secondary-color: get-theme-color($theme, secondary);
    $secondary-color__hover: get-theme-color($theme, secondary__hover);

    $disabled-color: rgba($text-color, .4);

    @include full-width-backdrop($primary-color);
    background-color: $primary-color;

    @include stack-spacing(default, padding-top);
    @include stack-spacing(default, padding-bottom);

    @include has-limited-width();

    color: $text-color;

    position: fixed;
    bottom: 0; left: 50%;
    transform: translateX(-50%);

    z-index: 1000;

    width: 100%;
    max-height: 100vh;

    overflow-y: auto;
    overflow-x: hidden;

    strong {
        display: block;
    }

    p,
    label {
        font-size: $base_font-size__small;
    }

    label {
        font-weight: $base_font-weight__bold;
        color: $text-color !important;

        &::after {
            content: attr(title) !important;

            display: block;

            font-weight: $base_font-weight;
        }

    }

    a {
        color: $text-color;
        text-decoration-color: $text-color;

        &:hover {
            text-decoration-color: transparent;
        }

    }

    ul {
        list-style: none;
        padding: 0;
    }

    button {
        @extend .button;

        background-color: $text-color;
        color: $primary-color;

        margin-top: 0;

        &:not(a) {

            &:hover,
            &:focus,
            &:active {
                background-color: $secondary-color__hover;
                color: $primary-color;
            }

        }

        &:not(:last-child){ // Secondary

            &:not(a) {
                background-color: transparent;
                color: $text-color;
                border-color: $text-color;

                &:hover,
                &:focus,
                &:active {
                    color: $secondary-color__hover;
                    border-color: $secondary-color__hover;
                }

            }

            &[disabled] {
                background-color: transparent !important;
                color: $disabled-color !important;
                border-color: $disabled-color !important;
            }

        }

    }

    &-types {
        @include stack-spacing();
    }

    &-buttons {
        $gutter: $grid_gutter;

        display: flex;
        flex-wrap: wrap;

        margin-left: (-.5 * $gutter);
        margin-right: (-.5 * $gutter);

        .button {
            flex: 0 0 auto;

            @include stack-spacing();

            margin-left: (.5 * $gutter);
            margin-right: (.5 * $gutter);

            white-space: nowrap;

        }

    }

    .formField {
        @include stack-spacing();

        &.is-checkbox {

            .formField-input + .formField-label {

                &::before { // Marker
                    background-color: $primary-color;
                    border-color: $primary-color;
                    box-shadow: 0 0 0 $field_border-width $text-color !important;
                }

            }

            .formField {

                &-input:checked + .formField-label {

                    &::before {
                        background-color: $text-color;
                    }

                }

                &-label:hover::before {
                    background-color: $disabled-color;
                }

            }

            .formField-input:disabled + .formField-label,
            .formField-input:disabled:focus + .formField-label,
            .formField-input:disabled:active + .formField-label {
                color: $disabled-color !important;

                @include base-transition();

                &:hover {
                    color: $text-color !important;
                    @include base-transition(in);
                }

                &::before { // Marker
                    background-color: $disabled-color;
                    box-shadow: 0 0 0 $field_border-width $disabled-color !important;
                }

            }

        }

    }

    @include only-on-desktop(){

        &-intro,
        &-types {
            width: get-column-width(math.div(8, 12));
        }

    }

    @include only-on-mobile(){

        &-buttons {
            $gutter: $page_padding__mobile;

            margin-left: (-.5 * $gutter);
            margin-right: (-.5 * $gutter);

            .button {
                flex: 1 0 25%;

                margin-left: (.5 * $gutter);
                margin-right: (.5 * $gutter);
            }

        }

    }


}
