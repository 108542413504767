.featureList {
    $icon_size: 48px;

    @include stack-spacing(section);

    &-title {
        text-align: center;
    }

    &-items {
        list-style: none;
        padding: 0;
    }

    &-item {
        @include stack-spacing(component);

    }

    &.is-small &-item {
        @include stack-spacing();

        display: flex;
        column-gap: $grid_gutter;

        > * {
            @include stack-spacing(0);
        }

    }

    &-description {

        > *:first-child {
            margin-top: 0;
        }

    }

    &.is-small &-description {
        margin-top: calc(.5 * #{$icon_size} - 1em * #{$line-height});
    }

    &:not(.is-small) &-description {
        @include font-size(large);
        @include line-height(large);
        font-weight: 500;
    }

    &-icon {
        flex: 0 0 $icon_size;
    }

    .streamlineIcon {
        display: block;
        width: 100%;
        padding-bottom: 100%;

        position: relative;

        color: $ci_calming-color;

        svg {
            position: absolute;
            width: 100%;
            height: 100%;

            top: 0; left: 0;

            @include icon--set-stroke-width(2);
        }

    }

    @include not-on-desktop(){

        &:not(.is-small) &-icon {
            width: 25%;
        }

        &-icon + &-description {
            @include stack-spacing();
        }

    }

    @include only-on-mobile(){
        text-align: center;

        &-icon {
            margin-left: auto;
            margin-right: auto;
        }

        &.is-small & {

            &-item {
                column-gap: $grid_gutter__mobile;
            }

            &-description {
                @include stack-spacing(0);
                flex-grow: 1;

                text-align: left;
            }

        }

    }

    @include not-on-mobile(){
        width: 100%;

        margin-left: auto;
        margin-right: auto;

        &:not(.is-small) &-items {
            display: flex;
            flex-wrap: wrap;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            > * {
                flex: 1 1 auto;
                width: 50%;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

    }

    @include only-on-desktop(){
        $columns_span: 8;

        width: get-column-width(math.div($columns_span, 12));

        &:not(.is-small) &-item {
            display: flex;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            > * {
                flex: 1;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

        &:not(.is-small) &-icon {
            flex: 0 1 auto;
            width: (math.div(2, $columns-span) * 100%);
        }

    }

    @include only-on-small-desktop() {
        $columns_span: 10;

        &:not(.is-small) {
            width: get-column-width(math.div($columns_span, 12));
        }

        &:not(.is-small) &-icon {
            width: (math.div(2, $columns-span) * 100%);
        }

    }

}
