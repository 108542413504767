$list_item_ident: 0 !default;

$list_marker_ident: 2ch !default;
$list_marker_size: .9ch !default;
$list_marker_color: $text-color !default;

$list_marker_styles: (
    content: "",
    display: inline-block,
    width: $list_marker_size,
    height: 5px,
    margin-right: (-1 * $list_marker_size),
    position: relative,
    left: (-1 * $list_marker_ident),
    vertical-align: middle,
    top: -.1em,
    background-color: $list_marker_color,
) !default;

@import "scss/_settings";
@import "scss/_styles";

.ol, .ul {
    padding-inline-start: 0;
}

.ol .ol-li {
    $indent: $list_item_ident;
    $marker_ident: $list_marker_ident;

    padding-left: ($indent + $marker_ident);
    counter-increment: list;

    &::before {
        content: counter(list);
        font-weight: $base_font-weight__bold;
        margin-left: (-1 * $marker_ident);
    }

}

html[dir="rtl"] {

    .ol .ol-li {
        $indent: $list_item_ident;
        $marker_ident: $list_marker_ident;

        padding-left: 0;
        padding-right: ($indent + $marker_ident);

        &::before {
            margin-left: 0;
            margin-right: (-1 * $marker_ident);
        }

    }

    .ul .ul-li {
        padding-left: 0;
        padding-right: ($list_item_ident + $list_marker_ident);

        &::before {
            margin-right: 0;
            margin-left: get-style($list_marker_styles, margin-right);
            left: 0;
            right: get-style($list_marker_styles, left);
        }

    }

    .dl-data,
    .dl-dd,
    .dl > .dd {
        margin-left: auto;
        margin-right: get-style($list_data_styles, margin-left);
    }

}
