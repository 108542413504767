// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.newsletterForm.is-dynamics-365 {

    .newsletterForm-content {
       @extend %dynamics365Form-fieldset;
    }

    .dynamics365Form {
        padding: 0 !important;
        margin-top: 0 !important;

        form {
            @include box-padding(default, padding-top);

            padding-top: calc(var(--bp) - var(--sp));
        }

    }

}
