$pageHeader_cut-out_min-height: 620px !default; // Please regard: Is already set in modules
$pageHeader_cut-out_ratio: (math.div($page-content-max-width, 760px)) !default; // Please regard: Is already set in modules

.pageHeader {

    &.is-cut-out {
        margin-top: 0;
    }

    @include not-on-desktop(){

        &.is-cut-out {
            display: flex;
            flex-direction: column;

            .heroImage-figure {

                &::before {
                    display: none;
                }

                img:only-of-type,
                img.not-on-desktop {
                    display: block;
                }

            }

        }

    }

    @include only-on-desktop(){
        $min-height: $pageHeader_cut-out_min-height;
        $ratio: $pageHeader_cut-out_ratio;

        &.is-cut-out {
            //min-height: 100vh;
            //min-height: calc(100vh - #{$mainHeader_height} - #{$intrapageNav_height});

            justify-content: flex-start;

            @include color-themes((
                color: text-color,
            ));

            @include full-width-backdrop();

            &::before {

                @include color-themes((
                    background-color: primary,
                ));

            }

            &::after { // Spacer
                content: "";
                display: block;

                padding-bottom: math.div(100%, $ratio);
            }

            .heroImage-figure {
                height: 100%;

                &::before {
                    padding-bottom: 0;

                    background-position: bottom right;
                    background-size: cover;
                    background-color: transparent;
                }

            }

        }

        &.is-cut-out & {

            &-hgroup {
                @include stack-spacing(component, padding-top);
                min-height: $min-height;
            }

            &-image {
                display: block;
                width: 101vw;
                width: calc(100vw + 1px); // Fix backdrop offset on odd screen resolutions
                max-width: $page_max-width;
                height: 100%;

                padding: 0;

                position: absolute;
                top: 0; left: 50%;
                z-index: 1;

                transform: translateX(-50%);
            }

        }

        &.is-cut-out &-image {

            @include color-themes((
                background-color: primary,
            ));

        }

    }

}
