.newsletterForm {
    @include stack-spacing(component);

    form.is-hidden {
        display: none;
    }

    &-message {
        height: 0;
        overflow: hidden;

        > .wrapper {
            @include box-padding();

            background-color: $backdrop-color;
            border-left: $base_border-width $success-color solid;

            opacity: 0;
            transition: opacity $base_transition-duration__out;
        }

        &.is-visible {
            @include stack-spacing(component);
            height: auto;

            > .wrapper {
                opacity: 1;
                transition: opacity $base_transition-duration__in;
            }

        }

        &.is-error {

            > .wrapper {
                border-left: $base_border-width $error-color solid;
            }

        }

        * + * {
            @include stack-spacing(small);
        }

        & > *:first-child {
            margin-top: -.2em;
        }

        & > *:last-child {
            margin-bottom: -.4em;
        }

    }

        //***** Add spacer for scroll fully into view

    &[id] {
        $offset-max: $mainHeader_height + $intrapageNav_height;

        padding-top: $offset-max;
        padding-top: var(--mainHeader-height);

        margin-top: (-1 * $offset-max);
        margin-top: calc(-1 * var(--mainHeader-height));
    }

}
