.teaserCard {
    $title_font-size: rem(64px);
    $title_line-height: 1.25;

    &.is-wallpaper {
        $indent: get-column-indent();

        @include stack-spacing(component, padding-top);
        @include stack-spacing(component, padding-bottom);

        padding-left: $indent;
        padding-right: $indent;

        background-color: $backdrop-color;
        max-width: none;

        position: relative;
        z-index: 1;

        @include color-themes((
            background-color: primary,
            color: text-color,
        ));

        .figure {
            height: 100%;

            &::before {
                height: 100% !important;
                padding-bottom: 0 !important;
            }

            &-image-container {
                height: 100%;
            }

            img {
                height: 100%;
                aspect-ratio: auto !important;
            }

        }

        &.has-image {
            color: $white;

            .button {
                background-color: $white;
                color: $ui-color;

                &:hover {
                    //color: $white;
                    background-color: $ui-color__hover;
                }

                &.is-secondary {
                    background-color: transparent;
                    color: $white;
                    border-color: $white;

                    &:hover {
                        color: $ui-color__hover;
                        border-color: $ui-color__hover;
                    }

                }

            }

        }

    }

    &.is-wallpaper & {

        &-image {
            position: absolute;
            top: 0; left: 0;

            z-index: -1;

            width: 100%;
            height: 100%;

            overflow: hidden;

            &::after {
                content: "";

                display: block;
                width: 100%; height: 100%;

                position: absolute;
                top: 0; left: 0;

                background-color: rgba(black, .2);
                mix-blend-mode: multiply;
            }

        }

        &-header {
            padding-top: 0;
        }

        &-topic {
            @include reset-topic();
        }

        &-content {
            font-weight: $base_font-weight__medium;
        }

        &-cta {
            @include stack-spacing(component);
        }

        @include only-on-desktop(){

            &-title,
            &-content {
                width: get-column-width(math.div(6, 10));
            }

            &-topic {
                font-size: $base_font-size__large;
            }

            &-title {
                font-size: $title_font-size;
                line-height: $title_line-height;
            }

            &-content {
                font-size: $base_font-size__large;
            }

        }

        @include only-on-small-desktop(){

            &-title,
            &-content {
                width: get-column-width(math.div(8, 10));
            }

        }

        @include not-on-desktop(){
            $title_font-size: $teaserCard_title_font-size__mobile;
            $title_line-height: $teaserCard_title_line-height__mobile;

            &-title {
                font-size: $title_font-size;
                line-height: $title_line-height;
            }

            &-content {
                margin-top: $stack-spacing__component__mobile;
            }

            &-cta {
                margin-top: $stack-spacing__mobile + $stack-spacing__component__mobile;
            }

        }

    }

    .teaserCardGroup.is-large + &.is-wallpaper {
        margin-top: $grid_gutter;
    }

        // Set minimum height

    @include only-on-desktop(){
        $ratio: math.div(20, 9);

        &.is-wallpaper {
            display: flex;
            align-items: flex-start;

            &::before { // Spacer for proportional minimum height
                content: "";

                display: block;
                width: 0; padding-bottom: math.div(100%, $ratio);
            }

        }

    }

}
