@import "_link.settings.vetro";

$link-color: $text-color !default;
$link-color__hover: $text-color !default;
$link-color__active: color-state($ui-colors, active) !default;

$link_text-decoration-color: $ci_impulse-color !default;
$link_text-decoration-color__hover: transparent !default;

    //* FIXME: The property text-decoration-color is not widly supported
    //*   This cause that on unsupported browsers the user will not see any
    //*   changes when hovering a link.
    //*   Maybe https://css-tricks.com/styling-underlines-web/ gives a solution.

$link-states: (
    default: (
        color: $link-color,
        text-decoration: underline,
        text-decoration-color: $link_text-decoration-color,
        text-decoration-thickness: 2px,
        text-underline-offset: 3px,
        text-decoration-skip-ink: auto,
        transition: $base_transition-duration__out,
        transition-properties: color text-decoration-color,
    ),
    hover: (
        color: $link-color__hover,
        text-decoration-color: $link_text-decoration-color__hover,
        transition-duration: $base_transition-duration__in,
    ),
    active: (
        color: $link-color__active,
    )
) !default;

@import "scss/_settings";
@import "scss/_styles";

%external-link {

    &::after {
        content: "\00a0\2197";
        display: inline;
    }

}

%link {
    text-decoration-skip-ink: auto;
    text-decoration-skip-ink: auto;

    @include color-themes((
        color: text-color,
        text-decoration-color: accent-color,
    ));

    &:hover {

        @include color-themes((
            text-decoration-color: transparent,
        ));

    }

    &[href^="mailto"] {
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: $link_text-decoration-color;
        }
    }

    &[href^="http"]:not([href*="vitronic."]):not([href*="vetro-gmbh."]):not([href*="prismic.io"]) {
        @extend %external-link;
    }

    @include only-on-desktop(){

        &[href^="phone"] {
            color: $text-color !important;
            text-decoration: none;
            cursor: default;
        }

    }

}

h1, h2, h3, h4, h5, h6 {

    a[href^="http"] {

        &::after {
            display: none;
        }

    }

}

    //***** Emphasised *****//

%link--empasised {
    font-weight: $base_font-weight__medium;
    text-decoration: none !important;

    display: inline-block;
    white-space: nowrap;

    margin-bottom: $base_border-width;

    transition-property: color, border-color;

    position: relative;

    user-select: none;

    & {
        @include color-themes((
            color: text-color
        ));
    }

    &:hover {

        @include color-themes((
            color: text-color
        ));

    }

    &::before { // Line
        content: "";

        display: block;
        width: 100%; height: 0;

        position: absolute;
        bottom: (-1 * $base_border-width); left: 0;
        z-index: -1;

        transform: scaleX(1);
        transform-origin: bottom left;

        border-bottom: $base_border-width solid $ui-color__hover;

        transition-duration: inherit;
        transition-property: transform;

        pointer-events: none;

        @include color-themes((
            border-bottom-color: accent-color,
        ));

    }

    &:hover {
        color: $ui-color;

        &::before { // Line
            transform: scaleX(0);
            transform-origin: bottom right;
        }

    }
}

.link.is-emphasised {
    @extend %link--empasised;
}

    //***** Google Tag Manager *****//

a * {
    pointer-events: none;
}

    //***** Right to left *****//

html[dir="rtl"] {

    a[href^="http"] {

        &::after {
            margin-left: 0;
            margin-right: .2em;

            transform: scaleX(-1);
        }

    }

}
