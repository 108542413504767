@mixin affinityLinkBar-option-marker(
    $border-colors: define-color-states(
        transparent,
        $ui-color__hover,
        $ui-color__active
    ),
    $border-width: $base_border-width,
){
    display: inline-block;

    padding-bottom: $border-width;
    position: relative;

    &::before {
        content: "";

        display: block;
        width: 100%;

        position: absolute;
        bottom: 0;

        border-bottom: $border-width solid color-state($border-colors);

        @include base-transition();
        transition-property: border-color;
    }

    &:hover::before {
        border-color: color-state($border-colors, hover);
        @include base-transition(in);
    }

    &:active::before {
        border-color: color-state($border-colors, active);
    }

}

.affinityLinkBar {
    $gutter: $grid_gutter;

    $border-width: $base_border-width;
    $options_padding: (.5 * $grid_gutter) nth($field_padding, 2);

    $icon: get-svg-data-url('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M6 10L12 15L18 10"/></svg>');

    @include stack-spacing(section);

    position: relative;
    z-index: 1;

    user-select: none;

    @include stack-spacing(default, padding-top);
    @include stack-spacing(default, padding-bottom);

    @include full-width-backdrop(rgba(55, 70, 80, .18));

    &-list {

        > .wrapper {
            position: relative;
        }

        &-topic {
            @extend .label;

            margin-top: 0;
            margin-bottom: .2rem;

        }

        &-label {
            white-space: nowrap;
            display: block;

            padding: $field_padding;

            background-color: $page-color;
            border-bottom: $border-width solid $ui-color;

            &.is-placeholder {
                color: $disabled-color;
            }

            &::after {
                content: "";

                display: inline-block;
                width: 1em; height: 1em;

                vertical-align: middle;
                margin-top: -.1em;

                margin-left: .25em;

                background: url($icon) center center no-repeat;
            }

        }

    }

    &-options {
        list-style: none;
        padding: 0;
        margin: 0;

        display: block;
        position: absolute;
        left: 0;

        min-width: 100%;

        opacity: 0;
        pointer-events: none;
        z-index: -1;

        color: $white;

        transition: all $base_transition-duration__out, z-index 0s $base_transition-duration__out;

        padding: $border-width nth($field_padding, 2) 0;

        &::before { // Backdrop
            content: "";

            display: block;
            width: auto; height: 0;

            position: absolute;
            top: (-1 * $border-width);
            left: 0;
            right: 0;
            z-index: -1;

            background: $ci_leading-color;
            border-top: $border-width solid $ui-color__hover;
            transition: inherit;
        }

    }

    &-list > .wrapper:hover &-options,
    &-list > .wrapper:focus &-options {
        opacity: 1;
        pointer-events: all;
        z-index: 1;

        transition: all $base_transition-duration__in, z-index 0s;

        &::before { // Backdrop
            height: 100%;
        }

    }

    &-option {
        @include stack-spacing(small);

        font-size: $base_font-size__small;
        line-height: 1.2;

        padding-top: .2em;

        &:last-child {
            margin-bottom: nth($options_padding, 1);
        }

        &-label {

            &,
            &:hover {
                color: $white;
            }

            cursor: pointer;
            white-space: nowrap;
            text-decoration: none;

            @include affinityLinkBar-option-marker($border-width: $border-width);
        }

        input {
            display: none;

            &:checked + label::before {
                border-color: rgba($text-color, .85);
            }

        }

    }

    &-links {
        list-style: none;

            // Set on same height as lists
        padding: nth($field_padding, 1) 0 nth($field_padding, 3);
        border-bottom: $border-width solid transparent;
    }

    &-link a {
        @extend %link--empasised;
    }

    @include only-on-mobile(){

    	&-list {

        	& + & {
            	margin-top: $stack-spacing__mobile;
        	}

            &-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

    	}

    }

    @include not-on-mobile(){

        & > .wrapper {

            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            margin-left: (-.5 * $gutter);
            margin-right: (-.5 * $gutter);

            > * {
                padding-left: (.5 * $gutter);
                padding-right: (.5 * $gutter);
            }

        }

        &-lists {
            display: flex;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            > * {
                flex: 1 0 auto;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

        &-links {
            display: flex;
            margin: 0 (-.5 * $grid_gutter);

            > * {
                flex: 1 0 auto;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .affinityLinkBar {

        &-list {

            &-label {

                &::after {
                    margin-left: 0;
                    margin-right: .25em;
                }

            }

        }

    }

}
