.timeTable {
    @include stack-spacing(component);

    border-collapse: collapse;

    width: 100%;

    th, td {
        padding: 0;
        vertical-align: top;
        text-align: left;
        vertical-align: top;
    }

    &-caption {
        @include stack-spacing(default, margin-bottom);

        font-weight: $font-weight__bold;
        line-height: 1.3;
        text-align: left;

        span {
            display: inline-block;

            border-bottom: $text-color $base_border-width solid;
        }

    }

    &-entry > *:not(:first-child) {
        padding-left: .85em;
    }

    &-time {
        font-weight: $font-weight__medium;
        font-variant-numeric: tabular-nums;

        white-space: nowrap;
        width: 1%;
    }

}

html[dir="rtl"] {

    .timeTable {

        &-entry > *:not(:first-child) {
            padding-left: 0;
            padding-right: 1em;
        }

    }

}
