    //***************************//
    //***** Unit conversion *****//
    //***************************//

@function rem($size, $base: $font-size){

    @if (unit($size) == "rem"){
        @return $size;
    }

    @if (unit($size) == "em"){
        @return 1rem/1em * em($size, $base);
    }

    @if (unit($size) == "px"){
        @return math.div(round(1000rem * math.div($size, $base)), 1000);
    }

    @return $size;

}

@function em($size, $base: $font-size){

    @if (unit($size) == "em"){
        @return $size;
    }

    @if (unit($size) == "px"){

        @if (unit($base) == "rem"){
            $base: math.div($base * $font-size,1rem);
        }

        @return math.div(round(1000em * math.div($size, $base)), 1000);
    }

    @return $size;
}

@function px(
    $size,
    $base: $font-size,
    $round: true,
){

    @if (unit($size) == "px"){
        @return $size;
    }

    @if (unit($size) == "em"){
        $size: math.div($base * $size, 1em);
    }

    @if (unit($size) == "rem"){
        $size: math.div($base * $size, 1rem);
    }

    @if ($round) {
        @return round($size);
    }

    @return $size;

}

@function strip-units($number) {
    @return $number/(0 * $number + 1);
}

    //****************************//
    //***** Calculate height *****//
    //****************************//

@function get-outer-height(
    $font-size: 1rem, // rem
    $line-height: $base_line-height,
    $padding: 0,     // em
    $border: 0,      // px
    $base: $base_font-size,
){

    $font-size: px($font-size, $base, $round: false);
    $height: $font-size * $line-height;

    @if $padding != 0 {

        @if type-of($padding) != "list" {
            $padding: (2 * $padding);
        } @else {

            @if length($padding) < 3 {
                $padding: (2 * nth($padding, 1));
            } @else {
                $padding: (nth($padding, 1) + nth($padding, 3));
            }

        }

        $height: ($height + math.div($padding,1em) * $font-size);

    }

    @if $border != 0 {

        @if type-of($border) != "list" {
            $border: (2 * $border);
        } @else {

            @if length($border) < 3 {
                $border: (2 * nth($border, 1));
            } @else {
                $border: (nth($border, 1) + nth($border, 3));
            }

        }

        $height: $height + $border;

    }

    @return $height;

}


    //*********************************************//
    //***** Organising element styles in maps *****//
    //*********************************************//

@mixin styles (
    $declarations: (),
    $properties...
){

    @if length($properties) > 0 {

        @each $property in $properties {

            @if map-has-key($declarations, $property) {

                $declaration: (
                    $property: map-get($declarations, $property),
                );

                @include styles($declaration);

            }

        }

    } @else {

        @each $property, $value in $declarations {

                // If value is a list with two items and second item contains a CSS variable,
                //   split value into two declarations, e.g.:
                //   Value:  `margin-top: 1rem, var(--sp)`
                //   Output: `margin-top: 1rem; margin-top: var(--sp);`

            @if (type-of($value) == "list") and (list-separator($value) == comma) and (length($value) == 2) {
                $fallback: nth($value, 1);
                $css_variable: nth($value, 2);

                @if (type-of($css_variable) == "string") and (str-index($css_variable, 'var(--') != null ) {
                    #{$property}: $fallback;
                    $value: $css_variable;
                }

            }

            #{$property}: $value;

        }

    }

}

@function get-style (
    $declarations: (),
    $property: null,
){

    @if map-has-key($declarations, $property) {
        @return map-get($declarations, $property);
    } @else {
        @return null;
    }

}

//* Handlers to organise styles in collections
//*
//* Define collection:
//*
//*   $collection: (
//*       default: {
//*           color: black,
//*           font-weight: normal
//*       },
//*       highlighted: {
//*           color: red,
//*           font-weight: bold
//*       }
//*   );
//*
//* Include all styles of an item:
//*
//*   @include state-styles($collection, highlighted);
//*
//* Include selected styles of an item:
//*
//*   @include state-styles($collection, highlighted, font-weight);

@function get-state-style (
    $collection: (),
    $key: default,
    $property: "",
){

    $styles: get-state-styles($collection, $key, $property);

    @return get-style($styles, $property);

}


@function get-state-styles (
    $collection: (),
    $key: default,
    $properties...
){

    @if map-has-key($collection, $key) == false {
        // @debug "Warning in @function get-styles: Key `#{$key}` was not found in collection!";
        @return (null: null);
    }

    $declarations: map-get($collection, $key);


        // Return all style declarations

    @if length($properties) == 0 or length(nth($properties, 1)) == 0 {
        @return $declarations;
    }


        // Return selected style declarations

    $selected-declarations: ();

    @each $property in $properties {

        $property: quote($property); // Convert variable to string (to fix type conflict)

        @if map-has-key($declarations, $property) == false {

            //@debug "Warning in @function get-styles: Style property `#{$property}` was not found!";
            @return (null: null);

        } @else {

            $declaration: (
                #{$property}: map-get($declarations, $property)
            );

            $selected-declarations: map-merge($selected-declarations, $declaration);

        }

    }

    @return $selected-declarations;

}

@mixin state-styles (
    $collection: (),
    $key: default,
    $properties...
){

    $declarations: get-state-styles($collection, $key, $properties...);

    @each $property, $value in $declarations {
        #{$property}: $value;
    }

}

    //*******************************************//
    //***** Organising responsive variables *****//
    //*******************************************//

@mixin responsive-variables(
    $set: (),
    $prefix: "--sp"
){

    @each $device, $definitions in $set {

        $variables: ();

        @each $suffix, $value in $definitions {

            $name: #{$prefix};

            @if ($suffix != default){
                $name: #{$name}-#{$suffix};
            }

            $variables: join($variables, (#{$name}: $value));

        }

        @if ($device == mobile){

            @include not-on-desktop(){
                @each $name, $value in $variables {
                    #{$name}: $value;
                }
            }

        }

        @if ($device == tablet){

            @include only-on-tablet(){
                @each $name, $value in $variables {
                    #{$name}: $value;
                }
            }

        }

        @if ($device == small-desktop){

            @include only-on-small-desktop(){
                @each $name, $value in $variables {
                    #{$name}: $value;
                }
            }

        }

        @if ($device == default){
            @each $name, $value in $variables {
                #{$name}: $value;
            }
        }

    }

}

@mixin responsive-variable(
    $type: default,
    $property: none,
    $variables: (),
    $factor: 1,
    $prefix: "",
){

    @if (type-of($property) == "list") {

        $properties: $property;

        @each $property in $properties {

            @include responsive-variable(
                $type: $type,
                $property: $property,
                $variables: $variables,
                $factor: $factor,
                $prefix: $prefix,
            );

        }

    } @else {


            // Get fallback value

        $fallback_value: null;

        @if map-has-key($variables, default){
            $variables: map-get($variables, default);

            @if map-has-key($variables, $type){
                $fallback_value: map-get($variables, $type);
            }
        }

            // Get variable name

        $variable-name: get-variable-name($type, $prefix);

            // Set property to fallback and variable

        @if $variable-name and $property {

            @if ($factor != 1) {

                @if ($fallback_value){
                    #{$property}: calc(#{$factor} * #{$fallback_value});
                }

                #{$property}: calc(#{$factor} * var(#{$variable-name}));

            } @else {

                @if ($fallback_value){
                    #{$property}: $fallback_value;
                }

                #{$property}: var(#{$variable-name});

            }

        }

    }

}

@function get-variable-name(
    $type: default,
    $prefix: "",
){
    // Get variable name

    $variable-name: null;

    @if ($prefix){

        $variable-name: #{$prefix};

        @if ($type != default){
            $variable-name: #{$variable-name}-#{$type};
        }

    }

    @return $variable-name;

}


//**********************************//
//***** Encode SVG as data URL *****//
//**********************************//

@function svg-url(
    $svg: "",
    $color: $text-color,
){
    $data: get-svg-data-url($svg, $color);
    @return url($data);
}

@function get-svg-data-url(
    $svg: "",
    $color: $text-color,
){
        /*
         * Format SVG code as data URI
         *   Also masks hashes to fix missing SVGs in Firefox
         */

    $svg: str-replace($svg, "{{color}}", "#{$color}");

    $svg: str-replace($svg, '    ', "");
    $svg: str-replace($svg, '\a', "");
    $svg: str-replace($svg, "#", "%23");
    $svg: str-replace($svg, "<", "%3C");
    $svg: str-replace($svg, ">", "%3E");
    $svg: str-replace($svg, "'", "%27");
    $svg: str-replace($svg, '"', "%22");

    @return ('data:image/svg+xml;charset=utf8,' + $svg);
}

//****************************//
//***** Global functions *****//
//****************************//

    //***** Replace strings *****//

@function str-replace($string, $search, $replace: '') {

        /*
         * Replace `$search` with `$replace` in `$string`
         * @author Hugo Giraudel
         */

    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;

}

    //***** Recursive map merge *****//

@function map-merge-recursive($parent-map, $child-map) {
    $result: $parent-map;
    @each $key, $value in $child-map {
        @if (not map-has-key($result, $key)) or (type-of(map-get($result, $key)) != type-of($value)) or (not (type-of(map-get($result, $key)) == map and type-of($value) == map)) {
            $result: map-merge($result, ($key: $value));
        }
        @else {
            $result: map-merge($result, ($key: map-merge-recursive(map-get($result, $key), $value)));
        }
    }
    @return $result;
}
