$intrapageNav_height: 74px !default;
$intrapageNav_height__mobile: 56px !default;

$intrapageNav_gutter: $grid_gutter !default;

$intrapageNav_svgs: (
    sublevel: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M6 10L12 15L18 10"/></svg>',
);

.intrapageNav {
    $font-size: rem(17px);
    $font-size__mobile: 14px;

    $border-width: 2px;

    $gutter: $intrapageNav_gutter;

    @include stack-spacing(section);

    color: $white !important;

    > .wrapper {
        min-height: $intrapageNav_height;
        @include has-limited-width();

        font-size: $font-size;
        font-weight: $base_font-weight__medium;

        @include full-width-backdrop($text-color);

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        column-gap: $gutter;

        > *:last-child:not(:first-child) {
            text-align: right;
        }

    }

    &.without-title > .wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    &-spacer {
        display: block;
        height: $intrapageNav_height;
        width: 0;

        @include stack-spacing(section);

        position: absolute;
        z-index: -1;
    }

    &-title {
        font-size: inherit;
        font-weight: inherit;

        text-transform: uppercase;

        position: relative;
        top: .125em;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-list {
        list-style: none;
        padding: 0;
    }

    &-item {
        @extend .mainNav-item;
        font-size: inherit;
    }

    &-link {
        @extend .mainNav-link;

        color: $white !important;

        &::before {
            border-width: $border-width;
        }

    }

    &-item.is-current &-link {

        &::before {
            border-color: $white;
            border-width: $border-width;
        }

        &:hover::before {
            border-color: $ui-color__hover;
        }

    }

    &-cta {

        &-link {
            $padding: .2em 1.4em;

            margin-top: 0;
            padding: $padding;

            min-height: 0;

            font-size: inherit;

            > .icon,
            > .streamlineIcon {
                margin-top: (-.5 * $icon_size);
                margin-bottom: (-.5 * $icon_size);
            }

        }


    }

    @include only-on-desktop(){

        > .wrapper {
            height: ($intrapageNav_height - 1); // Fix IE11 bug with min-height and flexbox
        }

        &-dropdown-toggle {
            display: none;
        }

        &-list {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: $gutter;

            margin: 0;
        }

        &-nav:first-child &-list {
            justify-content: flex-start;
        }

        &-item {
            padding: 0;
        }

        &-link {
            margin: 0 (-.25 * $mainNav_gutter);
        }

    }

    @include not-on-desktop(){
        $font-size: $font-size__mobile;

        $list_padding: (.75 * $grid_gutter);

        $button_padding: .4em 1em;
        $button_line-height: 1.3;
        $button_border-width: 2px;
            // Center label

        $button_padding: (nth($button_padding, 1) + .1em) nth($button_padding, 2) (nth($button_padding, 1) - .1em);

        font-size: rem($font-size, $base_font-size__mobile);

        > .wrapper {
            padding-top: 0;
            padding-bottom: (.5 * $page_padding__mobile);

            > * {
                margin-top: (.5 * $page_padding__mobile);
            }
        }

        &-dropdown {
            position: relative;

            &-toggle {
                $icon_size: 24px;
                $padding: .4em 1em;

                display: block;

                padding: $button_padding;
                padding-right: (nth($button_padding, 2) + (math.div(math.div(1em * 3, 5) * $icon-size, $font-size--mobile)));
                border: $button_border-width solid $white;

                line-height: $button_line-height;
                border: $button_border-width solid $white;

                cursor: pointer;

                position: relative;

                &::after {
                    content: "";

                    $icon_data: get-icon-data-url(sublevel, $color: $white, $svgs: $intrapageNav_svgs);
                    background: url($icon_data) center center no-repeat;

                    display: block;
                    width: $icon_size; height: $icon_size;

                    position: absolute;
                    top: 50%; right: nth($padding, 2);

                    transform: translate(45%, -50%);
                    margin-top: -.1em;
                }

            }

        }

        &-list {
            position: absolute;
            right: 0;

            min-width: 100%;

            padding: $list_padding ($list_padding - .25 * $mainNav_gutter);
            margin: (-1 * 2px) 0 0;

            opacity: 0;
            pointer-events: none;
            z-index: -1;

            transition: all $base_transition-duration__out, z-index 0s $base_transition-duration__out;

            &::before { // Backdrop
                content: "";

                display: block;
                width: 100%; height: 0;

                position: absolute;
                top: 0; left: 0;

                background: $page-color;
                box-shadow: 0 2px 3px rgba($black, .3);

                transition: inherit;
            }

        }

        &-nav.is-visible &-list {
            opacity: 1;
            pointer-events: all;
            z-index: 1;
            transition: all $base_transition-duration__in, z-index 0s;

            &::before { // Backdrop
                height: 100%;
            }
        }

        &-item {
            padding: 0;
            position: relative;

            &:first-child {
                margin-top: (-.2 * $list_padding);
            }

            &:not(:first-child) {
                margin-top: (1.2 * $list_padding);
            }

            &:last-child {
                margin-bottom: (-.2 * $list_padding);
            }

        }

        &-link {
            color: $text-color !important;
            display: inline-block;

            padding: 0;

            white-space: nowrap;
        }

        &-cta {

            &-link {
                display: block;

                line-height: $button_line-height;

                padding: $button_padding;
                padding-right: (math.div(1, 3) * nth($button_padding, 2));

                border-width: $button_border-width;
            }

        }

    }

    @include only-on-mobile(){

        > .wrapper {
            display: flex;
            column-gap: $page_padding__mobile;
        }

        &-title {
            display: none;
        }

        &-nav {
            flex: 0 1 calc(50% - #{.5 * $page_padding__mobile});

        }

        &-cta {
            flex: 1 0 auto;
        }

        &-list {
            right: auto;
            left: 0;
        }

    }

        //** Stickiness **//

    &.js-makeSticky {
        width: 100vw;
        max-width: 100vw;

        position: relative;
        left: 50%;
        transform: translateX(-50%);

        &.is-stuck {
            position: fixed;
            margin-top: 0;
        }

        &.is-stuck + .intrapageNav-spacer {
            position: static;
        }

    }

    @include not-on-desktop(){

        > .wrapper {
            min-height: $intrapageNav_height__mobile;
        }

        &-spacer {
            display: block;
            min-height: $intrapageNav_height__mobile;
        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .intrapageNav {

        &-cta-link {

            .icon.is-proceed {
                margin-left: 0;
                margin-right: .3em;
                transform: scaleX(-1);
            }

        }

    }

    .intrapageNav.js-makeSticky {
        left: auto;
        right: 50%;
        transform: translateX(50%);
    }

}
