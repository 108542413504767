%icon {
    display: inline-block;
    width: $icon_size; height: $icon_size;

    vertical-align: middle;

    position: relative;
    top: -.09em;
}

.icon {
    @extend %icon;
}

    // Add icon to element by using class

.icon:not(svg) {

        // Center icon vertically

    height: 0;

    &::after {
        content: "";

        display: block;
        width: 100%; height: $icon_size;

        position: absolute;
        top: 0; left: 0;

        transform: translateY(-50%);
    }

    @each $key, $value in $icon_svgs {

        &.is-#{$key} {

            &::after {
                $icon_data: get-icon-data-url($key);
                background: url($icon_data) center center no-repeat;
            }

        }

    }

}
