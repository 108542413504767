$diagram_bar_height: 16px !default;
$diagram_bar_height__mobile: (.75 * $diagram_bar_height) !default;

$diagram_bar_colors: (
    $ci_calming-color,
    $ci_leading-color,
    $ci_active-color,
    $ci_structure-color,
) !default;

.diagram {
    @include stack-spacing();

    tbody {
        margin-top: 0;
    }

    &-data {
        display: block;
        border: none;
        padding: 0;
        border-collapse: collapse;

        margin-top: 0;

        * {
            display: block;
        }

    }

    &-record {
        position: relative;
        background-color: $backdrop-color;

        & + & {
            @include stack-spacing(small);
        }

        &-label {
            @extend .label;

            background-color: $page-color;
            text-align: left;
        }

        &:first-child &-label {
            margin-top: 0;
        }

        &-value {
            height: $diagram_bar_height;

            text-indent: 100%;
            overflow: hidden;
            white-space: nowrap;

            transform-origin: center left;
            background-color: $gray;
        }

        $interval: length($diagram_bar_colors);

        @each $color in $diagram_bar_colors {
            $index: index($diagram_bar_colors, $color);

            &:nth-child(#{$interval}n+#{$index}) &-value {
                background-color: $color;
            }

        }

    }

    @include not-on-desktop(){

        &-record {

            &-value {
                height: $diagram_bar_height__mobile;
            }

        }

    }

}

    //***** Animation *****//

%diagram-animated {
    $duration: .7s;

    .diagram-record {
        position: relative;

        transition: $duration ease-in-out;

        &::after { // Cover bar
            content: "";

            display: block;
            width: 100%; height: $diagram_bar_height;

            position: absolute;
            bottom: 0; right: 0;

            background-color: $backdrop-color__opaque;
            transition: inherit;
        }

    }

}

%diagram-revealed {

    .diagram-record {

        &::after {
            width: 0;
        }

    }

}

.diagram {

    &.is-animated {
        @extend %diagram-animated;
    }

    &.is-animated.is-revealed {
        @extend %diagram-revealed;
    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .diagram {

        &-record {

            &-label {
                text-align: right;
            }

            &-value {
                transform-origin: center right;
            }

        }

    }

    .diagram.is-animated .diagram {

        &-record {

            &::after { // Cover bar
                right: auto;
                left: 0;
            }

        }

    }

}
