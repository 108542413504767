// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

    // Quickfix: Hide caption

.heroImage {

    &-caption {
        display: none;
    }

}
