%formField-textarea {
    @extend %field;

    min-width: 100%;
    max-width: 100%;

    display: block;
    width: 100%;
    max-width: 100%;

    min-height: ($formField_textarea_lines * 1em * $field_line-height + 2 * nth($field_padding, 1));
}

.formField-textarea {
    @extend %formField-textarea;
}
