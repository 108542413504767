.formInsert {

    @include only-on-desktop(){
        width: get-column-width(math.div(8, 12));
    }

}

//***** Dynamics 365 forms *****//

.formInsert.is-dynamics-365 {

    .formInsert-content {
       @extend %dynamics365Form-fieldset;
    }

    .dynamics365Form {
        padding: 0 !important;
    }

}
