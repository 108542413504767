$mainSection_alternating-style: false !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

.pageHeader + .mainSection {
    margin-top: ($stack-spacing__section + $stack-spacing__component);

    @include not-on-desktop(){
        margin-top: ($stack-spacing__section__mobile + $stack-spacing__component__mobile);
    }

}

.mainSection {

    &-leadtext {
        @include stack-spacing();

        & + * {
            margin-top: $stack-spacing__component !important;
            margin-top: var(--sp-component) !important;
        }

        strong {
            font-weight: $base_font-weight;
        }

    }

    &-title + .accordionInsert,
    &-title + .highlightInsert {
        @include stack-spacing(component);
    }

}

.mainSection {

    &.is-centered & {

        &-title,
        &-leadtext {
            text-align: center;
        }

        &-leadtext {

            @include only-on-desktop(){
                width: get-column-width(math.div(8, 12));
                margin-left: auto;
                margin-right: auto;
            }

        }

    }

}

.mainSection {

    &.is-emphasised .mainSection-title {
        text-transform: uppercase;
        letter-spacing: .025em;
    }

}

.mainSection > .leadText:first-child {
    margin-top: 0;
}

    //***** Limited width *****//

@mixin mainSection--limited-width(
    $max-width: get-column-width(math.div(8, 12)),
){

    @include only-on-desktop(){
        max-width: $max-width;
        padding-left: 0; padding-right: 0;

        margin-left: auto; margin-right: auto;

        .contentBlock-column {
            max-width: none;
        }

    }

}

%mainSection--limited-width {
    @include mainSection--limited-width();
}

.mainSection.has-limited-width {
    @extend %mainSection--limited-width;
}
