.downloadList {
    @include stack-spacing(component);

    &-filter + &-item {
        @include stack-spacing();
    }

    .filterPanel {
        margin-top: 0;
    }

}
