%formField-select {
    $marker_size: $formField_select_markersize;

    display: block;
    width: 100%;

    position: relative;

    @include styles($field_styles, background-color);

    @include base-transition();

    &::after { // Marker
        content: "";

        position: absolute;
        top: 50%; right: 0;

        transform: translateY(-50%);

        display: block;
        height: $field_height; width: $marker_size;

        pointer-events: none;

        @include state-styles($formField_select_marker_states);

        transition: inherit;
    }

    &:hover {
        @include base-transition(in);

        select {
            border-color: $ui-color__hover;
            transition: inherit;
        }

        &::after {
            @include state-styles($formField_select_marker_states, hover);
        }

    }

    &:focus-within {

        &::after {
            @include state-styles($formField_select_marker_states, focus);
        }

    }

    select {
        @extend %formField-select-input;
    }

}

%formField-select-input {
    $marker_size: $formField_select_markersize;

    margin-bottom: 0;

    font-family: inherit;
    font-size: inherit;

    line-height: inherit;

    width: 100%;
    padding: $field_padding;
    padding-right: ($marker_size + .4em);

    border-color: $field_border-color;
    border-width: $field_border-width;
    border-style: $field_border-style;

    background-color: inherit;
    color: inherit;

    outline: 0;
    border-radius: 0;

    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;

    @include base-transition();

    &:focus {
        @include state-styles($field_states, focus);
        outline-width: 0;

        @include base-transition(in);
    }

        // Mozilla Hack
    text-indent: .01px;
    text-overflow: "";

        // IE10 Hack
    &::-ms-expand {
        display: none;
    }

}

%formField-multipleSelect {
    @extend %formField-select;

    &::after { // Marker
        display: none;
    }

    select {
        padding: math.div(4 * nth($field_padding, 2), 5) nth($field_padding, 2);
    }

    option + option {
        margin-top: .2em;
    }

}

.formField {

    &-select {
        @extend %formField-select;

        &.is-multiple {
            @extend %formField-multipleSelect;
        }

    }

}
