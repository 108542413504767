@mixin mainSection-with-backdrop(){
    margin-top: 0;
    margin-bottom: 0;

    @include stack-spacing(section, padding-top);
    @include stack-spacing(section, padding-bottom);

    @include full-width-backdrop();

    & > *:last-child {
        margin-bottom: 0;
    }

}

@mixin mainSection-without-backdrop(){
    @include stack-spacing(section);

    padding-top: 0;
    padding-bottom: 0;

    &::before {
        display: none;
    }

}
