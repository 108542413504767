.is-eventOnline {
    @extend %pageLayout__narrow;

    .mainSection {

        &-title {
            text-align: center;
        }

    }

    .mainSection-title + .infographicInsert {
        @include stack-spacing(component);
    }

}
