%spotlightTeaser--alternation {

    @include only-on-desktop(){

        .spotlightTeaser {

            &-figures {
                margin-left: get-column-indent();
            }

            &-content {
                order: 1;
                margin-left: $grid_gutter;
            }

        }

    }

}

.spotlightTeaser {
    @include stack-spacing(section);

    &-image {

        .fixedRatioFigure {
            @include stack-spacing(0);
            @include fixedRatioFigure-set-ratio(1);
        }

    }

    &-image + &-video {
        @include stack-spacing(component);
    }

    .videoInsert {
        @include stack-spacing(0);
    }

    &-content {
        @include stack-spacing(component);

        > *:first-child {
            @include stack-spacing(0);
        }

    }

    &-title {
        display: block;

        font-size: rem(50px);
        line-height: 1.3;

        &:first-child {
            margin-top: -.25em;
        }

    }

    &-subtitle {
        @include stack-spacing(0);

        @include font-size(large);
        font-weight: $font-weight__medium;
    }

    .keyFactList {

        &-fact {
            font-size: rem(40px);
            font-weight: $font-weight__medium;
        }

        &.is-side-by-side {
            justify-content: left;

            .keyFactList-item {
                width: 50%;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

        }

    }

    .navigationLink {
        @include stack-spacing(component);
    }

    @include only-on-desktop(){

        display: flex;

        &-figures {
            width: get-column-width(math.div(5, 12));
            margin-left: $grid_gutter;
        }

        &-content {
            order: -1;

            @include stack-spacing(component, $factor: 2);

            width: get-column-width(math.div(5, 12));
            margin-left: get-column-indent();
        }

    }

    &.is-alternation {
        @extend %spotlightTeaser--alternation;
    }

    .spotlightTeaserGroup-item:nth-child(even) & {
        @extend %spotlightTeaser--alternation;
    }

}
