$mainHeader_height: 74px !default;
$mainHeader_height__mobile: 56px !default;

$pageHeader_wallpaper_min-height: 620px !default;
$pageHeader_wallpaper_ratio: math.div($page_content_max-width, 756px) !default;

@import "accordionInsert/_accordionInsert";
@import "affinityLinkBar/_affinityLinkBar";
@import "affinityLinks/_affinityLinks";
@import "authorCard/_authorCard";
@import "authorCardGroup/_authorCardGroup";
@import "careerPageHeader/_careerPageHeader";
@import "caseSummary/_caseSummary";
@import "contentBlock/_contentBlock";
@import "cookieConsent/_cookieConsent";
@import "cookieConsent/_cookieConsent";
@import "countdownTimer/_countdownTimer";
@import "ctaInsert/_ctaInsert";
@import "dataInsert/_dataInsert";
@import "downloadCard/_downloadCard";
@import "downloadList/_downloadList";
@import "eventPageHeader/_eventPageHeader";
@import "eventSessionBooking/_eventSessionBooking";
@import "eventSummary/_eventSummary";
@import "featureList/_featureList";
@import "fiftyfiftySlide/_fiftyfiftySlide";
@import "formInsert/_formInsert";
@import "highlightInsert/_highlightInsert";
@import "highlightListInsert/_highlightListInsert";
@import "htmlInsert/_htmlInsert";
@import "htmlSlide/_htmlSlide";
@import "imageInsert/_imageInsert";
@import "imageTeaserGroup/_imageTeaserGroup";
@import "infographicInsert/_infographicInsert";
@import "infographicSlide/_infographicSlide";
@import "intrapageNav/_intrapageNav";
@import "lamapollInsert/_lamapollInsert";
@import "leadCard/_leadCard";
@import "leadInsert/_leadInsert";
@import "leadText/_leadText";
@import "livestreamInsert/_livestreamInsert";
@import "mainBackLink/_mainBackLink";
@import "mainFooter/_mainFooter";
@import "mainHeader/_mainHeader";
@import "mainStage/_mainStage";
@import "metadataInsert/_metadataInsert";
@import "milestonesTimeline/_milestonesTimeline";
@import "modalDialog/_modalDialog";
@import "newsletterForm/_newsletterForm";
@import "newsletterPanel/_newsletterPanel";
@import "notificationBanner/_notificationBanner";
@import "pageHeader/_pageHeader";
@import "pageSummary/_pageSummary";
@import "quoteInsert/_quoteInsert";
@import "scriptInsert/_scriptInsert";
@import "searchPanel/_searchPanel";
@import "slideGroup/_slideGroup";
@import "socialChannelsBar/_socialChannelsBar";
@import "socialShareBar/_socialShareBar";
@import "socialShareInsert/_socialShareInsert";
@import "sourceList/_sourceList";
@import "spotlightTeaser/_spotlightTeaser";
@import "spotlightTeaserGroup/_spotlightTeaserGroup";
@import "tableInsert/_tableInsert";
@import "teaserCard/_teaserCard";
@import "teaserCardGroup/_teaserCardGroup";
@import "textBlock/_textBlock";
@import "textSlide/_textSlide";
@import "topicFinder/_topicFinder";
@import "topicFinderV2/_topicFinderV2";
@import "topicInsert/_topicInsert";
@import "videoInsert/_videoInsert";
@import "videoTeaserGroup/_videoTeaserGroup";
@import "wallpaperSlide/_wallpaperSlide";
