.spotlightTeaserGroup {
    @include stack-spacing(section);
    @include stack-spacing(section, padding-bottom);

    @include full-width-backdrop($ci_active-color);


    $indent: .5 * ($stack-spacing__section + $stack-spacing__component);

    &-title {
        @include stack-spacing(default, padding-top, $factor: 1.15);
        @include stack-spacing(default, padding-bottom);

        font-size: rem(40px);
        line-height: 1.2;
        color: $white;

        text-align: center;

        @include full-width-backdrop($ci_leading-color);
    }

    &-description {
        @include stack-spacing(section);

        @include font-size(large);
        @include line-height(large);
        font-weight: $font-weight__medium;
        text-align: center;
    }

    &-items {
        @include stack-spacing(section);

        padding-top: $indent;
    }

    &-item {
        //background-color: $page-color;

        position: relative;
        z-index: 1;

        & + & {
            @include stack-spacing(component, $factor: 2);
            margin-top: (2 * $indent);
        }

        &::before {
            content: "";

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            display: block;

            background-color: $page-color;
        }

    }

    .spotlightTeaser {
        @include stack-spacing(0);

        position: relative;
        @include stack-spacing(component, top, $factor: -1);
        top: (-1 * $indent);

        &-content {
            @include stack-spacing(component);
        }

    }

    @include only-on-desktop(){

        &-description {
            width: get-column-width(math.div(8, 12));
            margin-left: auto;
            margin-right: auto;
        }

        .spotlightTeaser {

            &-content {
                margin-top: (2 * $indent);
            }

        }

    }

    @include not-on-desktop(){

        &-item {
            padding-left: $page_padding__mobile;
            padding-right: $page_padding__mobile;

            &::before {
                bottom: calc(#{$indent} - #{$page_padding__mobile});
            }

            &:not(:first-child) {

                .spotlightTeaser {
                    margin-top: calc(#{$page_padding__mobile} - #{$indent});
                }

            }

        }

    }

}
