@mixin fixedRatioFigure-set-ratio(
    $ratio: $fixedRatioFigure_fixed-ratio,
){

    a,
    &::before {
        padding-bottom: math.div(100%, $ratio);
    }

}
