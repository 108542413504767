.pageHeader {
    $modifier: "is-text-only";

    &.#{$modifier} {
        @include stack-spacing(section);

        margin-left: auto;
        margin-right: auto;

        display: block;
    }

    &.#{$modifier} & {

        &-hgroup {
            width: 100%;
            padding: 0;
        }

        &-topic {
            display: block;
        }

        &-title {
            display: block;
            @include stack-spacing();

            font-size: get-heading-font-size(1);
            width: 100%;
        }

        &-leadtext {
            @include stack-spacing();
        }

        &-metadata {
            position: static;
            @include stack-spacing();
            padding: 0;

            transform: none;
        }

        @include only-on-mobile(){

            &-title {
                font-size: get-heading-font-size(1, $headings_styles__mobile);
            }

        }

    }

    @include only-on-desktop(){

        &.#{$modifier} {
            width: get-column-width(math.div(8, 12));
        }

    }

}
