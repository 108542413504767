$mainHeader_gutter: $grid_gutter !default;
$mainHeader_height: 74px !default; // Please regard: Is already set in modules
$mainHeader_height__mobile: 56px !default; // Please regard: Is already set in modules

$mainHeader_host_height: 48px !default;
$mainHeader_host_height__mobile: 36px !default;

$mainHeader_branding_width: 165px !default;
$mainHeader_branding_width__mobile: 145px !default;

:root {
    --mainHeader-height: #{$mainHeader_height + $serviceNav_height};

    @include not-on-desktop(){
        --mainHeader-height: #{$mainHeader_height__mobile};
    }

}

.mainHeader {
    @include full-width-backdrop($backdrop-color__opaque);
    @include has-full-width();

    position: fixed;
    top: 0;
    z-index: 200;

    &::before {
        z-index: 1;
    }

    .mainNav {
        margin-top: 0;
    }

    & + &-spacer {
        //** Push content below fixed header **//
        padding-top: $mainHeader_height;
        padding-top: var(--mainHeader-height);

        @include not-on-desktop(){
            padding-top: $mainHeader_height__mobile;
        }

    }

    &-navbar {
        height: $mainHeader_height;
        @include has-limited-width();

        > .wrapper {
            height: 100%;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            position: relative;
            z-index: 1;

            > * {
                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

    }

    &-nav {
        flex: 1 0 auto;
    }

        // Extend width of search, locale or language nav to center main nav

    &-nav + &-search:last-child,
    &-nav + &-locale:last-child,
    &-nav + &-language:last-child {
        min-width: $mainHeader_branding_width;

        .mainNav-list {
            justify-content: flex-end;
        }

    }

    &-host {
        @include full-width-backdrop($ci_impulse-color);

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 1;

        height: $mainHeader_host_height;

        font-size: $base_font-size__smallest;
        font-weight: $base_font-weight__medium;

        &,
        a {
            color: $text-color;
        }

        &-label,
        &-logo {
            display: block;

            svg {
                width: 130px;
                height: auto;
            }

        }

        &-label {
            padding-top: .2em; // Vertical align with logo

            &::after {
                content: ":";
                margin-right: .5em;
            }

        }

        &-logo {
            line-height: 0;
        }

        @include not-on-desktop(){
            height: $mainHeader_host_height__mobile;

            &-logo {

                svg {
                    width: 96px;
                }

            }

        }

    }

    &-branding {

        a, .logo, svg {
            display: block;

            color: $accent-color !important;
        }

        svg {
            width: $mainHeader_branding_width;
        }

    }

    @include only-on-desktop(){
        width: calc(100vw - #{17px}); // Fallback for IE11
        width: calc(100vw - var(--scrollbar-width));

        &-search {

            &-toggle {
                font-weight: $base_font-weight__medium;

                a {
                    text-decoration: inherit;
                }

            }

            &-form {
                @include has-limited-width();

                position: absolute;
                left: 0; top: 100%;

                width: 100%;
            }

        }

        &-search {
            transition: all $base_transition-duration__out, z-index 0s $base_transition-duration__out;

            &-form {
                opacity: 0;
                pointer-events: none;
                z-index: -1;

                transition: inherit;

                position: absolute !important;

                @include full-width-backdrop($ci_leading-color);

                &::before { // Backdrop
                    height: 0;
                    transition: inherit;
                }

            }

        }

        &-search:not([data-toggle-on-click]):hover &-search-form,
        &-search[data-toggle-on-click-visible="true"] &-search-form {
            opacity: 1;
            pointer-events: all;
            z-index: 1;

            transition: all $base_transition-duration__in, z-index 0s;

            &::before { // Backdrop
                height: 100%;
            }

        }

        &-underlay {
            transition: all $base_transition-duration__out, z-index 0s $base_transition-duration__out;

            display: block;
            width: 100vw;
            height: 100vh;

            position: fixed;
            top: 0; left: 0;
            z-index: -2;

            opacity: 0;
            pointer-events: none;

            background-color: rgba($off-white, .8);

            &.is-visible {
                opacity: 1;
                pointer-events: all;

                transition: all $base_transition-duration__in, z-index 0s;
            }

        }

    }

    @include not-on-desktop(){
        height: var(--mainHeader-height);

        transform: translateX(-50%) !important;

        &-navbar {
            height: $mainHeader_height__mobile;

            > .wrapper {

                > * {
                    padding-left: 0;
                    padding-right: 0;
                }

            }

        }

        &-branding {
            margin: 0 auto;

            svg {
                width: $mainHeader_branding_width__mobile;
            }

        }

        &-nav {
            flex: 0 1 auto;
        }

        &-locale,
        &-language,
        &-search {
            display: none;
        }

    }

    //***** Compact Version *****//

    $transition-duration: $base_transition-duration__in;

    &-host {
        max-height: $mainHeader_host_height;
        transition: $transition-duration ease-in;

        overflow: hidden;

        @include not-on-desktop(){
            max-height: $mainHeader_host_height__mobile;
        }

    }

    &.is-compact &-host {
        max-height: 0;
    }

}
