@mixin dataInsert-set-columns(
    $data_column-span: 3,
    $column-count: $grid_number-of-cols,
    $rtl: false,
){

    &-title {
        $indent: right;

        @if $rtl {
            $indent: left;
        }

        padding-left: (.5 * $grid_gutter); padding-right: (.5 * $grid_gutter);
        padding-#{$indent}: calc(#{math.div(100% * $data_column-span, $column-count)} + #{.5 * $grid_gutter});
    }

    &-description {
        width: math.div(100% * ($column-count - $data_column-span), $column-count);
    }

    &-data {
        width: (math.div(100% * $data_column-span, $column-count));
    }

}

.dataInsert {
    @include stack-spacing(section);

    display: flex;
    flex-wrap: wrap;

    &-title {
        @include stack-spacing(component, margin-bottom);
    }

    &-data {

        > * + * {
            @include stack-spacing(component);
        }

    }

    &.has-swapped-data &-data {
        display: flex;
        flex-direction: column-reverse;

        > * {
            margin-top: 0;

            &:not(:first-child) {
                @include stack-spacing(component, margin-bottom);
            }

        }

    }

    &-keyfacts {

        > *:first-child {
            margin-top: 0;
        }

    }

    &-diagram {

        .diagram {
            margin-top: 0;
        }

        &-description {
            @include stack-spacing();
            font-size: $base_font-size__small;
        }

    }

    @include only-on-desktop(){
        margin-left: (-.5 * $grid_gutter);
        margin-right: (-.5 * $grid_gutter);

        > * {
            padding: 0 (.5 * $grid_gutter);
        }

        &-title {
            flex: 1 0 auto;
            width: 100%;
        }

        &-description {
            flex: 0 1 auto;
        }

        &-data {
            flex: 0 0 auto;
        }

        @include dataInsert-set-columns();

    }

    @include not-on-desktop(){
    	flex-direction: column;

    	&-title,
    	&-data {
        	order: -1;
    	}

    	&-description {
        	@include stack-spacing(component);
    	}

        &-diagram {
            width: 75%;
        }

    }

    @media (max-width: 375px){

        &-diagram {
            width: 100%;
        }

    }

    @include only-on-tablet(){

        &-diagram {
            width: 60%;
        }

    }

}

    //***** Animation *****//

.dataInsert {

    &[data-sal|='fade'] {
        opacity: 1 !important;

        .diagram {
            @extend %diagram-animated;

            &-record {
                transition-duration: 1.4s !important;
                transition-delay: .7s !important;
            }

        }

        &.sal-animate .diagram {
            @extend %diagram-revealed;
        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    @include only-on-desktop(){

        .dataInsert {
            @include dataInsert-set-columns($rtl: true);
        }

    }

}
