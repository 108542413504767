$quoteInsert_blockquote_icon: '<svg width="63" height="44" viewBox="0 0 63 44" fill="{{color}}" xmlns="http://www.w3.org/2000/svg"><path d="M0.76001 43.23H15.1045L24.6675 27.2448C29.2896 19.4121 29.449 17.3341 29.449 13.9772C29.449 6.14448 23.0736 0.22998 15.1045 0.22998C7.13533 0.22998 0.76001 6.30433 0.76001 13.8173C0.76001 18.7727 3.46952 23.0887 9.04793 25.4865L0.76001 43.23ZM34.0711 43.23H48.4155L57.9785 27.2448C62.6006 19.4121 62.76 17.3341 62.76 13.9772C62.76 6.14448 56.3847 0.22998 48.4155 0.22998C40.4464 0.22998 34.0711 6.30433 34.0711 13.8173C34.0711 18.7727 36.7806 23.0887 42.359 25.4865L34.0711 43.23Z"/></svg>';

$quoteInsert_icon_width: 63px;
$quoteInsert_icon_height: 44px;
$quoteInsert_icon_offset: -10px;
$quoteInsert_icon_scale__mobile: .75;

.quoteInsert {
    @include stack-spacing(section);

    &-content {
        $icon_width: $quoteInsert_icon_width;
        $icon_height: $quoteInsert_icon_height;
        $icon_data-url: get-svg-data-url($quoteInsert_blockquote_icon);
        $icon_offset: $quoteInsert_icon_offset;

        @include heading-style(2, $headings_styles, font-size, line-height);
        font-weight: $base_font-weight__medium;
        text-align: left;

        padding: $icon_height 0 0 0;

        position: relative;

        &::before {
            content: "";

            display: block;
            width: $icon_width; height: $icon_height;

            position: absolute;
            top: 0; left: 0;
            transform: translateY($icon_offset);

            background: url($icon_data-url);
        }

        @include not-on-desktop(){
            $icon_scale: $quoteInsert_icon_scale__mobile;

            @include heading-style(2, $headings_styles__mobile, font-size, line-height);
            padding-top: ($icon_scale * $icon_height);

            &::before {
                transform: translateY($icon_scale * $icon_offset) scale($icon_scale);
                transform-origin: top left;
            }

        }

    }

    &-blockquote {
        margin: 0;
    }

    &-author {
        font-size: $base_font-size__small;

        @include stack-spacing();

        &-name,
        &-subline {
            display: block;
        }

        &-name {
            @extend .is-uppercase;
        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .quoteInsert {

        &-content {
            text-align: right;

            &::before {
                left: auto;
                right: 0;
            }

        }

    }

}

//***** Variants *****//

@import "variants/_with-portrait";
