$copy_styles: (
) !default;

$copy_styles__standfirst: (
    font-size: 1.05rem,
    font-weight: $base_font-weight__bold,
) !default;

$copy_styles__small: (
    font-size: $base_font-size__small,
    color: $gray,
) !default;
