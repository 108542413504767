.livestreamInsert {
    $ratio: math.div(16, 9);

    @include stack-spacing(section);

    &-content {
        height: 0;
        padding-bottom: math.div(100%, $ratio);
        position: relative;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }

    &-teaser {
        padding-bottom: $page_padding__mobile;

        color: $page-color;

        .figure {
            margin-top: 0;

            &-caption {
                display: none;
            }

        }

        &-topic {
            @extend .is-uppercase;
            font-weight: $base_font-weight__bold;
            margin-top: -.2em;
        }

        &-title {
            @extend h1;

            margin-top: 0;
            font-size: rem(80px);
        }

        &-topic + &-title {
            @include stack-spacing(small);
        }

        &-content {
            @include stack-spacing(small);
            font-weight: $font-weight__bold;
        }

        @include not-on-desktop(){

            @include full-width-backdrop($ci_leading-color);

            &-image {
                position: relative;
                z-index: 1;

                &::before {
                    content: "";

                    display: block;
                    width: 105vw;
                    height: 50%;

                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: -1;

                    background-color: $page-color;
                }

            }

            &-text {
                padding-top: $page_padding__mobile;
            }

            &-topic {
                font-size: $base_font-size__small;
            }

            &-title {
                font-size: rem(28px, $base_font-size__mobile);
                line-height: 1.25;
            }


        }

        @include only-on-desktop(){
            @include stack-spacing(section, padding-top);
            @include stack-spacing(section, padding-bottom);

            min-height: 720px;
            position: relative;

            background-color: $ci_leading-color;

            &-image {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;

                width: 50%;
            }

            .figure {

                &,
                & * {
                    height: 100%;
                    width: 100%;
                }

                &-image {
                    object-fit: cover;
                }

                &-caption {
                    display: none;
                }

            }

            &-text {
                $indent-right: 1;

                width: get-column-width(math.div(6, 12));
                margin-left: get-column-indent();

                position: relative;
                z-index: 1;
            }

            &-content {
                @include stack-spacing();

                font-size: $base_font-size__largest;
                font-weight: $font-weight__bold;
            }

        }

    }

}

html[dir="rtl"] {

    @include only-on-desktop(){

        .livestreamInsert-teaser {

            &-image {
                right: auto;
                left: 0;
            }

            &-text {
                margin-left: auto;
                margin-right: get-column-indent();
            }

        }

    }

}

