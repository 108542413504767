$table_padding: .5rem 0 .4rem !default;

$table_row_border: 1px solid $text-color !default;
$table_row_background-color__alt: false !default;

@import "scss/_settings";
@import "scss/_styles";

%table {

    &-thead {

        .table-th,
        .table-td {
            padding-top: 0;
        }

    }

    &-th, &-td {
        $padding: 1ch;

        overflow: hidden;
        padding-right: $padding;

        position: relative;

        &::after {
            content: "";

            display: block;
            width: $padding; height: 100%;

            position: absolute;
            top: 0; right: 0;

            background-image: linear-gradient(to left, rgba($table_row_background-color, 1), rgba($table_row_background-color, 0))
        }

    }

    @if ($table_row_background-color__alt){

        &-tr:nth-child(even) & {

            &-th,
            &-td {

                &::after {
                    background-image: linear-gradient(to left, rgba($table_row_background-color__alt, 1), rgba($table_row_background-color__alt, 0))
                }

            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    %table {
        text-align: right;

        &-caption {
            text-align: right;
        }

    }

}
