.mainFooter {
    $width: 50%;

    &-description {

        &-title {
            $image: map-get($logo_images, with-claim--inverted);

            margin-top: 0 !important;

            display: block;

            background: svg-url($image, $white) no-repeat;
            background-size: $width auto;

            white-space: nowrap;
            text-indent: 110%;
            overflow: hidden;

            &::before { // Spacer
                content: "";

                display: inline-block;
                width: 100%;
                padding-bottom: math.div($logo__with-claim_height * $width, $logo_width);
            }

            @include not-on-desktop(){
                $width: 66.66%;

                width: 66.66%;
                max-width: 200px;
                aspect-ratio: math.div($logo_width, $logo__with-claim_height);

                background-size: 100% auto;

                &::before { // Spacer
                    padding-bottom: 0;
                }

            }

        }

    }

}

html[dir="rtl"] {

    .mainFooter {

       &-description {

            &-title {
                background-position: top right;
            }

        }

    }

}
