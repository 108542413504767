@import "_accordionInsert.styles";

.accordionInsert {

    &-item {

        .revealableContent {
            margin-top: 0;
        }

        & + & {
            @include stack-spacing(small);
        }

    }


}
