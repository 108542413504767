// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.pageHeader {

    @include only-on-desktop(){

        .heroImage {
            height: 100%;

            &-figure {
                min-height: 100%;

                display: flex;
                flex-direction: column;

                align-content: stretch;

                &,
                &[style*="background-image"]::before {
                    flex: 1 0 auto;
                }

            }

        }

    }

    .intrapageLink {
        @include stack-spacing(component);
    }

}
