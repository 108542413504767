%button {

    .is-active-color &,
    &#{&}.is-active-color,
    .is-active-blue &,
    &#{&}.is-active-blue {
        color: get-theme-color(active-color, primary);
    }

    .is-impulse-color &,
    &#{&}.is-impulse-color,
    .is-impulse-color &,
    &#{&}.is-impulse-color {
        color: get-theme-color(impulse-color, primary);
    }

}
