.js-makeSticky {

    &.js-makeSticky,
    &.is-sticky {
        position: relative;
        top: auto;
    }

    &.is-stuck {
        position: fixed;
        top: 0;
    }

}
