@mixin teaserListItem--seperator(){
    position: relative;

    &::before { // Line between values
        content: "";

        display: block;
        width: 0; height: 100%;

        position: absolute;
        left: 0; top: 50%;

        transform: translateY(-50%);

        border-left: 1px solid $gray;
    }

}

.teaserListItem  {
    $image_col-span: 2;
    $metadata_gutter: 1rem;

    @include stack-spacing(component);

    display: flex;

    margin-left: (-.5 * $grid_gutter);
    margin-right: (-.5 * $grid_gutter);

    > * {
        flex: 1;

        padding-left: (.5 * $grid_gutter);
        padding-right: (.5 * $grid_gutter);
    }

    > .wrapper {
        flex: 1 0 auto;
        width: (math.div(12 - $image_col-span, 12) * 100%);
    }

    &-image {
        flex: 1 0 auto;
        width: (100% * math.div($image_col-span, 12));

        .figure {
            margin-top: 0;
            position: relative;

            &::before {
                padding-bottom: (math.div(2, 3) * 100%);
            }

            &-caption {
                position: absolute;
                width: 100%;

                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;
            }

        }

    }

    &-metadata {
        margin-top: 0;

        @extend .is-uppercase;
        font-size: $base_font-size__smallest;
        font-weight: $base_font-weight__bold;

        display: flex;
        flex-wrap: wrap;

        margin-left: (-.5 * $metadata_gutter);
        margin-right: (-.5 * $metadata_gutter);

        > * {
            flex: 0 0 auto;

            padding-left: (.5 * $metadata_gutter);
            padding-right: (.5 * $metadata_gutter);
        }

        &-key {
            position: absolute;

            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
        }

        &-value {
            margin: 0;
            white-space: nowrap;

            &:not(:nth-child(2)) {
                @include teaserListItem--seperator();
            }

        }

    }

    &-title {
        flex: 1 0 auto;
        width: 100%;

        @include stack-spacing(small);
        font-size: get-heading-font-size(4);
    }

    &-content {
        @include stack-spacing(small);
    }

    @include only-on-desktop(){
        $cta_col-span: 3;

        > .wrapper {
            display: grid;
            grid-template-columns: 1fr get-column-width(math.div($cta_col-span, (12 - $image_col-span)));
            grid-template-rows: auto 1fr;
            grid-column-gap: $grid_gutter;
        }

        &-header,
        &-content {
            grid-column: 1;
        }

        &-cta {
            grid-column: 2;
            grid-row: 1 / span 2;

            justify-self: end;
            align-self: center;

            margin: 0;
            @include stack-spacing(default, padding-right);
        }

    }

    @include not-on-desktop(){
        $image_col-span: 4;

        margin-left: (-.5 * $grid_gutter__mobile);
        margin-right: (-.5 * $grid_gutter__mobile);

        > * {
            padding-left: (.5 * $grid_gutter__mobile);
            padding-right: (.5 * $grid_gutter__mobile);
        }

        > .wrapper {
            width: (math.div(12 - $image_col-span, 12) * 100%);
        }

        &-image {
            width: (100% * math.div($image_col-span, 12));
        }

        &-content {
            font-size: $base_font-size__small;
        }

    }

}
