// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

   // Quick fix: Remove icon on mobile

@include not-on-desktop(){

    .downloadCard {

        &-category {

            &::before {
                display: none;
            }

        }

    }

}
