.leadText {
    $line-height: 1.375;

    font-size: $base_font-size__largest;
    $line-height: 1.375;

    font-weight: $base_font-weight__medium;

    margin-top: $stack-spacing__section;

    &:not(.is-simple) {

        .is-first-letter {
            $font-size: 3.6em;

            font-size: $font-size;
                // FIXME: It seems that Firefox ignores the line-height
            line-height: math.div($line-height * 1em, $font-size);

            font-weight: $base_font-weight;

            float: left;
            padding-top: .3em;
            padding-right: .05em;

            margin-left: -.06em;
        }

    }

    > *:first-child {
        margin-top: 0;
    }

    @include not-on-desktop(){
        font-size: 1.2rem;
        line-height: 1.475;
    }

}

html[dir="rtl"] {

    .leadText {

        &:not(.is-simple) {

            .is-first-letter {
                font-size: inherit;
                line-height: inherit;

                font-weight: inherit;

                float: inherit;
                padding-top: inherit;
                padding-right: inherit;

                margin-left: inherit;
            }

        }

    }

}
