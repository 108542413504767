%formField-toggle-label {
    $size: $formField_marker_size;
    $inset: $formField_marker_inset;

    display: inline-block;

    padding-left: (1.4 * $size);
    padding-left: calc(#{$size} + .4em);
    margin-bottom: 0;

    position: relative;

    margin-left: $inset;

    user-select: none;
    cursor: pointer;

    @include label-supplement($supplement: null);

    &::before { // Marker
        content: '';

        position: absolute;
        top: (.5 * $base_font-size * $field_line-height); left: $field_border-width;
        transform: translateY(-50%);
        margin-top: ceil(-.5 * $field_border-width);

        display: block;
        width: ($size - 2 * $field_border-width);
        height: ($size - 2 * $field_border-width);

        border: $inset solid white;
        box-shadow: 0 0 0 $field_border-width $field_border-color;

        background-color: white;

        @include base-transition(in);
    }

    &:hover {

        &::before {
            background-color: $formField_marker_color__hover;
        }

    }

    &:not(:last-child) {
        margin-right: $grid_gutter;
    }

}

%formField-toggle-input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: auto;

    &:focus + %formField-toggle-label {
        color: $text-color;

        &::before { // Marker
            box-shadow: 0 0 0 $field_border-width $field_focus-color !important;
        }

    }

    // Reset focus styling to hover styling when mouse triggers focus
    &:focus:not(:focus-visible) + %formField-toggle-label {

        &::before {
            box-shadow: 0 0 0 $field_border-width $field_border-color !important;
        }

    }

    &:checked + %formField-toggle-label {

        &::before {
            background-color: $formField_marker_color;
        }

    }

    &:disabled + %formField-toggle-label,
    &:disabled:focus + %formField-toggle-label,
    &:disabled:active + %formField-toggle-label {
        color: $field_disabled-color !important;
        cursor: default;

        &::before { // Marker
            background-color: $field_disabled-color;
            box-shadow: 0 0 0 $field_border-width $field_disabled-color !important;
        }

    }

}

%formField-radio-label {
    $size: $formField_marker_size;

    @extend %formField-toggle-label;

    padding-left: (1.3 * $size);
    padding-left: calc(#{$size} + .3em);

    &::before {
        border-radius: 50%;
    }

}

%formField-radio-input {
    @extend %formField-toggle-input;
}

%formField-checkbox-label {
    @extend %formField-toggle-label;
}

%formField-checkbox-input {
    @extend %formField-toggle-input;
}

%formField-toggleGroup {

    &-label {
        @include styles($label_styles);
        @include label-supplement();

        display: block;
        @include stack-spacing(small, margin-bottom);
    }

    %formField-toggle-label::after { // Supplement
        display: none;
    }

}

.formField {

    &.is-radio,
    &.is-radio-group {

        .formField {

            &-input {
                @extend %formField-radio-input;
            }

            &-label {
                @extend %formField-radio-label;
            }

        }

    }

    &.is-checkbox,
    &.is-checkbox-group {

        .formField {

            &-input {
                @extend %formField-checkbox-input;
            }

            &-label {
                @extend %formField-checkbox-label;
            }

        }

    }

    &.is-radio-group,
    &.is-checkbox-group {
        @extend %formField-toggleGroup;

        .formField {

            &-input {
                @extend %formField-toggle-input;
            }

            &-label {
                @extend %formField-toggle-label;
            }

        }

    }

    &-group {

        &-label {
            @extend %formField-toggleGroup-label;
        }

    }

}
