.intrapageLink {
    $icon_size: 24px;
    $icon_data: get-icon-data-url(arrow-down, $text-color);

    @include stack-spacing(component);

    position: relative;
    z-index: 1;

    &-link {
        @extend %link--empasised;

        &::after { // Icon
            content: "";

            display: inline-block;
            width: $icon_size; height: $icon_size;

            vertical-align: middle;
            position: relative;
            top: -.1em; right: -4px;

            background: url($icon_data) center center no-repeat;
            border-radius: 50%;

        }

        @each $theme in map-keys($color-themes) {

            &.is-#{$theme}::after,
            .is-#{$theme} &::after {

                $color: get-theme-color(
                    $theme: $theme,
                    $key: text-color,
                );

                $icon_data: get-icon-data-url(arrow-down, $color);

                background-image: url($icon_data);
            }

        }

    }

    @include not-on-desktop(){
        text-align: center;
    }

}

html[dir="rtl"] {

    .intrapageLink-link {

        &::after {
            right: auto; left: -4px;
        }

    }

}
