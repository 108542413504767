.contactDetails {

    &-value {

        a  {

            &:hover {
                text-decoration-color: $ci_active-color;
            }

        }

    }

}
