.formButtonset {
    $gutter: $formButtonset_gutter;

    margin-top: ($stack-spacing__component - $stack-spacing);
    margin-top: calc(var(--sp-component) - var(--sp));

    display: flex;
    flex-wrap: wrap;

    margin-left: (-.5 * $gutter);
    margin-right: (-.5 * $gutter);

    &-item {
        @include stack-spacing();

        display: block;
        flex: 0 0 auto;

        padding-left: (.5 * $gutter);
        padding-right: (.5 * $gutter);
    }

    .button {
        display: block;
        width: 100%;
        margin-top: 0;

        white-space: nowrap;
    }

    @include only-on-mobile(){
        $gutter: $formButtonset_gutter__mobile;

        margin-left: (-.5 * $gutter);
        margin-right: (-.5 * $gutter);

        &-item {
            flex: 1 0 50%;

            padding-left: (.5 * $gutter);
            padding-right: (.5 * $gutter);
        }

    }

}
