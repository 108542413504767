%button {
    @include button-style();

    &[disabled],
    &.disabled {
        @include button-style($button-states__disabled);

        cursor: default;
    }

    &--secondary {
        @include button-style($button-states__secondary);

        &[disabled],
        &.disabled {
            @include button-style($button-states__secondary__disabled);

            cursor: default;
        }

    }

}

.button,
.cta {
    @extend %button;
}

.button.is-secondary {
    @extend %button--secondary;
}
