.pageHeader {
    $modifier: "is-simple";

    &.#{$modifier} {
        display: block;
        text-align: center;

        @include stack-spacing(section);
    }

    &.#{$modifier} & {

        &-hgroup {
            width: 100%;
            padding-bottom: 0;
        }

        &-topic {
            display: block;
            font-size: $base_font-size;
        }

        &-title {
            display: block;
            width: 100%;

            font-size: rem(60px);
            line-height: 1.35;
        }

        &-leadtext {
            @include stack-spacing(component);
        }

    }

    @include only-on-desktop(){

        &.#{$modifier} & {

            &-leadtext {
                width: (math.div(8, 12) * 100%);
                margin-left: auto; margin-right: auto;
            }

        }

    }

    @include not-on-desktop(){

         &.#{$modifier} & {

            &-topic {
                font-size: $base_font-size__small;
            }

            &-title {
                font-size: get-heading-font-size(1, $headings_styles__mobile);
            }

        }

    }

}
