%link {
    @include state-styles($link-states, default);

    &:hover {
        @include state-styles($link-states, hover);
    }

    &:active {
        @include state-styles($link-states, active);
    }

    &.is-stand-alone {

            // Force displaying element as block (without using `display: block`)

        &::before,
        &::after {
            content: "";

            display: block;
            width: 100%; height: 0;
        }

        &::after {
            margin-bottom: $stack-spacing;
        }

    }

    &[href^="tel:"] {
        color: $text-color;
        text-decoration: none;
        cursor: default;
    }

}

a {
    @extend %link;
}
