// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.pageSummary {

    .highlightList {

        &-title {
            margin-bottom: 1.45em;

            &::before {
                width: get-column-width(math.div(1, 5));
            }

        }

    }

}
