@import "scss/_choices";
@import "scss/_styles";

.choices.is-open {
    overflow: visible;
}

.choices__list[aria-expanded] {
    @extend .choices__list--dropdown;
}
