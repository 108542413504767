.lazyloadingFigure {

    figure {

        &::before {
            filter: blur(10px);
            clip-path: inset(0);
        }

        &.is-loaded::before {
            filter: blur(0);
            transition: filter .3s;
        }

    }

}
