.imagePreview {

    &-link {
        display: block;
    }

    &-figure {
        @extend  .figure;
    }

    &-image {
        @extend  .figure-image;
    }

    &-caption {
        @extend  .figure-caption;
    }

}
