%teasercard--fatured-alteration {

    @include only-on-desktop(){
        margin-left: auto; margin-right: 0;

        .teaserCard {

            &-image {
                left: get-columns-indent(-6, 6);
            }

        }

    }

}

.teaserCard {

    &.is-featured {

        @include color-themes((
            color: secondary,
        ));

    }

    &.is-featured & {

        &-header {
            padding-top: 0;
        }

        &-topic {
            @include reset-topic();
        }

    }

    @include only-on-desktop(){
        $padding: $box-padding;

        $title_font-size: rem(50px);
        $title_line-height: 1.3;

        $image_indent: (-1 * $stack-spacing__component);

        &.is-featured {
            width: get-column-width(math.div(6, 12));
            max-width: none;

            padding: $padding;

            transform: translateY(-1 * $image_indent);

            > .wrapper {
                min-height: 320px;
                display: flex;
                flex-direction: column;
            }

            & + .spacer {
                height: (-2 * $image_indent);
            }

            &::after { // Backdrop
                content: "";

                display: block;
                width: 100%; height: 100%;

                position: absolute;
                top: 0; left: 0;

                z-index: -1;

                background-color: $backdrop-color__opaque;
            }

            .figure,
            picture,
            img {
                height: 100%;
            }

            //** Theming **//

            &::after {

                @include color-themes((
                    background-color: primary,
                ));

            }

        }

        &.is-featured &  {

            &-image {
                position: absolute;
                top: $image_indent; bottom: $image_indent;
                left: get-columns-indent(5, 6);

                z-index: -1;

                width: get-column-width(math.div(7, 6));
            }

            &-title {
                font-size: $title_font-size;
                line-height: $title_line-height;
            }

            &-content {
                font-weight: $base_font-weight__medium;
                font-size: $base_font-size__large;

                @include stack-spacing();

                flex: 1 0 auto;
            }

            &-cta {
                @include stack-spacing(component);
            }

        }

    }

    @include not-on-desktop(){
        $image_ratio: 1;

        $padding: $box-padding__mobile;

        $title_font-size: $teaserCard_title_font-size__mobile;
        $title_line-height: $teaserCard_title_line-height__mobile;

        $text_indent: $padding;
        $box_offset: (-2 * $text_indent);

        &.is-featured {
            margin-left: $text_indent;

            > .wrapper {
                margin: $box_offset 0 0;

                padding: $padding;

                position: relative;
                z-index: 1;

                background-color: $backdrop-color__opaque;
            }

        }

        &.is-featured & {

            &-image {
                margin: 0 $text_indent 0 (-1 * $text_indent);

                .figure {

                    &::before {
                        padding-bottom: (math.div(1, $image-ratio) * 100%);
                    }

                }

            }

            &-topic {
                margin-top: -.2em;
            }

            &-title {
                font-size: $title_font-size;
                line-height: $title_line-height;
            }

            &-content {
                margin-top: $stack-spacing__mobile;
            }

            &-cta {
                @include stack-spacing();
            }

        }

        @each $theme in map-keys($color-themes) {

            $color: get-theme-color(
                $theme: $theme,
                $key: primary,
            );

            &.is-featured.is-#{$theme} > .wrapper {
                background-color: $color;
            }

        }

        &.is-featured.is-alternation {
            margin-left: auto; margin-right: 0;

            margin-left: 0;
            margin-right: $text_indent;

            & .teaserCard {

                &-image {
                    margin-left: $text_indent;
                    margin-right: (-1 * $text_indent);
                }

            }

        }

    }

    &.is-featured.is-alternation {
        @extend %teasercard--fatured-alteration;
    }

}

html[dir="rtl"] {

    @include only-on-desktop(){

        .teaserCard.is-featured .teaserCard {

            &-image {
                left: auto;
                right: get-columns-indent(5, 6);
            }

        }

        .teaserCard.is-featured.is-alternation {
            margin-left: 0; margin-right: auto;
        }

        .teaserCard.is-featured.is-alternation .teaserCard {

            &-image {
                right: auto;
                left: get-columns-indent(5, 6);
            }

        }

    }

}
