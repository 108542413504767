:root {

    @include responsive-variables(
        $set: $stack-spacings,
        $prefix: --sp
    );

    @include responsive-variables(
        $set: $box-paddings,
        $prefix: --bp
    );

}

//***********************//
//***** Page Layout *****//
//***********************//

html {
    overflow-x: hidden;

    min-height: 100%; min-width: 100%;
    height: auto;
}

body {
    min-height: 100%;
    min-height: 100vh;

    overflow-x: hidden;

    max-width: $page_max-width;
    margin: 0 auto !important;

    @if global_variable_exists(page_background){
        background: $page_background;
    }

    @if global_variable_exists(page_box-shadow){
        box-shadow: $page_box-shadow;
    }

    position: relative;
    z-index: 1;
}

h1, h2, h3, h4, h5, h6, p, blockquote,
table, caption, tbody, tfoot, thead,
address, big, dl, ol, ul,
form, fieldset, legend,
img {
    @include stack-spacing();
    margin-bottom: 0;
}

.grid-page {
    min-height: 100vh;

    @media (min-width: ($breakpoint__desktop + 1)){

        margin-left: (-.5 * $page_padding);
        margin-right: (-.5 * $page_padding);

        &-content {
            box-sizing: content-box;
            max-width: $page_content_max-width;

            padding: 0 $page_padding $stack-spacing__section;
        }

    }

    @if ($page_max-width != none){

        @media (min-width: (.5 * $page_max-width + .5 * $breakpoint__desktop)){

            margin: 0;

            &-content {
                padding-left: $page_padding;
                padding-right: $page_padding;
            }

        }

    }

}

.grid-section {
    margin-top: $stack-spacing__section__mobile;

    @include only-on-desktop(){
         margin-top: $stack-spacing__section;
    }

}

.grid-component {
    margin-top: $stack-spacing__component__mobile;

    &:first-child {
        margin-top: 0;
    }

    @include only-on-desktop(){
         margin-top: $stack-spacing__component;
    }

}

    //***** Modifier classes for setting width *****/

.has-full-width {
    @include has-full-width();
}

.has-limited-width {
    @include has-limited-width();
}

.has-limited-wrapper {
    @include has-limited-wrapper();
}


//****************//
//***** Grid *****//
//****************//

    //***** Global attributes *****/

@include only-on-desktop(){

    .grid-row {
        @include grid-row();

        &.is-centered {
            justify-content: center;
        }

    }

}

    //***** Rows with a fixed number of columns *****/
    //***** e.g. `.row.with-3-columns .column` ******/

@include only-on-desktop(){

    .grid-row {

        .grid-column {
            @include grid-column();
        }

    }

    @for $i from 1 through length($grid_col-spans) {

        $count: nth($grid_col-spans, $i);

        .grid-row.with-#{$count}-columns {
            flex-wrap: wrap;

            .grid-column {
                @include grid-column(math.div(1, $count));
            }

        }

    }

}

    //***** Rows with a fixed number of columns and ratio *****/
    //***** e.g. `.row.with-ratio-2-1 .column` ****************/

@include only-on-desktop(){

    $ratios: $grid_col-ratios;

    @if type-of(nth($ratios, 1)) != "list" {
            // If `$ratios` is not a list of ratios, convert it
        $ratios: append((), $ratios, comma);
    }

    @for $i from 1 through length($ratios) {

        $ratio: nth($ratios, $i);
        $count: length($ratio);

        $total: 0;
        $selector: ".with-ratio";

        @for $j from 1 through length($ratio){
            $total: $total + nth($ratio, $j);

            $selector: "#{$selector}-#{nth($ratio, $j)}"
        }

        .grid-row {

            &#{$selector} {

                .grid-column {

                    @for $j from 1 through length($ratio){

                        $cols: nth($ratio, $j);

                        &:nth-child(#{$j}n){
                            @include grid-column(math.div($cols, $total));
                        }

                    }

                }

            }

        }

    }

}


    //***** Elements with a span over n of $grid_number-of-cols columns *****/
    //***** e.g. `span-6.columns` **************************************/

@include only-on-desktop(){

    .grid-row {

        .grid-column {
            @include grid-column();

            &.is-centered {
                float: none;
                margin-left: auto;
                margin-right: auto;
            }

        }

        @for $i from 1 through $grid_number-of-cols {

            .span-#{$i} {
                flex: 0 1 auto;
                width: math.div(floor(10000% * math.div($i, $grid_number-of-cols)), 100);
            }

        }

    }

}


    //***** Placeholder for width of one of n elements *****/

@for $i from 1 through $grid_number-of-cols {

    %one-of-#{$i} {
        width: math.div(floor(math.div(100, $i) * 100%), 100);
    }

}

@for $i from 1 through $grid_number-of-cols {

    .grid-row {

        .is-one-of-#{$i} {
            @extend %one-of-#{$i};
        }

    }

}

//--* Visually hide elements -----//
//-- Thanks to https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html --//

%visually-hidden:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    white-space: nowrap;
}

%is-visually-hidden { // Deprecated: Use "%visually-hidden"
    @extend %visually-hidden;
}

.is-visually-hidden {
    @extend %is-visually-hidden;
}
