%navigationLink {
    @include stack-spacing();

    &-link {
        font-weight: $base_font-weight__medium;
        text-decoration: none;

        display: inline-block;
        white-space: nowrap;

        position: relative;
        z-index: 1;

        &:not(.button){
            @extend %link--empasised;
        }

        .icon {
            display: inline;
            margin-left: .2em;

            &:first-child {
                margin-left: -2px;
                margin-right: .2em;
            }

        }

            // Remove icon if link is marked as external

        &[href^="http"] {

            svg {
                display: none;
            }

        }

    }

}


%navigationLink--go-back {
    transform-origin: bottom right;

    &:hover {
        transform-origin: bottom left;
    }

}

html[dir="rtl"] {

    %navigationLink {

        svg {
            margin-right: .2em;
            margin-left: -5px;

            transform: scaleX(-1);
        }

    }

    %navigationLink--go-back {

        svg {
            margin-right: -2px;
            margin-left: .2em;

            transform: scaleX(-1);
        }

    }

}

.navigationLink {
    @extend %navigationLink;

    &-link {
        @extend %navigationLink-link;
    }

    &.is-go-back {
        @extend %navigationLink--go-back;
    }

}
