@mixin filterList-option-marker(
    $border-colors: define-color-states(
        transparent,
        $ui-color__hover,
        $ui-color__active
    ),
    $border-width: $base_border-width,
){
    display: inline-block;

    padding-bottom: $border-width;
    position: relative;

    &::before {
        content: "";

        display: block;
        width: 100%;

        position: absolute;
        bottom: 0;

        border-bottom: $border-width solid color-state($border-colors);

        @include base-transition();
        transition-property: border-color;
    }

    &:hover::before {
        border-color: color-state($border-colors, hover);
        @include base-transition(in);
    }

    &:active::before {
        border-color: color-state($border-colors, active);
    }

}

.filterPanel {
    $gutter: $grid_gutter;

    $border-width: $base_border-width;
    $options_padding: (.5 * $grid_gutter);

    $icon: get-svg-data-url('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="1.5" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M6 10L12 15L18 10"/></svg>');

    @include stack-spacing(component);

    display: flex;

    margin-left: (-.5 * $gutter);
    margin-right: (-.5 * $gutter);

    position: relative;
    z-index: 1;

    user-select: none;

    > * {
        padding-left: (.5 * $gutter);
        padding-right: (.5 * $gutter);
    }

    &-label {
        min-width: math.div(2 * 100%, 12);
        font-weight: $base_font-weight__bold;
    }

    &-group {

        &-label {
            white-space: nowrap;

            &::after {
                content: "";

                display: inline-block;
                width: 1em; height: 1em;

                vertical-align: middle;
                margin-top: -.1em;

                margin-left: .25em;

                background: url($icon) center center no-repeat;
            }

        }

    }

    &-options {
        list-style: none;
        padding: 0;
        margin: 0;

        display: block;
        position: absolute;

        opacity: 0;
        pointer-events: none;
        z-index: -1;

        transition: all $base_transition-duration__out, z-index 0s $base_transition-duration__out;

        padding-top: $border-width;

        &::before { // Backdrop
            content: "";

            display: block;
            width: auto; height: 0;

            position: absolute;
            top: 0;
            left: (-1 * $options_padding);
            right: (-1 * $options_padding);

            background: $backdrop-color__opaque;
            border-top: $border-width solid $ui-color__hover;
            transition: inherit;
        }

    }

    &-group:hover &-options {
        opacity: 1;
        pointer-events: all;
        z-index: 1;

        transition: all $base_transition-duration__in, z-index 0s;

        &::before { // Backdrop
            height: 100%;
        }

    }

    &-option {
        @include stack-spacing(small);

        font-size: $base_font-size__small;
        line-height: 1.2;

        padding-top: .2em;

        &:last-child {
            margin-bottom: $options_padding;
        }

        &-label {
            cursor: pointer;
            white-space: nowrap;

            @include filterList-option-marker($border-width: $border-width);
        }

        input {
            display: none;

            &:checked + label::before {
                border-color: rgba($text-color, .85);
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .filterPanel {

        &-group {

            &-label {

                &::after {
                    margin-left: 0;
                    margin-right: .25em;
                }

            }

        }

    }

}
