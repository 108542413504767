.eventSessionBooking {
    @include stack-spacing(section);

    &-title {
        @extend .mainSection-title;
    }

    &-description {

        > *:first-child {
            @include stack-spacing(0);
        }

    }

    &-title + &-description {
        @include stack-spacing();
    }

    &-agenda {

        &:not(:first-child) {
            @include stack-spacing(component);
        }

        &-title {
            @extend h2;
        }

    }

    &-day {

        & + & {
            @include stack-spacing(component);
        }

        &-date {
            display: inline-block;
            padding-bottom: .0em;
            border-bottom: $field_border-width solid $text-color;
            @include stack-spacing(default, margin-bottom);
        }

    }

    &-agenda-title + &-day {
        @include stack-spacing(component);
    }

    &-item {
        $title_font-size: large;
        $title_line-height: large;

        @include stack-spacing(small);

        position: relative;
        z-index: 1;

        $indent: (2 * px($box-padding__small) + $formField_marker_size + $field_border-width);
        --indent: calc(2 * var(--bp-small) + #{$formField_marker_size + $field_border-width});

        &::before { // Bar on the left
            content: "";

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: -1;

            transform: translateX(50%);
            margin-left: round(-.5 * $field_border-width);

            display: block;
            width: $indent;
            width: var(--indent);

            border-right: $field_border-width solid $page-color;
            border-left: $field_border-width solid $gray;

            transition: background-color $base_transition-duration__out;
        }

        &[data-bookable="true"] {
            background-color: $backdrop-color;

            &::before {
                border-left: none;
                transform: none;
                margin-left: 0;

                background-color: $gray;
            }

        }

        &[data-bookable="true"][data-selected="true"],
        &[data-bookable="true"][data-available="true"]:hover {

            &::before {
                background-color: $field_focus-color;
                transition-duration: $base_transition-duration__in;
            }

        }

        &[data-available="false"] {
            color: rgba($text-color, .5);

            &::before {
                background-color: rgba($gray, .25);
            }

        }

        &[data-hidden="true"] {
            display: none;
        }

        > .wrapper {
            position: relative;
        }

        &:not([data-bookable="true"]) {

            > .wrapper {
               @include stack-spacing(small, (padding-top, padding-bottom));

               padding-left: ($indent + px($box-padding__small));
               padding-left: calc(var(--indent) + var(--bp-small));
            }

        }

        &[data-bookable]:not([data-bookable="true"]) {
            display: none ;// QUICKFIX: Hide non-bookable items
        }

        > *:first-child {
            @include stack-spacing(0);
        }

        &-marker {
            @include box-padding(small, padding);
            padding-top: .3em;
            padding-bottom: .2em;

            @include box-padding(small, margin, $factor: -1);
            @include box-padding(small, margin-bottom);

            @include font-size(small);
            @include line-height(small);
            font-weight: $font-weight__medium;

            background-color: $notification-color;
        }

        &-metadata {
            $metadata_gutter: 1.5ch;

            @include stack-spacing(0);

            @include font-size(small);
            @include line-height(small);
            font-weight: $font-weight__medium;

            &-list {
                margin-top: 0;
                font-size: $base_font-size__small;
            }

            &-key {
                position: absolute;

                text-indent: 200%;
                white-space: nowrap;
                overflow: hidden;
            }

            &-value {
                margin: 0;

                display: inline;
                padding-right: (.5 * $metadata_gutter);

                &:not(:nth-child(2)) {
                    padding-left: (.5 * $metadata_gutter);

                    position: relative;

                    &::before { // Compensate space between inline elements
                        content: "\00a0"; // Non-breaking space
                    }

                    &::after { // Line between values
                        $height: 1em * $base_line-height;

                        content: "";

                        padding-left: (.5 * $metadata_gutter);

                        display: block;
                        width: 0; height: $height;

                        position: absolute;
                        left: 0; top: (.6 * 1em * (1 - $base_line-height));

                        border-left: 1px solid $gray;
                    }

                }

            }

        }

        &-title {
            display: block;

            margin-top: .1em;

            @include font-size($title_font-size);
            @include line-height($title_line-height);
        }

        &-speakers {
            font-weight: $font-weight__medium;
        }

        &-summary {
            @include stack-spacing(small);

            @include font-size(small);
            @include line-height(small);

            > *:first-child {
                @include stack-spacing(0);
            }

            p + p {
                @include stack-spacing(small);
            }

        }

        .formField.is-checkbox {
            @include stack-spacing(0);

            .formField-label {
                display: block;

                margin: 0;

                @include box-padding(small);
                padding-left: ($indent + px($box-padding__small));
                padding-left: calc(var(--indent) + var(--bp-small));

                position: static;

                &::before {
                    top: 50%;
                    transform: translateY(-50%);
                    @include box-padding(small, margin-left);
                }

            }

        }

        &:not([data-available="true"]) {

            .formField.is-checkbox .formField-label {
                $opacity: .3;

                cursor: default;

                &::before { // Checkbox
                    background-color: rgba(white, $opacity) !important;
                    border-color: transparent !important;
                    box-shadow: 0 0 0 $field_border-width rgba($field_border-color, $opacity) !important;
                }

            }

        }

        &:not([data-bookable="true"]) {

            .formField.is-checkbox {

                .formField-label {
                    cursor: default;

                    &::before { // Checkbox
                        display: none;
                    }

                }

            }

            &:last-child {

                .formField.is-checkbox .formField-label {
                    padding-bottom: 0;
                }

            }

        }

    }

    &-buttonset {
        @include stack-spacing(component);

        .button {
            @include stack-spacing(0);
        }

    }

}
