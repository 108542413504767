/*!
 * @license
 * MyFonts Webfont Build ID 3799693, 2019-08-22T05:24:48-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Campton-Bold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/bold/
 *
 * Webfont: Campton-Book by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/book/
 *
 * Webfont: Campton-SemiBold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/semi-bold/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3799693
 * Licensed pageviews: 100,000
 * Webfonts copyright: Copyright &#x00A9; 2017 by Ren&#x00E9; Bieder. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/

@import url("//hello.myfonts.net/count/39fa8d");

$font-faces_root: "../fonts/Campton/" !default;

$font-faces_formats: "eot", "woff", "woff2", "ttf" !default;

$font-faces: (
    (
        font-family: "Campton",
        font-file: "39FA8D_1_0",
        root: "#{$font-faces_root}",
        font-weight: 400,
        font-style: normal
    ),
    (
        font-family: "Campton",
        font-file: "39FA8D_2_0",
        root: "#{$font-faces_root}",
        font-weight: 500,
        font-style: normal
    ),
    (
        font-family: "Campton",
        font-file: "39FA8D_0_0",
        root: "#{$font-faces_root}",
        font-weight: 700,
        font-style: normal
    ),
) !default;

$font-size: 21px !default;
$line-height: 1.6 !default;

$font-size__mobile: 16px !default;

$font-size__large: rem(27px) !default;
$font-size__largest: rem(33px) !default;

$line-height__large: 1.3 !default;
$line-height__large__mobile: $line-height__large !default;

$line-height__largest: 1.2 !default;
$line-height__largest__mobile: $line-height__largest !default;

$font-size__small: rem(18px) !default;
$font-size__smallest: rem(14px) !default;

$font-family: 'Campton', 'Noto Sans SC', 'Noto Sans', Helvetica, Arial, sans-serif !default;

$font-weight__medium: 500;
$base_font-weight__medium: $font-weight__medium;

$base_uppercase_styles: (
    font-weight: $font-weight__medium,
    text-transform: uppercase,
    letter-spacing: .075em,
);


@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

@media (min-width: $breakpoint__desktop) and (max-width: $page_content_max-width){

    :root,
    html {
        font-size: 18px;
    }

}

.is-uppercase {
    @include styles($base_uppercase_styles);
}
