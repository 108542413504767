@mixin teaserCard--emphasised (
    $modifier: is-emphasised,
){
    $topic_font-size: $base_font-size__large;
    $topic_line-height: 1.2;

    &.#{$modifier} {
        position: relative;

            // Spacer for topic
        padding-top: (.5 * $topic_line-height * $topic_font-size);
    }

    &.#{$modifier} & {

        &-header {
            position: static;
            padding-top: 0;
        }

        &-topic {
            position: absolute;
            top: .2em; left: 50%;

            width: 100%;

            transform: translateX(-50%);

            padding: 0 (.5 * $grid_gutter);

            font-size: $topic_font-size;
            line-height: $topic_line-height;
            text-align: center;

            background-color: transparent;

            user-select: none;
            pointer-events: none;
        }

        &-image {
            position: relative;

            &::after {
                content: "";

                display: block;
                width: 100%; height: 50%;

                position: absolute;
                top: 0; left: 0;

                background: linear-gradient(to bottom, rgba(black, .8), rgba(black, .4), rgba(black, .15), rgba(black, 0));
                mix-blend-mode: multiply;

                pointer-events: none;
            }

        }

        &-title {
            @include stack-spacing();
        }

    }

    @include not-on-desktop(){

        &.#{$modifier} & {

            &-topic {
                padding-left: (.5 * $page_padding__mobile);
                padding-right: (.5 * $page_padding__mobile);
            }

        }

    }

}

.teaserCard {
    @include teaserCard--emphasised();
}
