%teaserCard--side-by-side {
    $module-name: "teaserCard";

    $image_col-span: 2;

    max-width: none;

    display: flex;

    margin-left: (-.5 * $grid_gutter);
    margin-right: (-.5 * $grid_gutter);

    > * {
        flex: 1;

        padding-left: (.5 * $grid_gutter);
        padding-right: (.5 * $grid_gutter);
    }

    > .wrapper {
        flex: 1 0 auto;
        width: (math.div(12 - $image_col-span, 12) * 100%);
    }

    .figure {

        img {
            aspect-ratio: math.div(3, 2);
        }

    }

    .figure {

        img {
            aspect-ratio: 3/2;
        }

    }

    .#{$module-name} {

        &-image {
            flex: 1 0 auto;
            width: (100% * math.div($image_col-span, 12));

            .figure::before {
                padding-bottom: (math.div(2, 3) * 100%);
            }

        }

        &-header {
            padding-top: 0;
        }

        &-topic {
            @include reset-topic();
            font-size: $base_font-size__smallest;
        }

        &-title {
            @include stack-spacing(small);
            font-size: get-heading-font-size(4);
        }

        &-content,
        &-address,
        &-contact {
            font-size: $base_font-size__small;
        }

        &-address {
            @include stack-spacing(small);
            font-style: inherit;
        }

    }

        // Has contact details

    &.has-contact .#{$module-name} {

        &-cta {
            display: none;
        }

    }

    @include only-on-desktop(){
        $cta_col-span: 3;
        $contact_details_col-span: 5;

        > .wrapper {
            display: grid;
            grid-template-columns: 1fr get-column-width(math.div($cta_col-span, (12 - $image_col-span)));
            grid-template-rows: auto auto 1fr;
            grid-column-gap: $grid_gutter;
        }

        .#{$module-name} {

            &-header,
            &-metadata,
            &-content,
            &-address {
                grid-column: 1;
            }

            &-cta {
                grid-column: 2;
                grid-row: 1 / -1;

                justify-self: end;
                align-self: center;

                margin: 0;
                @include stack-spacing(default, padding-right);

                    // Fallback for IE11

                @include stack-spacing();

                @supports (display: grid) {
                    margin-top: 0;
                }

            }

        }

            // Has contact details

        &.has-contact {

            > .wrapper {
                grid-template-columns: 1fr get-column-width(math.div($contact-details-col-span, 12 - $image-col-span));
            }

            .contactDetails {
                font-weight: inherit;
                margin-top: 0;

                &-list {
                    display: block;
                }

                &-value:first-child,
                &-label:first-child + .contactDetails-value {
                    margin-top: 0;
                }

                a {
                    text-decoration-color: transparent;

                    &:hover {
                        text-decoration-color: $ui_background-color__hover;
                    }

                }

                    // Fallback for IE11

                @include stack-spacing();

                @supports (display: grid) {
                    margin-top: 0;
                }

            }

        }

        &.has-contact .#{$module-name} {

            &-content {
                font-size: $base_font-size__small;
                margin-top: 0;
            }

            &-contact {
                $indent: get-columns-indent(1, $contact_details_col-span);

                grid-column: 2;
                grid-row: 1 / -1;

                position: relative;
                z-index: 1;

                    // Fallback for IE11

                .contactDetails {

                    &-list {
                        display: flex;
                        align-items: center;
                    }

                    &-value {
                        margin-top: 0;
                    }

                }

                    // For browser that supports grid

                @supports (display: grid) {

                    display: flex;
                    align-items: center;

                    margin-left: (-.5 * $grid_gutter);
                    margin-right: (-.5 * $grid_gutter);

                    > * {
                        flex: 1;

                        padding-left: (.5 * $grid_gutter);
                        padding-right: (.5 * $grid_gutter);
                    }

                    .contactDetails {

                        &-list {
                            display: block;
                        }

                        &-value {
                            margin-top: $stack-spacing__small;
                        }

                        &-value:first-child,
                        &-label:first-child + .contactDetails-value {
                            margin-top: 0;
                        }

                    }

                    &::before { // Line
                        content: "";

                        display: block;
                        height: 100%; width: (math.div(1, $contact-details-col-span) * 100%);

                        position: relative;
                        transform: translateX(50%);
                        z-index: -1;

                        border-left: 1px solid $gray;
                    }

                }

            }

        }

    }

    @include not-on-desktop(){
        $image_col-span: 4;

        margin-left: (-.5 * $grid_gutter__mobile);
        margin-right: (-.5 * $grid_gutter__mobile);

        > * {
            padding-left: (.5 * $grid_gutter__mobile);
            padding-right: (.5 * $grid_gutter__mobile);
        }

        > .wrapper {
            width: (math.div(12 - $image_col-span, 12) * 100%);
        }

        .#{$module-name} {

            &-image {
                width: (100% * math.div($image_col-span, 12));
            }

            &-content {
                font-size: $base_font-size__small;
            }

        }

    }

}

.teaserCard.is-side-by-side {
    @extend %teaserCard--side-by-side;
}
