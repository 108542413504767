@mixin base-transition(
    $direction: out,
    $properties: all,
    $duration: false,
    $easing: ease
){

    @if $duration == false {

        $duration: .3s;

        @if $direction == in and global_variable_exists(base_transition-duration__in) {
            $duration: $base_transition-duration__in;
        }

        @if $direction == out and global_variable_exists(base_transition-duration__out) {
            $duration: $base_transition-duration__out;
        }

    }

    @if length($properties) > 1 {

        $list: ();

        @each $property in $properties {
            $list: append($list, $property, "comma");
        }

        transition-duration: $duration;
        transition-property: $list;
        transition-timing-function: $easing;

    } @else {

        transition: $properties $duration $easing;

    }

}