// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.affinityLinkBar {
    z-index: 2;
}

.affinityLinkBar + .topicFinder {
    @include stack-spacing(component, padding-top);
    z-index: 1;
}
