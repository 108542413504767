$ci_leading-color: #001E46;
$ci_active-color: #E17500;
$ci_impulse-color: #F4FF1E;
$ci_calming-color: #4D8192;
$ci_structure-color: rgba(55, 70, 80, 1);

$darkgray: rgba(55, 70, 80, .77);
$gray: rgba(55, 70, 80, .44);
$lightgray: rgba(55, 70, 80, .09);
$palegray: rgba(55, 70, 80, .03);
$off-white: #FAFBFC;

$darkgray__opaque: #6B6863;
$gray__opaque: #A9A7A4;
$lightgray__opaque: #EBEAE8;
$palegray__opaque: #F6F5F4;

$midnight-blue: #00122B;

// By purpose

$white: $off-white !default;
$black: $midnight-blue !default;
$text-color: $ci_leading-color !default;
$page-color__transparent: rgba(20, 70, 80, .02) !default;

$accent-color: $ci_leading-color !default;
$accent-color__darken: $accent-color !default;

$ui-color: $accent-color !default;
$ui-color__hover: $ci_active-color !default;
$ui-color__active: $ci_active-color !default;

$ui_background-color: $accent-color !default;
$ui_background-color__hover: $ci_active-color !default;
$ui_background-color__active: $ci_active-color !default;

$disabled-color: rgba(55, 70, 80, .33) !default;

$minor-color: $darkgray !default;

$information-color: #00B3FF !default;
$success-color: #5DAE95 !default;
$warning-color: #E6932B !default;
$error-color: #B00020 !default;

$notification-color: $warning-color;

$backdrop-color: $lightgray !default;
$backdrop-color__opaque: $lightgray__opaque !default;

$viewport_background-color: rgba($backdrop-color__opaque, .5);

$color-themes: (

    leading-color: (
        text-color: $white,
        accent-color: $ci_impulse-color,
        primary: $ci_leading-color,
        secondary: $white,
        primary__hover: $text-color,
        secondary__hover: $ci_impulse-color,
     ),

    active-color: (
        text-color: $midnight-blue,
        accent-color: $midnight-blue,
        primary: $ci_active-color,
        secondary: $midnight-blue,
        primary__hover: $text-color,
        secondary__hover: $white,
    ),

    calming-color: (
        text-color: $white,
        accent-color: $white,
        primary: $ci_calming-color,
        secondary: $white,
        primary__hover: $white,
        secondary__hover: $ci_leading-color,
    ),

    structure-color: (
        text-color: $white,
        accent-color: $ci_impulse-color,
        primary: $ci_structure-color,
        secondary: $white,
        primary__hover: $text-color,
        secondary__hover: $ci_impulse-color,
    ),

    impulse-color: (
        text-color: $text-color,
        accent-color: $text-color,
        primary: $ci_impulse-color,
        secondary: $text-color,
        primary__hover: $text-color,
        secondary__hover: $white,
    ),

) !default;

//***** Legacy *****//

$color-themes: (
    leading-color: map-get($color-themes, leading-color),
    active-color: map-get($color-themes, active-color),
    calming-color: map-get($color-themes, calming-color),
    structure-color: map-get($color-themes, structure-color),
    impulse-color: map-get($color-themes, impulse-color),
    leading-blue: map-get($color-themes, leading-color),
    active-blue: map-get($color-themes, active-color),
    calming-green: map-get($color-themes, calming-color),
    structure-silver: map-get($color-themes, structure-color),
    impulse-green: map-get($color-themes, impulse-color),
);

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

*::selection {
    background: $ci_impulse-color;
    color: $text-color !important;

    .is-impulse-color & {
        background: $white;
    }

}

    //** Documentation only **//

.doc-themes-item-sample {

    @include color-themes((
        background-color: primary,
        color: text-color,
    ));

}
