.mainSection {
    @include mainSection-without-backdrop();

    @if $mainSection_alternating-style {

        & + &:nth-child(even) {
            @include mainSection-with-backdrop();
        }

    }

    &-title {

        &:empty {
            margin: 0;
        }

    }

    &-content {
        @include stack-spacing();
    }

}

.mainSection.has-backdrop {
    @include mainSection-with-backdrop();
}
