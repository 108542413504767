.quoteInsert {
    $modifier: "is-with-portrait";

    &-portrait {
        display: block;

        background: center center no-repeat;
        background-size: cover;

        border-radius: 50%;
        overflow: hidden;

        &::before {
            padding-bottom: 100% !important;
        }

        img {
            display: none;
        }

    }

    @include only-on-mobile(){
        $portrait_width: 50%;

        $icon_scale: $quoteInsert_icon_scale__mobile;
        $icon_height: ($icon_scale * $quoteInsert_icon_height);
        $icon_offset: ($icon_scale * $quoteInsert_icon_offset);

        &-image {
            width: $portrait_width;
            margin-top: $icon_offset;

            float: right;
            shape-outside: circle(50%);
        }

        &-image + &-blockquote {

            &::before { // Spacer with half height of portrait
                content: "";

                display: block;
                width: $portrait_width;
                height: 0;
                padding-bottom: (.5 * $portrait_width);
            }

        }

    }

    &.#{$modifier} & {

        &-content {
            $icon_data-url: get-svg-data-url($quoteInsert_blockquote_icon);

            @include font-size(largest);
            color: $text-color;

            &:before {
                background-image: url($icon_data-url);
            }

        }

    }

    @include not-on-mobile(){
        $portrait_column_ratio: math.div(3, 8);

        &.#{$modifier} {
            display: flex;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            > * {
                flex: 1;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

        }

        &.#{$modifier} & {

            &-image {
                flex: 1 0 auto;
                width: ($portrait_column_ratio * 100%);

                transform: translateY($quoteInsert_icon_offset);
            }

            &-blockquote {
                flex: 1 1 auto;
            }

        }

    }

}
