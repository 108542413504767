@include font-faces($font-faces);

:root {

    @include responsive-variables(
        $set: $font-sizes,
        $prefix: --fs
    );

    @include responsive-variables(
        $set: $line-heights,
        $prefix: --lh
    );

}

:root,
html {
    font-family: $font-family;

    font-size: $font-size;
    line-height: $line-height;

    font-weight: $font-weight;

    @include special-font-smoothing();

    @include not-on-desktop() {
        font-size: $font-size__mobile;
    }

}

// Misc

sup {
        //Fixme: Remove !important after getting rid of Bootstrap’s reboot
    font-size: .5em !important;
    vertical-align: super !important;
}

strong {
    font-weight: $font-weight__bold;
}

// Helper classes

.nowrap {
    white-space: nowrap;
}
