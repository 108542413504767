$formField_textarea_lines: 8 !default;

    //** Checkboxes and radios **//

$formField_marker_color__hover: mix($text-color, $page-color, $weight: 32%) !default;

$formField_marker_size: 20px !default;
$formField_marker_inset: 3px !default;

    //** Selects **//

$formField_select_marker_icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="{{color}}" stroke-width="2.5" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg"><path d="M6 10L12 15L18 10"/></svg>' !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

%formField {
    @include stack-spacing(component);

    .label {
        margin-bottom: (.5 * $stack-spacing__small);
        margin-bottom: calc(.5 * var(--sp-small));
    }

}

%formField-select {

    select {
        @include styles($field_styles, border, border-bottom);
    }

}

.formField {

    &.is-required.is-valid & {

        &-input {

            &:focus {
                @include state-styles($field_states, focus);
            }

        }

    }
}

    //***** Right to left *****//

html[dir="rtl"] {

    %formField-select {
        $marker_size: $formField_select_markersize;

        &:after { // Marker
            right: auto;
            left: 0;
        }

        select {
            padding-right: nth($field_padding, 2);
            padding-left: ($marker_size + .4em);
        }

    }

    %formField-toggle-label {
        $size: $formField_marker_size;
        $inset: $formField_marker_inset;

        padding-left: 0;
        padding-right: (1.4 * $size);
        padding-right: calc(#{$size} + .4em);

        margin-left: 0;
        margin-right: $inset;

        &:not(:last-child) {
            margin-right: 0;
            margin-left: $grid_gutter;
        }

        &::before { // Marker
            left: auto;
            right: $field_border-width;
        }

    }

    .formField {

        .js-passwordRevealer {

        	&-trigger {
            	right: auto;
            	left: nth($field_padding, 2);
            }

        }

    }

}
