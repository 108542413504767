.is-applicationTechnicalData {

    .pageHeader + .mainSection {
        @include stack-spacing(component);

        .tableInsert {
            margin-top: 0;
        }

    }

    @include only-on-desktop(){

        .tableInsert {
            width: get-column-width( math.div(10, 12));
            margin-left: auto; margin-right: auto;
        }

    }

    .mainSection + .mainFooter {
        margin-top: 0;

        &::before { // Line
            border: none;
        }

    }

}
