// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.mainBackLink {

    .navigationLink {
        margin-top: -.2em;
    }

    & + .pageHeader {
        @include stack-spacing(component);
    }

}
