.infographicInsert {
    @include stack-spacing(section);

    &-items {
        display: flex;
        flex-wrap: wrap;

        justify-content: center;
    }

    .figure {
        margin-top: 0;

        &-caption {
            @include stack-spacing();

            width: 100%;
            @include stack-spacing(small, padding-top);

            border-top: $backdrop-color $base_border-width solid;
        }

    }

    @include not-on-desktop(){

        &-image {
            width: 100%;

            & + & {
                @include stack-spacing(section);
            }

        }

        &-image.is-upright {
            width: (math.div(4, 6) * 100%);
        }

    }

    @include only-on-desktop(){
        $gutter: $grid_gutter;

        &-items {
            margin: 0 (-.5 * $gutter);
        }

        &-image {
            width: (math.div(6, 12) * 100%);

            padding: 0 (.5 * $gutter);

            &:only-child {
                width: (math.div(8, 12) * 100%);
            }

        }

        &-image.is-full-width {
            width: 100%;
        }

        &-image.is-upright {
            width: (math.div(5, 12) * 100%);
        }

        &-image.is-upright + &-image:not(.is-upright),
        &-image:not(.is-upright) + &-image.is-upright {
            margin-left: (math.div(1, 12) * 100%);
        }

        &-image:not(.is-upright) + &-image:not(.is-upright) {
            margin-left: (math.div(1, 12) * 100%);

            width: (math.div(5, 12) * 100%);
        }

        &-image.is-upright + &-image.is-upright {
            margin-left: (math.div(1, 12) * 100%);

            width: (math.div(4, 12) * 100%);
        }

    }

}
