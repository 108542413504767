.highlightInsert {
    @include stack-spacing(section);

    &-content {
        @include heading-style(3, $headings_styles, font-size, line-height);
        font-weight: $base_font-weight__medium;
        text-align: center;

        @include stack-spacing(default, padding-top);
        @include stack-spacing(default, padding-bottom);

        border: $base_border-width $ci_calming-color;
        border-style: solid none;

        > *:first-child {
            margin-top: 0;
        }

        @include color-themes((
            border-color: accent-color,
        ));

        @include not-on-desktop(){
            @include heading-style(3, $headings_styles__mobile, font-size, line-height);
        }

    }

    &.is-emphasised &-content {
        @include heading-style(2, $headings_styles, font-size, line-height);

        font-weight: $base_font-weight;
        text-align: left;

        padding: 0 0 0 $grid_gutter;

        border-width: (4 * $base_border-width);
        border-style: none none none solid;

        @include not-on-desktop(){
            @include heading-style(2, $headings_styles__mobile, font-size, line-height);
            padding-right: $grid_gutter__mobile;
        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .highlightInsert.is-emphasised .highlightInsert {

        &-content {
            text-align: right;
            padding: 0 $grid_gutter 0 0;
            border-style: none solid none none;
        }

    }

}
