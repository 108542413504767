    //** Textareas **//

$formField_textarea_lines: 4 !default;

    //** Checkboxes and radios **//

$formField_marker_color: mix($text-color, $page-color, $weight: 80%) !default;
$formField_marker_color__hover: mix($text-color, $page-color, $weight: 40%) !default;

$formField_marker_size: 16px !default;
$formField_marker_inset: 3px !default;

    //** Selects **//

$formField_select_marker_icon: '<svg width="32" height="32" viewBox="0 0 32 32" stroke="{{color}}" stroke-width="1" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5,12.5l6.5,7l6.5-7"/></svg>' !default;

$formField_select_marker_styles: (
    background: url(get-svg-data-url($formField_select_marker_icon, $text-color)) 50% 50% no-repeat,
) !default;


$formField_select_marker_states: (
    default: $formField_select_marker_styles,
    hover: (
        background-image: url(get-svg-data-url($formField_select_marker_icon, $ui-color__hover)),
    ),
    focus: (
        background-image: url(get-svg-data-url($formField_select_marker_icon, $field_focus-color)),
    ),
    success: (
        background-image: url(get-svg-data-url($formField_select_marker_icon, $field_success-color)),
    ),
    error: (
        background-image: url(get-svg-data-url($formField_select_marker_icon, $field_error-color)),
    ),
) !default;

$formField_select_markersize: (1em * $base_line-height) + (2 * nth($field_padding, 1)) !default;

    //** Messages **//

$formField_message_styles: (
    font-size: $base_font-size__small,
    color: $minor-color,
) !default;
