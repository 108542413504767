.mainFooter {
    $modifier: "is-compact";

    $border-color: rgba($white, .7);

    &.#{$modifier} & {

        &-service {
            @include stack-spacing(component);
            @include stack-spacing(component, padding-top);

            border-top: 1px $border-color solid;
        }

    }

    @include only-on-desktop(){

        &.#{$modifier} & {

            &-description {
                padding-right: 0;

                display: flex;

                margin-left: (-.5 * $grid_gutter);
                margin-right: (-.5 * $grid_gutter);

                > * {
                    flex: 1;

                    padding-left: (.5 * $grid_gutter);
                    padding-right: (.5 * $grid_gutter);

                    margin-top: 0;
                }

            }

        }

    }

}
