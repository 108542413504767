.tagList {

    ul {
        list-style: none;
    }

    &-title {
        display: block;
        margin-bottom: (.5 * $stack-spacing);
    }

    &-list {
        padding: 0;
        margin: 0 (-.5 * $tagList_gutter);

        display: flex;
    }

    &-item {
        padding: 0 (.5 * $tagList_gutter);
    }

    &-link {
        user-select: none;
        @include base-transition();

        @include state-styles($tagList_label_states);

        &:hover {
            @include base-transition(in);
            @include state-styles($tagList_label_states, hover);
        }

        &:active {
            @include state-styles($tagList_label_states, active);
        }

    }

}
