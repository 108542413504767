.is-landingpageCorporate {
    @extend %pageLayout__narrow;

    .mainSection.contains-textSlide {

        & + & {
            margin-top: 0;
        }

    }

}
