.tableInsert {
    @include stack-spacing(section);

    &-download {
        $icon_size: 24px;
        $icon_data: get-icon-data-url(download, $text-color);

        @include stack-spacing();

        &-link {
            @extend %link--empasised;

            &::after { // Icon
                content: "";

                display: inline-block;
                width: $icon_size; height: $icon_size;

                vertical-align: middle;
                position: relative;
                top: -.1em;

                margin-left: .3em;

                background: url($icon_data) center center no-repeat;
            }

        }

    }

}

    //***** Right to left *****//

html[dir="rtl"] {

    .tableInsert {

        &-download {

            &-link {

                &::after { // Icon
                    margin-left: 0;
                    margin-right: .3em;
                }

            }

        }

    }

}
