.socialShareInsert {
    $columns-span: 7;

    $list_padding: (.5 * $box-padding__mobile);

    $offset-x: $page_padding__mobile;
    $offset-y: $button_height__mobile + (2 * px($list_padding, $font-size__mobile));

    @include stack-spacing(section);

    width: calc(100% - #{$page_padding__mobile});
    padding-bottom: $offset-y;

    > .wrapper {
        position: relative;
        z-index: 1;

        &::before { // Backdrop
            content: "";

            display: block;
            width: 100%;
            height: 100%;

            position: absolute;
            top: $offset-y;
            left: $offset-x;
            z-index: -1;

            background-color: $ci_leading-color;
        }

    }

    &-quote {
        padding: $box-padding__mobile;
        background: $ci_impulse-color;

        &::after { // Marker
            $width: 64px;
            $height: 48px;

            content: "";

            display: block;
            width: 0;
            height: 0;

            position: absolute;
            bottom: 100%;
            left: $offset-x;
            z-index: -1;

            border-style: solid;
            border-color: transparent $ci_impulse-color $ci_impulse-color transparent;
            border-width: (.5 * $height) (.5 * $width);
        }

        .quoteInsert {
            margin-top: 0;

            &-content {
                font-size: rem(36px, $font-size__mobile);
                line-height: 1.2;
                font-weight: $font-weight__bold;
                color: $text-color;

                padding-top: (1.2 * $quoteInsert_icon_scale__mobile * $quoteInsert_icon_height);

                &::before { // Quotation mark
                    $icon_data-url: get-svg-data-url($quoteInsert_blockquote_icon, $text-color);
                    background-image: url($icon_data-url);
                }

            }

        }

    }

    &-channels {
        position: absolute;
        left: $offset-x;
        bottom: (-1 * $offset-y);

        padding: $list_padding $box-padding;

        display: flex;
        align-items: center;

        color: $white;

        &-label {
            font-weight: $font-weight__medium;
            margin-right: .5em;

            &::after {
                content: ":";
            }

        }

    }

    &-list {
        list-style: none;
        padding: 0;

        display: flex;
        margin-top: 0;

        .button {
            display: block;
            margin-top: 0;
        }

    }

    @include only-on-desktop(){
        $gutter: $grid_gutter;

        $list_padding: (.5 * $box-padding);

        $offset-x: get-column-indent($columns-span);
        $offset-y: $button_height + (2 * px($list_padding));

        width: get-column-width(math.div($columns-span, 12));
        margin-left: get-columns-indent(2);
        padding-bottom: $offset-y;

        > .wrapper {

            &::before { // Backdrop
                top: $offset-y;
                left: $offset-x;
            }

        }

        &-quote {
            padding: $box-padding;

            &::after { // Marker
                left: get-columns-indent(1, $columns-span);
            }

            .quoteInsert {
                margin-top: 0;

                &-content {
                    font-size: rem(54px);

                    padding-top: 0;
                    padding-left: ($quoteInsert_icon_width + .8 * px($box-padding));
                    padding-left: calc(#{$quoteInsert_icon_width} + #{.8 * $box-padding});

                    &::before {
                        transform: translateY(.15 * $quoteInsert_icon_height);
                    }

                }

            }

        }

        &-channels {
            left: get-columns-indent(1, $columns-span);
            bottom: (-1 * $offset-y);
            padding: $list_padding $box-padding;

            &-label {
                margin-right: $gutter;
            }

        }

        &-item {

            &:not(:first-child) {
                margin-left: $gutter;
            }

        }

    }

    @include not-on-desktop(){

        &-channels {

            &-label {
                display: none;
            }

        }

    }

}
