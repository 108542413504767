@mixin fixed-ratio-image(
    $ratio: $figure_fix-ratio
){
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 0 0;

    position: relative;

    &::before { // Placeholder for image
        content: "";

        display: block;

        width: 100%; height: 0;
        padding-bottom: math.div(100%, $ratio);

        background: $figure_fix-ratio_background-color center center no-repeat;
        background-image: inherit;
        background-position: inherit;
        background-size: cover;
    }

    a {
        position: absolute;
        top: 0; left: 0;

        display: block;

        width: 100%; height: 0;
        padding-bottom: math.div(100%, $ratio);
    }

    img {
        display: none;
    }

}

@mixin figure_set-fixed-ratio(
    $ratio: $figure_fix-ratio
){

    &[style*="background-image"]::before {
        padding-bottom: math.div(100%, $ratio);
    }

}
